// @flow
import React, {useEffect, useState, useMemo} from 'react';
import {Nav} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {Icon, Loader} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useAppContext, useJobContext, useTutorialContext} from 'contexts';
import {getRoomLayoutStatus} from 'service';
import excel from 'shared/Excel';
import {useTour} from '@reactour/tour';
import {Link} from 'shared/reload/Link';
import {useVariationResetButton} from 'shared/components/Header/VariationResetButton';
import {isEmpty} from 'lodash';
import {useJobSubmitHandler} from 'components/customer/Job/helpers/useJobSubmitHandler';

type JobMenuItemType = {
    jobMenu: any[],
    isCollapsed: boolean,
};

export const JobMenuItem = ({
    jobMenu,
    isCollapsed,
}: JobMenuItemType): React$Element<any> => {
    const isSmallDevice: boolean = isDeviceSmall();
    const {job, room, actionButtonsEnabled, expiredJobPrice} = useJobContext();
    const {userProfile} = useAppContext();
    const location = useLocation();
    const {handleJobSubmission, loader, dialog} = useJobSubmitHandler();
    const {tutorial, clearSteps} = useTutorialContext();
    const {steps, setIsOpen} = useTour();

    const [cutOffMenuList, setCutOffMenuList] = useState(-1);

    const roomLayoutStatus = useMemo<boolean>(() => {
        return getRoomLayoutStatus(room.id);
    }, [room]);

    const {noVariations, extraVariation, resetJob} = useVariationResetButton();
    const page = useMemo<string>(() => {
        const urlParts = location.pathname.split('/');
        let page = urlParts.pop();
        if (!isNaN(page)) {
            page = urlParts.pop();
        }
        return page;
    }, [location]);

    const activeKey = useMemo<string>(() => {
        const urlParts = location.pathname.split('/');
        const index = urlParts.indexOf('benchtop-module');
        if (index != -1) {
            return urlParts.splice(0, index + 1).join('/');
        }
        return location.pathname;
    }, [location]);

    useEffect(() => {
        setCutOffMenuList(isSmallDevice ? 5 : -1);
    }, [isSmallDevice]);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent('onMobileMenuUpdate'));
    }, [cutOffMenuList]);

    useEffect(() => {
        // TODO: 0 is ROOM. change it to proper enum later when whole file
        // is updated to .tsx
        if (
            job &&
            job.rooms &&
            job.rooms.length == 0 &&
            tutorial == 0 &&
            steps.length
        ) {
            setIsOpen(true);
        }

        () => {
            clearSteps();
            setIsOpen(false);
        };
    }, [job, tutorial, steps]);

    const toggleMenu = jobMenu.find((menu) => menu.toggleButton);
    let filteredJobMenus = jobMenu.filter((menu) => {
        if (menu.toggleButton) {
            return false;
        }

        if (userProfile.show_pricing && expiredJobPrice) {
            return false;
        }

        if (
            isSmallDevice &&
            menu.hasOwnProperty('onlyMobile') &&
            !menu.onlyMobile
        ) {
            return false;
        }

        if (
            !isSmallDevice &&
            menu.hasOwnProperty('onlyMobile') &&
            menu.onlyMobile
        ) {
            return false;
        }

        if (menu.onlyProcessingJob && !actionButtonsEnabled) {
            return false;
        }

        if (typeof menu.isVisible !== 'boolean') {
            menu.isVisible = excel.calculate(menu.isVisible, userProfile);
        }

        if (
            menu.hasOwnProperty('resetVariation') &&
            (job?.accepted == 0 || (!noVariations && !extraVariation))
        ) {
            return false;
        }

        return menu.isVisible;
    });

    if (filteredJobMenus.length > 5 && isSmallDevice) {
        filteredJobMenus.splice(4, 0, toggleMenu);
        filteredJobMenus = filteredJobMenus.filter((menu, key) => {
            if (cutOffMenuList > -1 && key >= cutOffMenuList) {
                return false;
            }
            return true;
        });
    }

    if (isEmpty(job)) {
        return null;
    }

    return (
        <>
            {dialog}
            <Loader loader={loader} />
            <Nav
                activeKey={activeKey}
                className={isSmallDevice ? 'job-menu' : 'flex-column job-menu'}>
                {filteredJobMenus.map((menu, key) => {
                    let menuName =
                        isSmallDevice && menu.hasOwnProperty('mobileName')
                            ? menu.mobileName
                            : menu.name;
                    let icon = menu.icon;
                    let action = undefined;
                    let navAs = Link;
                    let to = menu.url;
                    let isDisabled = false;

                    if (
                        menu.alternateUrl &&
                        menu.condition &&
                        excel.calculate(menu.condition, {
                            ...userProfile,
                            ...{
                                hasLayout: roomLayoutStatus,
                                cutOffMenuList,
                                page,
                            },
                        })
                    ) {
                        to = menu.alternateUrl;
                    }

                    if (
                        menu.alternateIcon &&
                        menu.condition &&
                        excel.calculate(menu.condition, {
                            ...userProfile,
                            ...{
                                hasLayout: roomLayoutStatus,
                                cutOffMenuList,
                                page,
                            },
                        })
                    ) {
                        icon = menu.alternateIcon;
                    }

                    if (menu.text) {
                        menuName = excel.calculate(menu.text, userProfile);
                    }

                    if (
                        menu.alternateText &&
                        menu.condition &&
                        excel.calculate(menu.condition, {
                            ...userProfile,
                            ...{
                                hasLayout: roomLayoutStatus,
                                cutOffMenuList,
                                page,
                            },
                        })
                    ) {
                        menuName = menu.alternateText;
                    }

                    if (menuName == '') {
                        menuName = menu.name;
                    }

                    if (
                        job.hasOwnProperty('displayId') &&
                        typeof to === 'string' &&
                        to.indexOf('{job}') > -1
                    ) {
                        to = to.replace('{job}', job.displayId);
                    }

                    if (
                        room.hasOwnProperty('id') &&
                        typeof to === 'string' &&
                        to.indexOf('{room}') > -1
                    ) {
                        to = to.replace('{room}', room.id);
                    }

                    if (
                        menu.hasOwnProperty('toggleButton') &&
                        menu.toggleButton
                    ) {
                        navAs = undefined;
                        to = undefined;
                        action = () => {
                            setCutOffMenuList(cutOffMenuList === -1 ? 5 : -1);
                        };
                    }

                    if (
                        menu.hasOwnProperty('submitButton') &&
                        menu.submitButton
                    ) {
                        navAs = undefined;
                        to = undefined;
                        action = handleJobSubmission;
                    }

                    if (menu.hasOwnProperty('resetVariation')) {
                        navAs = undefined;
                        to = undefined;
                        action = resetJob;
                    }

                    if (
                        menu.hasOwnProperty('isDisabled') &&
                        typeof menu.isDisabled !== 'boolean' &&
                        !!job?.id
                    ) {
                        const {condition, scope} = menu.isDisabled;
                        isDisabled = excel.calculate(
                            condition,
                            scope === 'job' ? job : userProfile
                        );
                    }

                    return (
                        <Nav.Link
                            id={menu.id}
                            key={menu.id}
                            as={navAs}
                            to={to}
                            href={to}
                            onClick={action}
                            disabled={
                                typeof job.id === 'undefined' ||
                                (!room.hasOwnProperty('id') &&
                                    menu.needsRoom) ||
                                isDisabled
                            }>
                            <div>
                                <Icon iconName={icon} title={menuName} />
                            </div>
                            {isCollapsed ? '' : <label>{menuName}</label>}
                        </Nav.Link>
                    );
                })}
            </Nav>
        </>
    );
};
