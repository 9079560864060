import {User} from 'components/customer/Auth/store/userSlice';

export const checkShowUpdateToUser = (key: string, user: User) => {
    if (key in user) {
        const keyValue = user[String(key)];

        if (typeof keyValue === 'boolean') {
            return keyValue;
        }

        if (typeof keyValue === 'number' || typeof keyValue === 'string') {
            return keyValue == 1;
        }
    }

    return false;
};
