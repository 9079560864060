import React, {useEffect} from 'react';
import {useConfirmationDialog} from 'shared';
import {Alert, ListGroup} from 'react-bootstrap';

interface ShowValidationMessageInterface {
    messages: string[];
    title: string;
    onHide?: () => void;
}

export const ShowValidationMessage = ({
    messages,
    title,
    onHide,
}: ShowValidationMessageInterface) => {
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    useEffect(() => {
        if (messages && messages.length > 0) {
            showDialog({
                title,
                message: (
                    <>
                        <>
                            <Alert
                                variant="danger"
                                style={{marginTop: 0, marginBottom: '15px'}}>
                                <strong>
                                    Please fix these problems to save the benchtop
                                </strong>
                            </Alert>
                            <ListGroup
                                style={{
                                    maxHeight: '300px',
                                    overflowY: 'scroll',
                                }}>
                                {messages.map((message, index) => (
                                    <ListGroup.Item key={index}>
                                        {message}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </>
                    </>
                ),
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        show: true,
                        name: 'Ok',
                        controlledHideDialog: true,
                        action: () => {
                            if (typeof onHide === 'function') {
                                onHide();
                            }
                        },
                    },
                ],
            });
        } else {
            hideDialog();
        }

        return () => {
            hideDialog();
        };
    }, [messages]);

    return <>{dialog}</>;
};
