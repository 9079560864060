// @flow
import type {Handler} from './Handler';
import {DBAbstract} from 'IndexedDB';

/**
 *
 *
 * @export
 * @class IDBHandler
 * @extends {Handler<T>}
 * @template T
 */
export default class IDBHandler<T> extends DBAbstract implements Handler<T> {
    static existingData: string[] = [];

    objectProcessor: (object: Object) => T;

    resetCache: boolean = false;
    handler: Handler<T>;
    store: string;
    index: string;
    search: any;

    /**
     * Creates an instance of IDBHandler.
     * @param {string} store
     * @param {ObjectProcessor} objectProcessor
     * @memberof IDBHandler
     */
    constructor(store: string, objectProcessor: (data: Object) => T) {
        super();
        this.objectProcessor = objectProcessor;
        this.store = store;
    }

    /**
     *
     * @param {*} search
     * @param {string} index
     * @return {*}  {Handler<T>}
     * @memberof IDBHandler
     */
    setIndex(search: any, index?: string): IDBHandler<T> {
        if (index) {
            this.index = index;
        }
        this.search = search;

        return this;
    }

    /**
     *
     *
     * @param {Handler<T>} handler
     * @return {*}  {Handler<T>}
     * @memberof IDBHandler
     */
    addHandler(handler: Handler<T>): Handler<T> {
        this.handler = handler;

        return handler;
    }

    /**
     *
     *
     * @param {boolean} resetCache
     * @memberof CacheHandler
     */
    setResetCache(resetCache: boolean) {
        this.resetCache = resetCache;
    }

    /**
     *
     *
     * @template T
     * @param {T} object
     * @return {*}  {Promise<Array<T>>}
     * @memberof HTTPHandler
     */
    async getData(): Promise<T[]> {
        if (this.resetCache) {
            if (this.index && this.search) {
                this.deleteByKey(this.store, this.index, this.search);
            } else if (!this.index && this.search) {
                this.delete(this.store, this.search);
            } else {
                this.truncate(this.store);
            }
        }

        let data;
        if (this.search) {
            let singleValue = true;
            if (Array.isArray(this.search)) {
                singleValue = false;
            }

            data = await this.readById(
                this.store,
                this.search,
                this.index,
                singleValue
            );
        } else {
            data = await this.readAll(this.store);
        }

        if (data && data.length) {
            const dbData: T[] = [];

            data.forEach((element) => {
                dbData.push(this.objectProcessor(element));
            });

            return dbData;
        }

        if (this.handler) {
            const dataIsInDB = IDBHandler.existingData.includes(this.store);
            IDBHandler.existingData.push(this.store);

            const newData = await this.handler.getData();

            if (!dataIsInDB) {
                await this.add(this.store, JSON.parse(JSON.stringify(newData)));
            }

            return newData;
        }

        return [];
    }

    /**
     *
     *
     * @param {T[]} data
     * @memberof HTTPHandler
     */
    async setData(data: T[]): Promise<void> {
        await this.add(this.store, data);
    }
}
