import React from 'react';
import {Button} from 'react-bootstrap';
import {Icon, urlPartsJob} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import useUI from 'shared/useUI';
import {useRightSidebar} from 'shared/components/Sidebar';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {
    getUI,
    UISliceInterface,
    SidebarStatus,
    SidebarBehaviour,
} from 'store/customer/UISlice';
import {useTheme} from 'styled-components';

export const CartToggleButton = () => {
    const {
        rightSidebarBehaviour,
        rightSidebarSoftOpen,
        rightSidebar,
        isSmallScreenLayout,
    } = useUI();
    const {toggle} = useRightSidebar();
    const theme = useTheme();

    const [jobId] = urlPartsJob();

    const isSmallDevice = isDeviceSmall();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);

    if (
        !isSmallDevice &&
        jobId &&
        ((rightSidebarSoftOpen && !isSmallScreenLayout) ||
            (!rightSidebarSoftOpen &&
                rightSidebar === SidebarStatus.CLOSED &&
                rightSidebarBehaviour === SidebarBehaviour.TOGGLE) ||
            (isSmallScreenLayout &&
                ui.rightSidebar.smallScreenStatus == SidebarStatus.CLOSED &&
                ui.rightSidebar.smallScreenBehaviour ==
                    SidebarBehaviour.AUTOHIDE))
    ) {
        return (
            <Button
                title="Toggle cart visibility"
                onClick={toggle}
                variant="Link"
                className="sidebar-floating-button sidebar-cart-toggle-button">
                <Icon
                    iconName="Cart.svg"
                    color={theme.colors.cartIconColor.main}
                />
            </Button>
        );
    } else {
        return null;
    }
};
