import React from 'react';
import styled from 'styled-components';
import {Dropdown, Image} from 'react-bootstrap';
import {DropdownOption} from 'shared/components/Forms/Dropdown';

type doorType = {
    id: number;
    name: string;
    suffixName: string;
    suffixId: number;
    image: string;
    filterName: string;
    maximumHeight?: number;
    maximumRailsHorizontalHeight?: number;
    maximumRailsVerticalWidth?: number;
    maximumWidth?: number;
    minimumBorderWidthBottom?: number;
    minimumBorderWidthLeft?: number;
    minimumBorderWidthRight?: number;
    minimumBorderWidthTop?: number;
    minimumDistanceLeftRight?: number;
    minimumDistanceTopBottom?: number;
    minimumHeight?: number;
    minimumHeightDrawer?: number;
    minimumRailsHorizontalHeight?: number;
    minimumRailsVerticalWidth?: number;
    minimumWidth?: number;
};

type dropdownListDoorStyle = {
    children: string | JSX.Element;
    door?: doorType;
    active?: boolean;
    disabled?: boolean;
    onSelect?: (option: DropdownOption) => void;
};

const DropdownListDoorStyle = ({
    children,
    door,
    onSelect,
    ...props
}: dropdownListDoorStyle): JSX.Element => {
    return (
        <StyledDropdownItem onSelect={onSelect} {...props}>
            {door.image == null ? null : (
                <Image src={`/${door.image}`} thumbnail />
            )}
            <div>
                <strong>{children}</strong>
                <div>{door.maximumWidth ? door.maximumWidth : ''}</div>
            </div>
        </StyledDropdownItem>
    );
};

const StyledDropdownItem = styled(Dropdown.Item)<{disabled: boolean}>`
    display: flex;
    flex-direction: row;
    padding: 0.25rem 1.5rem !important;
    align-items: center;

    img {
        width: 45px;
        padding: 0;
    }

    > div {
        width: 100px;
        display: flex;
        margin-left: 1rem;
        flex-direction: column;
        font-size: 0.85rem;
    }
    ${({disabled}) => disabled && `opacity: 0.6`};
`;

export default DropdownListDoorStyle;
