import React from 'react';
import styled from 'styled-components';

const BetaLabel = styled.label`
    background: rgb(var(--secondary_colour));
    color: white;
    transform: rotate(45deg);
    position: absolute;
    top: 12px;
    right: -30px;
    width: 100px;
    text-align: center;
`;

export const Beta = ({
    className,
}: React.AllHTMLAttributes<HTMLLabelElement> & {className: string}) => {
    return <BetaLabel className={className}>BETA</BetaLabel>;
};
