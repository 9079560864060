import {FormControl, InputGroup} from 'components/customer/Auth/StyledElements';
import React, {useState, useMemo, useCallback} from 'react';
import {getColoredImage} from 'shared/helpers';
import {EyeHidden, EyeVisible} from 'assets';
import {useTheme} from 'styled-components';

type PasswordType = {
    name: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isInvalid: boolean;
    placeholder: string;
    disabled?: boolean;
    id?: number | string;
    onBlur: (e: MouseEvent) => void;
    autoComplete?: string;
};

export const Password = ({
    name,
    value,
    onChange,
    isInvalid,
    placeholder,
    autoComplete = 'new-password',
    ...props
}: PasswordType) => {
    const theme = useTheme();
    const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);

    const [eyeHidden, eyeVisible] = useMemo<string[]>(() => {
        const primaryColour = theme.colors.primary.main;
        const eyeHidden = getColoredImage(
            String(EyeHidden),
            '#204380',
            primaryColour
        );

        const eyeVisible = getColoredImage(
            String(EyeVisible),
            '#204380',
            primaryColour
        );

        return [eyeHidden, eyeVisible];
    }, [theme]);

    const handlePasswordVisibility = useCallback(() => {
        setNewPasswordShown((newPasswordShown) => !newPasswordShown);
    }, []);

    return (
        <InputGroup>
            <FormControl
                {...props}
                type={newPasswordShown ? 'text' : 'password'}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                autoComplete={autoComplete}
                className="password-field"
                $isInvalid={isInvalid}
            />
            <InputGroup.Append className="cbc-input-group-append">
                <InputGroup.Text onClick={handlePasswordVisibility}>
                    <img
                        className="cbc-input-group-icon"
                        alt={newPasswordShown ? 'Visible Icon' : 'Hidden Icon'}
                        src={newPasswordShown ? eyeVisible : eyeHidden}
                    />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};
