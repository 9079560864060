import {sumArrayVal} from 'shared/helpers';
import Shape from './Shape';

const FACE_TYPES = {
    INDIVIDUAL: 1,
    COLLECTIVE: 2,
    REDUCED_RAILS: 3,
};

export default class Drawer extends Shape {
    dimension(faceHeight) {
        let height = this.options.height;
        let width = this.options.width;
        let gap = this.options.gap;

        let newWidth = this.canvas.width - this.widthOffset * 2;

        const ratio = newWidth >= width ? width / newWidth : newWidth / width;
        let newHeight = Math.round(ratio * height);

        if (newHeight > this.canvas.height - this.heightOffset * 2) {
            newHeight = this.canvas.height - this.heightOffset * 2;

            newWidth =
                (newHeight < height ? newHeight / height : height / newHeight) *
                width;
        }

        let heightRatio =
            newHeight > height ? height / newHeight : newHeight / height;
        let newGap = Math.round(gap * heightRatio);
        const newFaceHeight = Math.round(faceHeight * heightRatio);
        this.height = newHeight;
        this.width = newWidth;

        return [newWidth, newHeight, newFaceHeight, newGap];
    }

    rectWithBorder(x, y, width, height, edgePattern) {
        this.context.strokeStyle = 'gray';
        this.context.lineWidth = 1;

        this.context.beginPath();
        this.context.rect(x, y, width, height);
        this.context.fillStyle = edgePattern;
        this.context.fill();
        this.context.stroke();
    }

    drawerEdges(
        width,
        height,
        faceHeight,
        gap,
        index,
        exteriorPattern,
        edgePattern
    ) {
        this.rectWithBorder(
            this.widthOffset,
            this.heightOffset +
                (index == 0 ? 0 : sumArrayVal(this.offset, index - 1)) +
                gap * index,
            width,
            faceHeight,
            exteriorPattern
        );

        const edgeOffset = 10;
        const edgeSize = 5;
        const drawerPanelEdges = this.options.drawerPanelEdges;

        if (
            this.options.allowDrawerFaceEdgeFinish &&
            Array.isArray(drawerPanelEdges) &&
            drawerPanelEdges.length > 0 &&
            this.options.drawerAmount > 1
        ) {
            const currentSelectedIndex = index % drawerPanelEdges.length;
            /**
             * @type {import('hooks/Product/DrawerPanelEdge').DrawerPanelEdge}
             */
            const currentSelected =
                drawerPanelEdges[Number(currentSelectedIndex)];
            const isLastIndex =
                currentSelectedIndex === drawerPanelEdges.length - 1;

            if (index == 0 && currentSelected?.drawer_edge_top > 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset + faceHeight * index - edgeOffset,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (index == 0 && currentSelected?.drawer_edge_bottom > 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index - 1) +
                        gap * index +
                        faceHeight -
                        edgeSize,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (
                !isLastIndex &&
                index != 0 &&
                currentSelected?.drawer_edge_top > 0
            ) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index - 1) +
                        gap * index,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (
                !isLastIndex &&
                index != 0 &&
                currentSelected?.drawer_edge_bottom > 0
            ) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index - 1) +
                        gap * index +
                        faceHeight -
                        edgeSize,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (isLastIndex && currentSelected?.drawer_edge_top > 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index - 1) +
                        gap * index,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (isLastIndex && currentSelected?.drawer_edge_bottom > 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index) +
                        edgeSize +
                        gap * index,
                    width,
                    edgeSize,
                    edgePattern
                );
            }
        } else {
            if (this.options.panelEdgeTop && index == 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset + faceHeight * index - edgeOffset,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (
                this.options.panelEdgeBottom &&
                index == this.options.drawers.length - 1
            ) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index) +
                        edgeSize +
                        gap * index,
                    width,
                    edgeSize,
                    edgePattern
                );
            }

            if (this.options.joiningBorder && index !== 0) {
                this.rectWithBorder(
                    this.widthOffset,
                    this.heightOffset +
                        sumArrayVal(this.offset, index - 1) +
                        gap * index -
                        gap * 0.6,
                    width,
                    edgeSize,
                    edgePattern
                );
            }
        }

        if (index == this.options.drawers.length - 1) {
            // finished drawing all drawers start drawind borders
            window.setTimeout(() => {
                this.edgePattern = edgePattern;
                window.dispatchEvent(new CustomEvent('drawers-painted'));
            }, 500);
        }
    }

    drawerBorders(width, height, faceHeight, originalFaceHeight, gap, index) {
        if (
            this.options.hasOwnProperty('hasBorders') &&
            !this.options.hasBorders
        )
            return;

        let widthRatio =
            width < this.options.width
                ? width / this.options.width
                : this.options.width / width;
        const heightRatio =
            faceHeight < originalFaceHeight
                ? faceHeight / originalFaceHeight
                : originalFaceHeight / faceHeight;

        this.context.strokeStyle = 'black';

        let borderTop =
            Math.round(
                heightRatio *
                    (this.options.borderTop ? this.options.borderTop : 0)
            ) + 0.5;
        let borderBottom =
            Math.round(
                heightRatio *
                    (this.options.borderBottom ? this.options.borderBottom : 0)
            ) + 0.5;
        const borderLeft =
            Math.round(
                widthRatio *
                    (this.options.borderLeft ? this.options.borderLeft : 0)
            ) + 0.5;
        const borderRight =
            Math.round(
                widthRatio *
                    (this.options.borderRight ? this.options.borderRight : 0)
            ) + 0.5;

        let joining = 0;

        if (this.options.faceType == FACE_TYPES.REDUCED_RAILS) {
            // Reduced rails use half the top border
            joining = (this.options.borderTop ? this.options.borderTop : 0) / 2;
        } else {
            joining = this.options.joiningBorderHeight
                ? this.options.joiningBorderHeight
                : 0;
        }

        joining = Math.round(heightRatio * joining) + 0.5;

        if (typeof this.options.joiningBorderHeight !== 'boolean') {
            if (index == 0) {
                borderBottom = joining;
            }

            if (index == this.options.drawers.length - 1) {
                borderTop = joining;
            }

            if (index > 0 && index < this.options.drawers.length - 1) {
                borderBottom = joining;
                borderTop = joining;
            }
        }

        this.context.beginPath();
        this.context.rect(
            this.widthOffset + borderLeft,
            this.heightOffset +
                (index == 0 ? 0 : sumArrayVal(this.offset, index - 1)) +
                borderTop +
                gap * index,
            width - borderRight - borderLeft,
            faceHeight - borderBottom - borderTop
        );

        this.context.stroke();
    }

    drawCollectiveBorder(width, height) {
        if (
            this.options.hasOwnProperty('hasBorders') &&
            !this.options.hasBorders
        )
            return;

        let widthRatio = width / this.options.width;
        let heightRatio = height / this.options.height;

        this.context.strokeStyle = 'black';

        let borderTop =
            Math.round(
                heightRatio *
                    (this.options.borderTop ? this.options.borderTop : 0)
            ) + 0.5;
        const borderBottom =
            Math.round(
                heightRatio *
                    (this.options.borderBottom ? this.options.borderBottom : 0)
            ) + 0.5;
        const borderLeft =
            Math.round(
                widthRatio *
                    (this.options.borderLeft ? this.options.borderLeft : 0)
            ) + 0.5;
        const borderRight =
            Math.round(
                widthRatio *
                    (this.options.borderRight ? this.options.borderRight : 0)
            ) + 0.5;

        this.context.beginPath();
        this.context.moveTo(
            this.widthOffset + borderLeft,
            this.heightOffset + borderTop
        );
        this.context.lineTo(
            this.widthOffset + width - borderRight,
            this.heightOffset + borderTop
        );
        this.context.lineTo(
            this.widthOffset + width - borderRight,
            this.heightOffset + height - borderBottom
        );
        this.context.lineTo(
            this.widthOffset + borderLeft,
            this.heightOffset + height - borderBottom
        );
        this.context.lineTo(
            this.widthOffset + borderLeft,
            this.heightOffset + borderTop
        );
        this.context.stroke();
    }

    drawDrawer(width, height, faceHeight, gap, index, exteriorPattern) {
        this.image(this.options.edgeMaterial).then((edgeImage) => {
            const pattern = this.pattern(
                this.options.hasOwnProperty('horizontal') &&
                    this.options.horizontal,
                edgeImage
            );

            this.drawerEdges(
                width,
                height,
                faceHeight,
                gap,
                index,
                exteriorPattern,
                pattern
            );
        });
    }

    async draw() {
        this.offset = [0];

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.heightOffset = 40;
        if (this.options.drawers) {
            const drawBorders = () => {
                let sideEdgeHeight = 0;

                if (this.options.faceType == FACE_TYPES.COLLECTIVE) {
                    const [width, height] = this.dimension(0);
                    sideEdgeHeight = height;

                    this.drawCollectiveBorder(width, height);
                } else {
                    this.options.drawers.forEach((drawer, index) => {
                        const [width, height, faceHeight, gap] = this.dimension(
                            parseFloat(drawer.drawer_face_height)
                        );
                        sideEdgeHeight += faceHeight + (index == 0 ? 0 : gap);

                        this.drawerBorders(
                            width,
                            height,
                            faceHeight,
                            parseFloat(drawer.drawer_face_height),
                            gap,
                            index
                        );
                    });
                }

                if (this.options.panelEdgeLeft) {
                    this.rectWithBorder(
                        this.widthOffset - 10,
                        this.heightOffset,
                        5,
                        sideEdgeHeight,
                        this.edgePattern
                    );
                }

                if (this.options.panelEdgeRight) {
                    this.rectWithBorder(
                        this.widthOffset + this.width + 5,
                        this.heightOffset,
                        5,
                        sideEdgeHeight,
                        this.edgePattern
                    );
                }
            };

            window.removeEventListener('drawers-painted', drawBorders);
            window.addEventListener('drawers-painted', drawBorders);

            const exteriorImage = await this.image(
                this.options.exteriorMaterial
            );
            const pattern = this.pattern(
                this.options.hasOwnProperty('horizontal') &&
                    this.options.horizontal,
                exteriorImage
            );

            this.options.drawers.forEach((drawer, index) => {
                const [width, height, faceHeight, gap] = this.dimension(
                    parseFloat(drawer.drawer_face_height)
                );
                this.offset[index] = faceHeight;

                this.drawDrawer(width, height, faceHeight, gap, index, pattern);
            });
        }
    }
}
