/**
 * This component renders main menu item based on configuration set in /src/config/base-config.json.
 *
 * The menu can be nested using "submenu": [] key/value pair within each menu items.
 */
import React, {useState, useEffect, useMemo} from 'react';

import {BaseConfig} from 'config';
import {useAppContext} from 'contexts';
import {CBCImage, Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Dropdown, ButtonGroup, Button} from 'react-bootstrap';
import {isMobile} from 'react-device-detect';
import {Link} from 'shared/reload/Link';
import {cloneDeep} from 'lodash';
import {useContactUsPopup} from 'hooks';
import {useAppDispatch} from 'store/customer';
import {
    logoutAction,
    redirectToSet,
} from 'components/customer/Auth/store/authSlice';

const MenuItem = ({
    menuItem,
}: {
    menuItem: {name: string, icon: string, url?: string, logout?: boolean},
}) => {
    const dispatch = useAppDispatch();
    const [isSubmenuVisible, setIsSubmenuVisible] = useState();
    const isSmallDevice = isDeviceSmall();
    const {dialog, showContactInfo} = useContactUsPopup();
    const {
        userProfile: {
            allowDirectLogin,
            parentApplicationUrl,
            parentApplicationName,
        },
    } = useAppContext();

    const signout = async () => {
        if (allowDirectLogin === 0 && !!parentApplicationUrl) {
            dispatch(redirectToSet(parentApplicationUrl));
        }
        dispatch(logoutAction());
    };

    // This function is only meant for mobile devices as there is no
    // hover css pseudo element in mobile devices
    const toggleMenu = (event) => {
        if (isMobile || isSmallDevice) {
            event.preventDefault();

            if (event.target === event.currentTarget) {
                setIsSubmenuVisible(!isSubmenuVisible);
            }
        }
    };

    const hideSubMenu = (event) => {
        const mainMenu = event.target.closest('.main-menu');

        if (!mainMenu) {
            setIsSubmenuVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', hideSubMenu);

        return () => {
            document.removeEventListener('click', hideSubMenu);
        };
    }, []);

    let item = (
        <Dropdown.Item as={Link} to={menuItem.url}>
            {<Icon iconName={menuItem.icon} />} {menuItem.name}
        </Dropdown.Item>
    );

    if (menuItem.hasOwnProperty('external') && menuItem.external) {
        item = (
            <Dropdown.Item href={menuItem.url} target="_blank">
                {<Icon iconName={menuItem.icon} />} {menuItem.name}
            </Dropdown.Item>
        );
    }

    if (menuItem.hasOwnProperty('logout') && menuItem.logout) {
        let logoutLabel = menuItem.name;
        if (allowDirectLogin === 0 && !!parentApplicationName) {
            logoutLabel = `Return to ${parentApplicationName}`;
        }
        item = (
            <Dropdown.Item
                as="div"
                style={{cursor: 'pointer', userSelect: 'none'}}
                onClick={signout}
                className="logout-menu">
                <Icon iconName={menuItem.icon} />
                {logoutLabel}
            </Dropdown.Item>
        );
    }

    if (menuItem.hasOwnProperty('modal') && menuItem.modal) {
        item = (
            <>
                <Dropdown.Item onClick={showContactInfo}>
                    {<Icon iconName={menuItem.icon} />} {menuItem.name}
                </Dropdown.Item>
                {dialog}
            </>
        );
    }

    if (
        menuItem.hasOwnProperty('submenu') &&
        typeof menuItem.submenu !== 'undefined' &&
        menuItem.submenu.length > 0
    ) {
        if (isMobile || isSmallDevice) {
            item = (
                <Dropdown.Item as="div" className="submenu">
                    <Dropdown show={isSubmenuVisible} drop="right">
                        <Dropdown.Toggle
                            onClick={toggleMenu}
                            role="submenu-toggle">
                            <Icon iconName={menuItem.icon} />
                            {menuItem.name}
                        </Dropdown.Toggle>
                        <Menu menu={menuItem.submenu} alignRight={true} />
                    </Dropdown>
                </Dropdown.Item>
            );
        } else {
            item = (
                <Dropdown.Item as="div" className="submenu">
                    <Dropdown show={true} drop="left">
                        <Dropdown.Toggle
                            onClick={toggleMenu}
                            role="submenu-toggle">
                            <Icon iconName={menuItem.icon} />
                            {menuItem.name}
                        </Dropdown.Toggle>
                        <Menu menu={menuItem.submenu} alignRight={true} />
                    </Dropdown>
                </Dropdown.Item>
            );
        }
    }

    return item;
};

const Menu = ({menu, alignRight}) => {
    alignRight = typeof alignRight === 'undefined' ? true : alignRight;

    const menuItems = menu.map((item, index) => (
        <MenuItem key={index} menuItem={item} />
    ));

    return <Dropdown.Menu alignRight={alignRight}>{menuItems}</Dropdown.Menu>;
};

export const MenuRenderer = () => {
    const {userLevel, userProfile} = useAppContext();
    const [isMainMenuVisible, setIsMainMenuVisible] = useState(false);
    const isSmallDevice = isDeviceSmall();
    const mainMenu = useMemo(() => {
        let menu =
            typeof BaseConfig[userLevel] === 'undefined'
                ? []
                : cloneDeep(BaseConfig[userLevel]['mainMenu']);
        let mainMenu = <></>;

        if (menu.length) {
            if (
                userProfile.hasOwnProperty(
                    'manufacturerHelpAndSupportDefaultOptions'
                ) &&
                userProfile.manufacturerHelpAndSupportDefaultOptions.length
            ) {
                const helpAndSupportMenu =
                    userProfile.manufacturerHelpAndSupportDefaultOptions.map(
                        (option) => ({
                            name: `${option.optionName}`,
                            url: `${option.optionUrl}`,
                            icon: 'Help.svg',
                            external: true,
                        })
                    );

                menu = menu.map((menuItem) => {
                    if (
                        menuItem.hasOwnProperty('id') &&
                        menuItem.id == 'help-and-support' &&
                        menuItem.hasOwnProperty('submenu')
                    ) {
                        menuItem.submenu = [
                            ...menuItem.submenu,
                            ...helpAndSupportMenu,
                        ];
                    }

                    return menuItem;
                });
            }

            if (!isSmallDevice) {
                menu = menu.filter(({onlyMobile}) => !onlyMobile);
            }

            mainMenu = <Menu menu={menu} alignRight={true} />;
        }

        return mainMenu;
    }, [userProfile, userLevel, isSmallDevice]);

    // This function is only meant for mobile devices as there is no hover
    // css pseudo element in mobile devices
    const toggleMainMenu = (event) => {
        event.preventDefault();
        setIsMainMenuVisible(!isMainMenuVisible);
    };

    const hideMenu = (event) => {
        const mainMenu = event.target.closest('.dropdown-toggle');
        const profileButton = event.target.closest('.dropdown-toggle-front');
        const withSubMenu = event.target.closest('.submenu');
        const logoutMenu = event.target.closest('.logout-menu');

        if (!mainMenu && !profileButton && !withSubMenu && !logoutMenu) {
            setIsMainMenuVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', hideMenu);

        return () => {
            document.removeEventListener('click', hideMenu);
        };
    }, []);

    return (
        <Dropdown show={isMainMenuVisible} as={ButtonGroup}>
            <Button
                role="mainmenu-toggle"
                variant="success"
                className="dropdown-toggle-front"
                onClick={toggleMainMenu}>
                <CBCImage src="Member-Icon.svg" alt="MemberIcon" />
            </Button>
            <Dropdown.Toggle id="dropdown-basic" onClick={toggleMainMenu}>
                <span>{userProfile.name}</span>
            </Dropdown.Toggle>
            {mainMenu}
        </Dropdown>
    );
};
