import React from 'react';
import {ErrorGIF} from 'assets';
import {Row, Col, Image, Container} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';

export const FourZeroFourRaw = ({}) => {
    return (
        <Container>
            <Row>
                <Col md={{span: 6, offset: 3}} style={{textAlign: 'center'}}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Image src={String(ErrorGIF)} style={{width: '200px'}} />
                    <p>
                        404 Page not found. The page you are looking for might
                        have been removed, had it&apos;s name changed or is
                        temporarily unavailable.
                    </p>
                    <CBCButton
                        className="button-blue four04-page-button"
                        asLink={true}
                        to="/v2">
                        Go to Dashboard
                    </CBCButton>
                </Col>
            </Row>
        </Container>
    );
};
