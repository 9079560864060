import React, {useMemo, useRef, useCallback, useState, useEffect} from 'react';
import {useBlocker, useNavigate} from 'react-router-dom';
import {ConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';

type NavigatorConfirmationDialogType = {
    showConfirmation: boolean;
    onClick: () => void;
    title?: string;
    message?: string;
};

type LocationType = {
    location: {pathname: string};
};

export const NavigatorConfirmationDialog = ({
    showConfirmation,
    onClick,
    ...props
}: NavigatorConfirmationDialogType) => {
    const navigate = useNavigate();
    const confirmed = useRef(false);
    const location = useRef<LocationType>();
    const [showModal, setShowModal] = useState(false);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        if (currentLocation.pathname !== nextLocation.pathname && !showModal) {
            location.current = {
                location: nextLocation,
            };

            return showConfirmation;
        }

        return false;
    });

    const hideModal = useCallback(() => {
        setShowModal(false);
    }, []);

    useEffect(() => {
        if (
            (blocker && blocker.state === 'blocked') ||
            blocker.state === 'proceeding'
        ) {
            setShowModal(true);
        }
    }, [blocker]);

    const options = useMemo(
        () => ({
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Proceed',
                    show: true,
                    variant: 'danger',
                    action: () => {
                        confirmed.current = true;

                        if (
                            typeof location.current.location.pathname ===
                            'string'
                        ) {
                            let state = {};
                            if ('state' in location.current.location) {
                                state = {
                                    state: location.current.location.state,
                                };
                            }

                            navigate(location.current.location.pathname, state);
                            confirmed.current = false;
                        }

                        onClick && onClick();
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                },
            ],
        }),
        [onClick]
    );

    return (
        <ConfirmationDialog
            {...props}
            show={showModal}
            options={options}
            hideDialog={hideModal}
            backdrop="static"
        />
    );
};
