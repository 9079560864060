import Shape from './Shape';
import {BI_FOLD} from './ComponentPainter';

/**
 *
 *
 * @export
 * @class LShape
 * @extends {Shape}
 */
export default class LShape extends Shape {
    /**
     *
     *
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @return {*}
     * @memberof LShape
     */
    dimension(leftWidth, rightWidth, leftDepth, rightDepth) {
        if (leftDepth > rightWidth) {
            throw new Error('Invalid Left Depth');
        }

        if (rightDepth > leftWidth) {
            throw new Error('Invalid Right Depth');
        }

        const staticWidth = this.canvas.width - this.widthOffset * 2 - 90;
        const staticHeight = this.canvas.height - this.heightOffset * 2;

        let newLeftWidth = leftWidth;
        let newRightWidth = rightWidth;

        if (newRightWidth > staticWidth || newLeftWidth > staticHeight) {
            newRightWidth =
                staticWidth < newRightWidth ? staticWidth : newRightWidth;
            newLeftWidth = Math.round(
                (staticWidth < rightWidth
                    ? staticWidth / rightWidth
                    : rightWidth / staticWidth) * leftWidth
            );

            if (newLeftWidth > staticHeight) {
                newLeftWidth = staticHeight;
                newRightWidth = Math.round(
                    (staticHeight < leftWidth
                        ? staticHeight / leftWidth
                        : leftWidth / staticHeight) * rightWidth
                );
            }
        }

        const newLeftDepth = Math.round(
            (newRightWidth / rightWidth) * leftDepth
        );
        const newRightDepth = Math.round(
            (newLeftWidth / leftWidth) * rightDepth
        );

        return [newLeftWidth, newRightWidth, newLeftDepth, newRightDepth];
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @memberof LShape
     */
    lines(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth) {
        this.line(
            [xOffset, yOffset - 15],
            [xOffset + rightWidth, yOffset - 15]
        );

        this.line([xOffset - 15, yOffset], [xOffset - 15, yOffset + leftWidth]);

        this.line(
            [xOffset + rightWidth + 15, yOffset],
            [xOffset + rightWidth + 15, yOffset + rightDepth]
        );

        this.line(
            [xOffset, yOffset + leftWidth + 15],
            [xOffset + leftDepth, yOffset + leftWidth + 15]
        );
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @memberof LShape
     */
    labels(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth) {
        this.label(
            [this.canvas.width / 2 - 35, yOffset - 25],
            [this.canvas.width / 2 - 30, yOffset - 12],
            'Right Width',
            70,
            20
        );

        this.label(
            [xOffset - 70 - 5, yOffset + leftWidth / 2 - 10],
            [xOffset - 70 + 5, yOffset + leftWidth / 2 + 3],
            'Left Width',
            70,
            20
        );

        this.label(
            [xOffset + leftDepth / 2 - 35, yOffset + leftWidth + 5],
            [xOffset + leftDepth / 2 - 30, yOffset + leftWidth + 20],
            'Left Depth',
            70,
            20
        );

        this.label(
            [xOffset + rightWidth + 5, yOffset + rightDepth / 2 - 10],
            [xOffset + rightWidth + 10, yOffset + rightDepth / 2 + 3],
            'Right Depth',
            70,
            20
        );
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @memberof LShape
     */
    biFold(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth) {
        this.context.beginPath();
        this.context.lineWidth = 2;
        this.context.translate(xOffset + leftDepth, yOffset + leftWidth);
        let angle = 15;

        this.context.moveTo(0, 0);
        if (this.options.biFold === BI_FOLD.LEFT) {
            if (leftDepth > rightDepth) {
                angle = angle - Math.abs(leftDepth - rightDepth) / 10;
            } else {
                angle = angle + Math.abs(leftDepth - rightDepth) / 15;
            }

            this.context.rotate((angle * Math.PI) / 180);
            this.context.lineTo(0, (leftWidth - rightDepth - 10) * -1);
            this.context.lineTo(
                (rightWidth - leftDepth - 10) / 2,
                (leftWidth - rightDepth - 10) * -1
            );
            this.context.stroke();

            this.context.rotate((-angle * Math.PI) / 180);
            this.context.translate(
                (xOffset + leftDepth) * -1,
                (yOffset + leftWidth) * -1
            );

            this.context.beginPath();
            this.context.translate(xOffset + rightWidth, yOffset + rightDepth);
            this.context.rotate((-(90 - angle) * Math.PI) / 180);
            this.context.moveTo(0, 0);
            this.context.lineTo(((rightWidth - leftDepth) / 2) * -1, 0);
            this.context.stroke();

            this.context.rotate(((90 - angle) * Math.PI) / 180);
            this.context.translate(
                (xOffset + rightWidth) * -1,
                (yOffset + rightDepth) * -1
            );
        } else if (this.options.biFold === BI_FOLD.RIGHT) {
            if (leftDepth < rightDepth) {
                angle = angle - Math.abs(leftDepth - rightDepth) / 10;
            }
            this.context.rotate(((90 - angle) * Math.PI) / 180);
            this.context.lineTo(0, ((leftWidth - rightDepth) / 2) * -1);
            this.context.stroke();

            this.context.rotate((-(90 - angle) * Math.PI) / 180);
            this.context.translate(
                (xOffset + leftDepth) * -1,
                (yOffset + leftWidth) * -1
            );

            this.context.beginPath();
            this.context.translate(xOffset + rightWidth, yOffset + rightDepth);
            this.context.rotate((-angle * Math.PI) / 180);
            this.context.moveTo(0, 0);
            this.context.lineTo((rightWidth - leftDepth - 10) * -1, 0);
            this.context.lineTo(
                (rightWidth - leftDepth - 10) * -1,
                (leftWidth - rightDepth - 10) / 2
            );

            this.context.stroke();

            this.context.rotate((angle * Math.PI) / 180);
            this.context.translate(
                (xOffset + rightWidth) * -1,
                (yOffset + rightDepth) * -1
            );
        }
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @return {*}
     * @memberof LShape
     */
    circleValues(
        xOffset,
        yOffset,
        leftWidth,
        rightWidth,
        leftDepth,
        rightDepth
    ) {
        const radius = Math.round(
            (this.options.insideRadius * leftWidth) / this.options.leftWidth
        );

        const curveCenterX = xOffset + leftDepth + radius;
        const curveCenterY = yOffset + rightDepth + radius;

        if (
            curveCenterX > xOffset + rightWidth ||
            curveCenterY > yOffset + leftWidth
        ) {
            throw new Error('Radius is too large');
        }

        return [radius, curveCenterX, curveCenterY];
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @param {*} edging
     * @param {number} [edgeOffset=0]
     * @memberof LShape
     */
    curve(
        xOffset,
        yOffset,
        leftWidth,
        rightWidth,
        leftDepth,
        rightDepth,
        edging,
        edgeOffset = 0
    ) {
        const [radius, curveCenterX, curveCenterY] = this.circleValues(
            xOffset,
            yOffset,
            leftWidth,
            rightWidth,
            leftDepth,
            rightDepth
        );

        this.context.beginPath();
        this.context.arc(
            curveCenterX + edgeOffset,
            curveCenterY + edgeOffset,
            radius,
            Math.PI,
            1.5 * Math.PI
        );
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(
            xOffset + leftDepth + edgeOffset,
            yOffset + leftWidth + edgeOffset
        );
        this.context.lineTo(
            xOffset + leftDepth + edgeOffset,
            yOffset + rightDepth + radius + edgeOffset
        );
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(
            xOffset + rightWidth + edgeOffset,
            yOffset + rightDepth + edgeOffset
        );
        this.context.lineTo(
            xOffset + leftDepth + radius + edgeOffset,
            yOffset + rightDepth + edgeOffset
        );
        this.context.stroke();

        if (!edging) {
            this.label(
                [
                    curveCenterX + (radius < 10 ? 20 : 0),
                    curveCenterY + (radius < 10 ? 20 : 0),
                ],
                [
                    curveCenterX + (radius < 10 ? 30 : 10),
                    curveCenterY + (radius < 10 ? 30 : 10),
                ],
                'Radius',
                70,
                20
            );
        }
    }

    /**
     *
     *
     * @param {*} xOffset
     * @param {*} yOffset
     * @param {*} leftWidth
     * @param {*} rightWidth
     * @param {*} leftDepth
     * @param {*} rightDepth
     * @return {*}
     * @memberof LShape
     */
    edges(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth) {
        this.context.lineWidth = this.edgeWidth;
        this.context.strokeStyle = this.edgeColor;

        const edgeOffset = this.edgeDistance + this.edgeWidth;

        // top
        if (this.options.edging.edgeLength1) {
            this.context.beginPath();
            this.context.moveTo(xOffset - edgeOffset, yOffset - edgeOffset);
            this.context.lineTo(
                xOffset + rightWidth + edgeOffset,
                yOffset - edgeOffset
            );
            this.context.stroke();
        }

        // left
        if (this.options.edging.edgeLength2) {
            this.context.beginPath();
            this.context.moveTo(xOffset - edgeOffset, yOffset - edgeOffset);
            this.context.lineTo(
                xOffset - edgeOffset,
                yOffset + leftWidth + edgeOffset
            );
            this.context.stroke();
        }

        // right
        if (this.options.edging.edgeWidth1) {
            this.context.beginPath();
            this.context.moveTo(
                xOffset + rightWidth + edgeOffset,
                yOffset - edgeOffset
            );
            this.context.lineTo(
                xOffset + rightWidth + edgeOffset,
                yOffset + rightDepth + edgeOffset
            );
            this.context.stroke();
        }

        // bottom
        if (this.options.edging.edgeWidth2) {
            this.context.beginPath();
            this.context.moveTo(
                xOffset - edgeOffset,
                yOffset + leftWidth + edgeOffset
            );
            this.context.lineTo(
                xOffset + leftDepth + edgeOffset,
                yOffset + leftWidth + edgeOffset
            );
            this.context.stroke();
        }

        this.context.strokeStyle = '#000';
        this.context.lineWidth = 3;

        return edgeOffset;
    }

    /**
     *
     *
     * @memberof LShape
     */
    draw() {
        const edging = this.options.hasOwnProperty('edging');
        const options = this.options;
        const curved = this.options.hasOwnProperty('insideRadius');

        const [leftWidth, rightWidth, leftDepth, rightDepth] = this.dimension(
            options.leftWidth,
            options.rightWidth,
            options.leftDepth,
            options.rightDepth
        );
        const xOffset =
            this.canvas.getBoundingClientRect().width / 2 - rightWidth / 2;
        const yOffset =
            this.canvas.getBoundingClientRect().height / 2 - leftWidth / 2;

        if (curved) {
            this.context.beginPath();
            this.context.lineWidth = 3;
            this.context.moveTo(xOffset, yOffset);
            this.context.lineTo(xOffset, yOffset + leftWidth);
            this.context.lineTo(xOffset + leftDepth, yOffset + leftWidth);
            this.context.stroke();

            if (!edging) {
                this.context.beginPath();
                this.context.lineWidth = 1;
                this.context.setLineDash([5, 3]);
                this.context.moveTo(xOffset + leftDepth, yOffset + leftWidth);
                this.context.lineTo(xOffset + leftDepth, yOffset + rightDepth);
                this.context.lineTo(xOffset + rightWidth, yOffset + rightDepth);
                this.context.stroke();
            }

            this.context.beginPath();
            this.context.lineWidth = 3;
            this.context.setLineDash([]);
            this.context.moveTo(xOffset + rightWidth, yOffset + rightDepth);
            this.context.lineTo(xOffset + rightWidth, yOffset);
            this.context.lineTo(xOffset, yOffset);
            this.context.stroke();

            this.curve(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth,
                edging
            );
        } else {
            this.context.beginPath();
            this.context.lineWidth = 3;
            this.context.moveTo(xOffset, yOffset);
            this.context.lineTo(xOffset, yOffset + leftWidth);
            this.context.lineTo(xOffset + leftDepth, yOffset + leftWidth);
            this.context.lineTo(xOffset + leftDepth, yOffset + rightDepth);
            this.context.lineTo(xOffset + rightWidth, yOffset + rightDepth);
            this.context.lineTo(xOffset + rightWidth, yOffset);
            this.context.lineTo(xOffset, yOffset);
            this.context.stroke();

            if (
                this.options.hasOwnProperty('bifold') &&
                options.leftWidth !== options.rightDepth &&
                options.rightWidth !== options.leftDepth
            ) {
                this.biFold(
                    xOffset,
                    yOffset,
                    leftWidth,
                    rightWidth,
                    leftDepth,
                    rightDepth
                );
            }
        }

        if (!edging) {
            this.lines(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );

            this.labels(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );
        } else {
            const edgeOffset = this.edges(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );

            if (this.options.edging.innerRadius) {
                this.context.lineWidth = this.edgeWidth;
                this.context.strokeStyle = this.edgeColor;

                this.curve(
                    xOffset,
                    yOffset,
                    leftWidth,
                    rightWidth,
                    leftDepth,
                    rightDepth,
                    edging,
                    edgeOffset
                );

                this.context.lineWidth = this.edgeWidth;
                this.context.strokeStyle = this.edgeColor;
            }
        }
    }
}
