import {HardwareOption} from 'components/customer/Product/entity';
import React from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Loader} from 'shared/helpers';
import {RadioCard} from 'shared/components/Forms/RadioCard';
import {Flex} from 'shared/components/StyledComponents';

export const DynamicRadioMobile = ({
    name,
    value,
    disabled = false,
    isLoading = false,
    yesOption,
    noOption,
    handleYesCheck,
    handleNoCheck,
    handleChange,
}: {
    name: string;
    value: boolean;
    disabled: boolean;
    isLoading: boolean;
    yesOption: HardwareOption;
    noOption: HardwareOption;
    handleYesCheck: () => void;
    handleNoCheck: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const isSmallDevice = isDeviceSmall();

    return (
        <>
            <Loader loader={isLoading} />
            <Flex $gap="15px">
                {!yesOption.hasOwnProperty('hidden') || !yesOption.hidden ? (
                    <RadioCard
                        type="yes"
                        isSelected={value}
                        handleCheck={handleYesCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={yesOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
                {!noOption.hasOwnProperty('hidden') || !noOption.hidden ? (
                    <RadioCard
                        type="no"
                        isSelected={!value}
                        handleCheck={handleNoCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={noOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
            </Flex>
        </>
    );
};
