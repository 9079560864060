import Rectangle from './Rectangle';
/**
 *
 *
 * @export
 * @class QFP
 * @extends {Rectangle}
 */
export default class QFP extends Rectangle {
    /**
     *
     *
     * @memberof QFP
     */
    dimension() {
        const width = this.options.width;
        const height = this.options.height;
        const staticWidth =
            this.canvas.width < 200 + this.widthOffset * 2 ? 130 : 200;

        if (this.showPair && this.options.widths) {
            const totalWidth =
                this.options.widths.map(Number).reduce((a, b) => a + b) +
                this.widthOffset * 3 +
                this.edgeWidth * 4;
            const widthRatio =
                totalWidth < staticWidth
                    ? totalWidth / staticWidth
                    : staticWidth / totalWidth;

            this.rectWidth = this.options.widths.map((width) =>
                Math.round(width * widthRatio)
            );
            this.rectHeight = Math.round(height * widthRatio);

            if (
                this.rectHeight + this.heightOffset * 2 + this.edgeWidth * 2 >
                this.canvas.height
            ) {
                this.rectHeight =
                    this.canvas.height -
                    this.heightOffset * 2 -
                    this.edgeWidth * 2;
                const ratio =
                    this.rectHeight < height
                        ? this.rectHeight / height
                        : height / this.rectHeight;

                this.rectWidth = this.options.widths.map((width) =>
                    Math.round(width * ratio)
                );
            }
        } else {
            if (width < height) {
                this.rectHeight = staticWidth;
                this.rectWidth = [
                    Math.round((this.rectHeight / height) * width),
                ];
            } else if (width === height) {
                this.rectWidth = [staticWidth];
                this.rectHeight = staticWidth;
            } else {
                this.rectWidth = [staticWidth];
                this.rectHeight = Math.round(
                    (this.rectWidth[0] / width) * height
                );
            }
        }
    }

    /**
     *
     *
     * @param {*} rectWidth
     * @param {*} rectXOffset
     * @memberof QFP
     */
    edges(rectWidth, rectXOffset) {
        this.context.lineWidth = this.edgeWidth;
        this.context.strokeStyle = this.edgeColor;
        this.edgeDistance = 2;

        const edgeOffset = this.edgeDistance + this.edgeWidth;
        const topEdge = [
            [rectXOffset - edgeOffset + 5, this.rectYOffset - edgeOffset],
            [
                rectXOffset + rectWidth + edgeOffset - 5,
                this.rectYOffset - edgeOffset,
            ],
        ];

        const leftEdge = [
            [
                rectXOffset - edgeOffset - this.edgeWidth,
                this.rectYOffset - edgeOffset + 15,
            ],
            [
                rectXOffset - edgeOffset - this.edgeWidth,
                this.rectYOffset + this.rectHeight + edgeOffset,
            ],
        ];

        const rightEdge = [
            [
                rectXOffset + rectWidth + edgeOffset,
                this.rectYOffset - edgeOffset + 5,
            ],
            [
                rectXOffset + rectWidth + edgeOffset,
                this.rectYOffset + this.rectHeight + edgeOffset - 5,
            ],
        ];

        const bottomEdge = [
            [
                rectXOffset - edgeOffset,
                this.rectYOffset +
                    this.rectHeight +
                    edgeOffset +
                    this.edgeWidth,
            ],
            [
                rectXOffset + rectWidth + edgeOffset - 15,
                this.rectYOffset +
                    this.rectHeight +
                    edgeOffset +
                    this.edgeWidth,
            ],
        ];

        this.options.edging.bottomEdge !== 0 &&
            this.drawLine(bottomEdge[0], bottomEdge[1]);
        this.options.edging.leftEdge !== 0 &&
            this.drawLine(leftEdge[0], leftEdge[1]);
        this.options.edging.rightEdge !== 0 &&
            this.drawLine(rightEdge[0], rightEdge[1]);
        this.options.edging.topEdge !== 0 &&
            this.drawLine(topEdge[0], topEdge[1]);

        this.context.strokeStyle = '#000';
        this.context.lineWidth = 3;
    }

    /**
     *
     *
     * @param {*} pattern
     * @param {*} rectWidth
     * @param {*} rectXOffset
     * @memberof QFP
     */
    cutout(pattern, rectWidth, rectXOffset) {
        const top = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.top
        );
        const left = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.left
        );
        const right = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.right
        );

        if (this.options.borders.bottom) {
            const bottom = Math.round(
                (this.rectHeight / this.options.height) *
                    this.options.borders.bottom
            );

            this.context.beginPath();
            this.context.moveTo(rectXOffset + left, this.rectYOffset + top);
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + top
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + this.rectHeight - bottom
            );
            this.context.lineTo(
                rectXOffset + left,
                this.rectYOffset + this.rectHeight - bottom
            );
            this.context.lineTo(rectXOffset + left, this.rectYOffset + top);
            this.context.fillStyle = '#fff';
            this.context.fill();
            this.context.stroke();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset + left + 1,
                this.rectYOffset + top + 1
            );
            this.context.lineTo(
                rectXOffset + left + 1,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 1 - 10,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 1,
                this.rectYOffset + top + 1
            );
            this.context.lineTo(
                rectXOffset + left + 1,
                this.rectYOffset + top + 1
            );
            this.context.fillStyle = '#d0d1cf';
            this.context.fill();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset + rectWidth - right - 1 - 10,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + top
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + this.rectHeight - bottom
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 1 - 10,
                this.rectYOffset + this.rectHeight - bottom
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 1 - 10,
                this.rectYOffset + top + 10
            );
            this.context.fillStyle = '#dfe0de';
            this.context.fill();
        } else {
            this.context.beginPath();
            this.context.moveTo(rectXOffset, this.rectYOffset);
            this.context.lineTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight
            );
            this.context.lineTo(
                rectXOffset + left,
                this.rectYOffset + this.rectHeight
            );
            this.context.lineTo(rectXOffset + left, this.rectYOffset + top);
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + top
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + this.rectHeight
            );
            this.context.lineTo(
                rectXOffset + rectWidth,
                this.rectYOffset + this.rectHeight
            );
            this.context.lineTo(rectXOffset + rectWidth, this.rectYOffset);
            this.context.lineTo(rectXOffset, this.rectYOffset);
            this.context.fillStyle = pattern;
            this.context.fill();
            this.context.stroke();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset - 10,
                this.rectYOffset + this.rectHeight + 1 + 10
            );
            this.context.lineTo(
                rectXOffset + left - 10,
                this.rectYOffset + this.rectHeight + 1 + 10
            );
            this.context.lineTo(
                rectXOffset + left,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.fillStyle = '#d0d1cf';
            this.context.fill();

            this.context.beginPath();
            this.context.moveTo(rectXOffset + left, this.rectYOffset + top);
            this.context.lineTo(
                rectXOffset + left,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + top
            );
            this.context.lineTo(rectXOffset + left, this.rectYOffset + top);
            this.context.fillStyle = '#d0d1cf';
            this.context.fill();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + top + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + top
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + this.rectHeight
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + this.rectHeight + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + top + 10
            );
            this.context.fillStyle = '#dfe0de';
            this.context.fill();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + this.rectHeight + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset + rectWidth - 1,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset + rectWidth - 1 - 10,
                this.rectYOffset + this.rectHeight + 1 + 10
            );
            this.context.lineTo(
                rectXOffset + rectWidth - right - 10,
                this.rectYOffset + this.rectHeight + 10
            );
            this.context.fillStyle = '#d0d1cf';
            this.context.fill();
        }
    }

    /**
     *
     *
     * @param {*} rectWidth
     * @param {*} rectXOffset
     * @memberof QFP
     */
    borders(rectWidth, rectXOffset) {
        const top = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.top
        );
        const left = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.left
        );
        const right = Math.round(
            (this.rectHeight / this.options.height) * this.options.borders.right
        );
        const bottom = Math.round(
            (this.rectHeight / this.options.height) *
                this.options.borders.bottom
        );

        this.context.beginPath();
        this.context.strokeStyle = 'gray';
        this.context.moveTo(rectXOffset + left, this.rectYOffset + top);
        this.context.lineTo(
            rectXOffset + rectWidth - right,
            this.rectYOffset + top
        );
        this.context.lineTo(
            rectXOffset + rectWidth - right,
            this.rectYOffset + this.rectHeight - bottom
        );
        this.context.lineTo(
            rectXOffset + left,
            this.rectYOffset + this.rectHeight - bottom
        );
        this.context.lineTo(rectXOffset + left, this.rectYOffset + top);
        this.context.stroke();

        this.context.beginPath();
        this.context.strokeStyle = '#d0d1cf';
        this.context.moveTo(rectXOffset + left + 1, this.rectYOffset + top + 1);
        this.context.lineTo(
            rectXOffset + rectWidth - right - 1,
            this.rectYOffset + top + 1
        );
        this.context.lineTo(
            rectXOffset + rectWidth - right - 1,
            this.rectYOffset + this.rectHeight - bottom - 1
        );
        this.context.lineTo(
            rectXOffset + left + 1,
            this.rectYOffset + this.rectHeight - bottom - 1
        );
        this.context.lineTo(rectXOffset + left + 1, this.rectYOffset + top + 1);
        this.context.stroke();
    }

    /**
     *
     *
     * @param {*} rectWidth
     * @param {*} previousWidth
     * @param {*} index
     * @return {*}
     * @memberof QFP
     */
    async drawPreview(rectWidth, previousWidth, index) {
        let rectXOffset =
            this.widthOffset +
            (previousWidth > 0
                ? previousWidth + this.edgeWidth * 2 + this.widthOffset
                : 0);

        if (!this.showPair || typeof this.options.widths === 'undefined') {
            rectXOffset =
                this.canvas.getBoundingClientRect().width / 2 -
                this.rectWidth / 2;
        }

        this.rectYOffset =
            this.canvas.getBoundingClientRect().height / 2 -
            this.rectHeight / 2 +
            0.5;

        if (
            this.hasBorders &&
            this.options.width <
                this.options.borders.left + this.options.borders.right
        ) {
            return;
        }

        if (
            this.hasBorders &&
            this.options.height <
                this.options.borders.top + this.options.borders.bottom
        ) {
            return;
        }

        const edgeImage = await this.image(this.options.edgeMaterial);
        const edgePattern = this.pattern(this.isHorizontal, edgeImage);

        this.context.beginPath();
        this.context.moveTo(rectXOffset - 1, this.rectYOffset);
        this.context.lineTo(
            rectXOffset - 1,
            this.rectYOffset + this.rectHeight
        );
        this.context.lineTo(
            rectXOffset - 1 - this.edgeWidth,
            this.rectYOffset + this.rectHeight + this.edgeWidth
        );
        this.context.lineTo(
            rectXOffset - 1 - this.edgeWidth,
            this.rectYOffset + this.edgeWidth
        );
        this.context.lineTo(rectXOffset - 1, this.rectYOffset);
        this.context.fillStyle =
            this.options.edging.leftEdge !== 0 ? edgePattern : '#dfe0de';
        this.context.fill();

        this.context.beginPath();
        this.context.moveTo(rectXOffset - 1, this.rectYOffset);
        this.context.lineTo(
            rectXOffset - 1 - this.edgeWidth,
            this.rectYOffset + this.edgeWidth
        );
        this.context.lineTo(
            rectXOffset - 1 - this.edgeWidth,
            this.rectYOffset + this.rectHeight + this.edgeWidth
        );
        this.context.lineWidth = 1;
        this.context.strokeStyle = 'gray';
        this.context.stroke();

        if (
            (this.hasCutout && this.options.borders.bottom) ||
            !this.hasCutout
        ) {
            this.context.beginPath();
            this.context.moveTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset + rectWidth - 1,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset + rectWidth - this.edgeWidth - 1,
                this.rectYOffset + this.rectHeight + this.edgeWidth
            );
            this.context.lineTo(
                rectXOffset - this.edgeWidth,
                this.rectYOffset + this.rectHeight + this.edgeWidth
            );
            this.context.lineTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.fillStyle =
                this.options.edging.bottomEdge !== 0 ? edgePattern : '#d0d1cf';
            this.context.fill();

            this.context.beginPath();
            this.context.moveTo(
                rectXOffset,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineTo(
                rectXOffset - this.edgeWidth,
                this.rectYOffset + this.rectHeight + this.edgeWidth
            );
            this.context.lineTo(
                rectXOffset + rectWidth - this.edgeWidth - 1,
                this.rectYOffset + this.rectHeight + this.edgeWidth
            );
            this.context.lineTo(
                rectXOffset + rectWidth - 1,
                this.rectYOffset + this.rectHeight + 1
            );
            this.context.lineWidth = 1;
            this.context.strokeStyle = 'gray';
            this.context.stroke();
        }

        const exteriorImage = await this.image(this.options.exteriorMaterial);
        const exteriorPattern = this.pattern(this.isHorizontal, exteriorImage);

        if (
            (this.hasCutout && this.options.borders.bottom) ||
            !this.hasCutout
        ) {
            this.context.beginPath();
            this.context.fillStyle = exteriorPattern;
            this.context.rect(
                rectXOffset,
                this.rectYOffset,
                rectWidth,
                this.rectHeight
            );
            this.context.fill();

            this.context.lineWidth = 1;
            this.context.strokeStyle = 'gray';
            this.context.stroke();
        }

        this.hasCutout && this.cutout(exteriorPattern, rectWidth, rectXOffset); // drawing inverted "u" from within borders as we need border values for that
        !this.hasCutout &&
            this.hasBorders &&
            this.borders(rectWidth, rectXOffset);

        this.edges(rectWidth, rectXOffset);

        const labelValue =
            this.showPair && this.options.widths
                ? this.options.widths[index]
                : this.options.width;

        if (this.showPair)
            this.horizontalLabelQFP(
                rectWidth + this.edgeWidth * 2,
                rectXOffset - this.edgeWidth,
                this.rectYOffset,
                labelValue + ' ' + this.options.metric
            );
        else {
            this.horizontalLabel(
                rectWidth,
                rectXOffset,
                this.rectYOffset,
                labelValue + ' ' + this.options.metric
            );
        }
    }

    /**
     *
     *
     * @param {*} rectWidth
     * @param {*} rectXOffset
     * @param {*} rectYOffset
     * @param {*} text
     * @memberof QFP
     */
    horizontalLabelQFP(rectWidth, rectXOffset, rectYOffset, text) {
        this.line(
            [rectXOffset, rectYOffset - 15],
            [rectXOffset + rectWidth, rectYOffset - 15]
        );

        this.label(
            [rectXOffset + parseInt(rectWidth / 2) - 30, rectYOffset - 50],
            [rectXOffset + parseInt(rectWidth / 2) - 15, rectYOffset - 22],
            text,
            60,
            20
        );
    }

    /**
     *
     *
     * @return {*}
     * @memberof QFP
     */
    async draw() {
        this.widthOffset = 20;
        if (this.options.width <= 0 || this.options.height <= 0) return;
        if (typeof this.options.qfpType === 'undefined') return;

        this.edgeWidth = 5;
        this.showPair = false;

        if (
            this.options.hasOwnProperty('hangType') &&
            this.options.hangType > 1
        ) {
            this.showPair = true;
        }

        this.dimension();
        this.hasBorders =
            this.options.hasOwnProperty('hasBorders') &&
            this.options.hasBorders;
        this.hasCutout =
            this.options.hasOwnProperty('hasCutout') && this.options.hasCutout;
        this.isHorizontal =
            this.options.hasOwnProperty('horizontal') &&
            this.options.horizontal;

        let previousWidth = 0;
        let index = 0;

        while (index < this.rectWidth.length) {
            const rectWidth = this.rectWidth[index];

            await this.drawPreview(rectWidth, previousWidth, index);

            previousWidth = rectWidth;
            index++;
        }

        let width = this.rectWidth[0];

        if (this.showPair && this.options.widths) {
            width =
                this.rectWidth.reduce((a, b) => a + b) + this.widthOffset + 3.5;
        }

        const showPair = this.showPair && this.options.widths;

        let verticalXOffset =
            this.canvas.getBoundingClientRect().width / 2 - width / 2;
        let verticalWidth = width;

        if (showPair) {
            const rectXOffsetVert =
                this.widthOffset +
                (previousWidth > 0
                    ? previousWidth + this.edgeWidth * 2 + this.widthOffset
                    : 0);

            const edgeOffsetVert = this.edgeDistance + this.edgeWidth;
            verticalXOffset = rectXOffsetVert + edgeOffsetVert;
            verticalWidth = this.rectWidth[0];
        }

        this.verticalLabel(
            verticalWidth,
            this.rectHeight,
            verticalXOffset,
            this.rectYOffset,
            this.options.height + ' ' + this.options.metric
        );

        this.hangSize = 2;
        if (
            this.options.hasOwnProperty('doorHang') &&
            this.options.hangType != 2
        ) {

            this.drawHang({
                xOffset: this.showPair && this.options.widths
                    ? this.widthOffset
                    : this.canvas.getBoundingClientRect().width / 2 - width / 2,
                yOffset: this.rectYOffset,
                width: this.showPair && this.options.widths
                    ? this.rectWidth.reduce((a, b) => a + b) +
                          this.widthOffset +
                          10.5
                    : width,
                height: this.rectHeight,
                isRight: this.options.doorHang > 0,
                small: false,
                horizontalHinge: false,
                reverseDoorHang: this.options.doorHangReverse,
            });

        } else {
            if (
                this.options.hasOwnProperty('doorHang') &&
                this.options.doorHang == 1
            ) {
                previousWidth = 0;

                this.rectWidth.forEach((width, index) => {
                    const rectXOffset =
                        this.widthOffset +
                        (previousWidth > 0
                            ? previousWidth +
                              this.edgeWidth * 2 +
                              this.widthOffset
                            : 0);

                    this.drawHang({
                        xOffset: rectXOffset,
                        yOffset: this.rectYOffset,
                        width: width,
                        height: this.rectHeight,
                        isRight: index > 0,
                        small: true,
                        horizontalHinge: false,
                        reverseDoorHang: this.options.doorHangReverse,
                    });

                    previousWidth = width;
                });
            }
        }
    }
}
