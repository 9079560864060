// @flow
import type {Handler} from './Handler';

type CacheDataType = {
    url: string,
    promise: Promise<any>,
};
/**
 *
 *
 * @export
 * @class CacheHandler
 * @implements {Handler<T>}
 */
export default class CacheHandler<T> implements Handler<T> {
    resetCache: boolean = false;
    handler: Handler<T>;
    static cacheDataArray: CacheDataType[] = [];
    url: string;
    objectProcessor: (object: Object) => T;

    /**
     * Creates an instance of IDBHandler.
     * @param {string} url
     * @param {ObjectProcessor} objectProcessor
     * @memberof IDBHandler
     */
    constructor(url: string, objectProcessor: (data: Object) => T) {
        this.url = url;
        this.objectProcessor = objectProcessor;
    }

    /**
     *
     *
     * @static
     * @return {*}  {(Promise<any> | void)}
     * @memberof CacheHandler
     */
    getCache(): Promise<any> | null {
        const networkRequest: ?CacheDataType = CacheHandler.cacheDataArray.find(
            (cacheData: CacheDataType) => cacheData.url === this.url
        );

        if (networkRequest) {
            return networkRequest.promise;
        }

        return null;
    }

    /**
     *
     *
     * @memberof CacheHandler
     */
    deleteCache(): void {
        CacheHandler.cacheDataArray = CacheHandler.cacheDataArray.filter(
            (cacheData: CacheDataType) => cacheData.url !== this.url
        );
    }

    /**
     *
     * @param {Promise<any>} promise
     * @memberof CacheHandler
     */
    setCache(promise: Promise<any>): void {
        CacheHandler.cacheDataArray.push({
            url: this.url,
            promise,
        });
    }

    /**
     *
     *
     * @param {Handler<T>} handler
     * @return {*}  {Handler<T>}
     * @memberof CacheHandler
     */
    addHandler(handler: Handler<T>): Handler<T> {
        this.handler = handler;

        return handler;
    }

    /**
     *
     *
     * @param {boolean} resetCache
     * @memberof CacheHandler
     */
    setResetCache(resetCache: boolean) {
        this.resetCache = resetCache;
    }

    /**
     *
     *
     * @template T
     * @param {T} object
     * @return {*}  {Promise<Array<T>>}
     * @memberof HTTPHandler
     */
    getData(): Promise<T[]> {
        if (this.resetCache) {
            this.deleteCache();
        }

        let promise: ?Promise<any> = this.getCache();

        try {
            if (promise == null) {
                promise = this.handler.getData();
                this.setCache(promise);
            }

            return promise;
        } catch (e) {
            this.deleteCache();
            throw e;
        }
    }

    /**
     *
     *
     * @param {T[]} data
     * @memberof HTTPHandler
     */
    setData(data: T[]) {}
}
