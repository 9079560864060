import {useCallback, useState} from 'react';

interface SvgModule {
    default: string;
}

const importAsModule = async (iconName: string) => {
    try {
        const module = (await import(
            `../../assets/images/icons/${iconName}`
        )) as SvgModule;

        return module.default;
    } catch (e) {
        // console.log(e);
    }
};

const importFromUrl = async (url: string) => {
    try {
        const response = await fetch(url, {cache: 'force-cache'});

        return await response.text();
    } catch (e) {
        // console.log(e);
    }
};

export const useFetchSVG = (
    primaryColor: string,
    secondaryColor: string,
    color?: 'primary' | 'secondary' | string
) => {
    const [imageData, setImageData] = useState<string>();
    const [imageFetched, setImageFetched] = useState<boolean>(false);

    const fetchIcon = useCallback(
        async (url: string, as: 'MODULE' | 'URL') => {
            if (url != null) {
                try {
                    let svg: string;

                    if (as == 'MODULE') {
                        svg = await importAsModule(url);
                    } else {
                        svg = await importFromUrl(url);
                    }

                    if (color) {
                        let replacingColor = color;

                        if (color == 'primary') {
                            replacingColor = primaryColor;
                        } else if (color == 'secondary') {
                            replacingColor = secondaryColor;
                        }
                        svg = svg.replace(/#1392CD/gi, replacingColor);
                        svg = svg.replace(/#204380/gi, replacingColor);
                    } else {
                        svg = svg.replace(/#1392CD/gi, secondaryColor);
                        svg = svg.replace(/#204380/gi, primaryColor);
                    }

                    const imageBase64: string = btoa(svg);

                    setImageData(`data:image/svg+xml;base64, ${imageBase64}`);
                    setImageFetched(true);
                } catch (e) {
                    // handle error here
                }
            }
        },
        [primaryColor, secondaryColor]
    );

    return {
        imageData,
        fetchIcon,
        imageFetched,
    };
};
