import React from 'react';
import {isMobile} from 'react-device-detect';

interface MobileRestrictionProps {
    children: React.ReactNode;
    message: string;
}

export const MobileRestriction = ({
    children,
    message,
}: MobileRestrictionProps) => {
    if (isMobile) {
        return <strong>{message}</strong>;
    }

    return children;
};
