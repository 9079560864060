import {BreakdownItem} from 'components/customer/Job/store/jobApi';
import {useJobContext} from 'contexts';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MoreInfo} from 'shared';
import {formatPrice} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';

interface Props {
    breakdown?: BreakdownItem[];
    cost: number;
    messages?: string[];
}

interface Item {
    name: string;
    cost?: number;
    items?: Item[];
    collapse?: boolean;
    total?: number;
}

const mapTotals = (item: Item): Item => {
    const ret = {...item};

    if (item.items) {
        ret.items = item.items.map(mapTotals);
    }

    ret.total =
        item?.cost ??
        ret?.items.reduce((total, item) => total + item.total, 0) ??
        0;

    return ret;
};

export const PricingWebhook: React.FC<Props> = ({
    breakdown,
    cost,
    messages,
}) => {
    const {job} = useJobContext() as PartialJob;
    const [collapsed, setCollapsed] = useState<Map<string, boolean>>(new Map());

    useEffect(() => {
        if (breakdown) {
            const initialCollapsedState = new Map<string, boolean>(
                breakdown.map((item) => [item.name, true])
            );
            setCollapsed(initialCollapsedState);
        }
    }, [breakdown]);

    const toggleCollapse = useCallback(
        (itemName: string) => (event: React.MouseEvent) => {
            event.preventDefault();
            setCollapsed((prevState) => {
                const newState = new Map(prevState);
                const isCollapsed = newState.get(itemName);
                newState.set(itemName, !isCollapsed);
                return newState;
            });
        },
        []
    );

    const breakdownWithTotals = useMemo(() => {
        if (breakdown) {
            return breakdown.map(mapTotals);
        }
        return [];
    }, [breakdown]);

    return (
        <ul className="totals">
            {breakdownWithTotals?.map((item) => {
                const isIndexCollapsed = collapsed.get(item.name) || false;

                return (
                    <li
                        key={item.name}
                        style={item.collapse ? {display: 'block'} : {}}>
                        {item.collapse ? (
                            <span onClick={toggleCollapse(item.name)}>
                                {isIndexCollapsed ? '+' : '-'} {item.name}
                            </span>
                        ) : (
                            <span>{item.name}</span>
                        )}

                        {item.hasOwnProperty('collapse') && isIndexCollapsed ? (
                            <span style={{float: 'right'}}>
                                {formatPrice(item.total, {})}
                            </span>
                        ) : null}

                        {item?.cost ? (
                            <span>{formatPrice(item.total, {})}</span>
                        ) : null}
                        {item.items && !isIndexCollapsed ? (
                            <ul>
                                {item.items.map((subItem) => (
                                    <li key={subItem.name}>
                                        <span>{subItem.name}</span>
                                        <span>
                                            {formatPrice(subItem.cost, {})}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </li>
                );
            })}

            <li className="cost">
                <span style={{display: 'flex'}}>
                    Total Cost:{' '}
                    {messages?.length > 0 ? (
                        <MoreInfo
                            minUsage={true}
                            editbuttonVisible={false}
                            hoverText="Pricing Information"
                            dialogClassName="modal-md"
                            info={
                                <ul>
                                    {messages.map((message) => (
                                        <li key={message}>{message}</li>
                                    ))}
                                </ul>
                            }
                        />
                    ) : null}
                </span>
                <span>{cost ? formatPrice(cost, {}) : '...'}</span>
            </li>
            {job.hasOwnProperty('priceExpireDate') ? (
                <li>
                    Pricing is valid until&nbsp;
                    <strong>{job.priceExpireDate}</strong>
                </li>
            ) : null}
        </ul>
    );
};
