import React from 'react';
import {Form, Image} from 'react-bootstrap';
import {MoreInfo} from 'shared';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';

interface RadioCardProps {
    type: 'yes' | 'no';
    isSelected: boolean;
    handleCheck: () => void;
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    fieldName: string;
    option: {
        image: string;
        description: string;
        name?: string;
    };
    isSmallDevice?: boolean;
    isTabletSize?: boolean;
}

export const RadioCard = ({
    isSelected,
    handleCheck,
    type,
    disabled,
    option,
    isSmallDevice,
    fieldName,
    handleRadioChange,
}: RadioCardProps) => {
    const disabledUi = disabled && type === 'no';
    return (
        <RadioContainer
            className="cbc-radio"
            onClick={handleCheck}
            $isSelected={isSelected}
            $disabled={disabledUi}>
            {option.image || option.description ? (
                <ImageWrapper>
                    {option.image ? (
                        <ImageSection>
                            {React.isValidElement(option.image) ? (
                                option.image
                            ) : (
                                <Image src={`/${option.image}`} />
                            )}
                        </ImageSection>
                    ) : null}
                </ImageWrapper>
            ) : null}

            <TitleWrapper>
                <Title $isSelected={isSelected}>
                    {option.name ? option.name : type === 'yes' ? 'Yes' : 'No'}
                </Title>
            </TitleWrapper>

            <MoreInfoContainer>
                {option.description ? (
                    <span
                        id={`${fieldName}_${type}_option`}
                        className="dynamic-radio-more-info">
                        <MoreInfo info={option.description} dynamicposition />
                    </span>
                ) : null}
            </MoreInfoContainer>

            <RadioInputContainer>
                <RadioInput
                    $isSelected={isSelected}
                    type="radio"
                    id={`boolean_${fieldName}_${type}`}
                    name={fieldName}
                    value={type === 'yes' ? '1' : '0'}
                    disabled={disabled}
                    checked={isSelected}
                    onChange={handleRadioChange}
                    className={`dynamic-radio-checkbox ${
                        isSmallDevice ? 'small' : ''
                    }`}
                    label={undefined}
                />
                <CheckIconContainer $isSelected={isSelected}>
                    <Icon
                        iconName="Button-Tick-Secondary.svg"
                        color="primary"
                    />
                </CheckIconContainer>
            </RadioInputContainer>
        </RadioContainer>
    );
};

const RadioContainer = styled.div<{
    $isSelected?: boolean;
    $disabled?: boolean;
}>`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    overflow: hidden;
    border: ${({$isSelected, theme}) =>
        $isSelected
            ? `2px solid ${theme.colors.primary.main}`
            : '2px solid transparent'};
    background: ${({$disabled}) => ($disabled ? '#e0e0e0' : '#fff')};
    cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
    width: ${({theme}) =>
        theme.device.isSmall ? 'calc(50vw - 40px)' : '200px'};
    height: fit-content;
`;

const ImageWrapper = styled.section`
    display: flex;
    padding: 15px 0;
    flex-wrap: wrap;
    justify-content: center;
`;

const ImageSection = styled.section`
    cursor: pointer;
    width: 90px;
    svg {
        width: 90px;
        height: 90px;
    }
`;

const RadioInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 15px;
    position: absolute;
    top: 7px;
    left: 7px;
`;

const RadioInput = styled(Form.Check)<{
    $isSelected?: boolean;
}>`
    display: ${({$isSelected}) => ($isSelected ? 'none' : 'block')};
`;

const CheckIconContainer = styled.div<{
    $isSelected?: boolean;
}>`
    img {
        width: 16px;
        display: ${({$isSelected}) => (!$isSelected ? 'none' : 'block')};
    }
`;

const MoreInfoContainer = styled.div`
    min-width: 22px;
    position: absolute;
    top: 6px;
    right: 7px;
`;

const Title = styled.div<{
    $isSelected?: boolean;
}>`
    flex-grow: 1;
    text-align: center;
    font-size: 11.5px;
    color: ${({$isSelected, theme}) =>
        !$isSelected ? theme.colors.primary.main : '#fff'};
    font-weight: 600;
    padding: 7px;
    background: ${({$isSelected, theme}) =>
        $isSelected ? theme.colors.primary.main : 'inherit'};
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
