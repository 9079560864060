import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Icon, useQueryParams} from 'shared/helpers';
import {useProductContext} from 'contexts';

export const Search = () => {
    const {rails} = useQueryParams();
    const [searchLocal, setSearchLocal] = useState('');
    const {search, setSearch, breadcrumbs, removeBreadCrumb} =
        useProductContext();

    const searchKeyHandler = () => {
        setSearch(searchLocal);
    };

    useEffect(() => {
        if (rails) {
            setSearch('rail');
        }
    }, [rails]);

    useEffect(() => {
        setSearchLocal(search);
    }, [search]);

    const searchKeyInputHandler = (e) => {
        if (e.which === 13) {
            // i.e. user hits enter key
            searchKeyHandler();
        }
    };

    const formatSearchKey = (key) =>
        key
            .split(' ')
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
            .join('');

    return (
        <>
            <div className="searchKeysNInput">
                {breadcrumbs.map((searchItem, index) => {
                    if (breadcrumbs.length - 1 === index) {
                        return (
                            <div className="searchKey hasHandler" key={index}>
                                <div>{formatSearchKey(searchItem.key)}</div>
                                <div
                                    onClick={() => {
                                        removeBreadCrumb(searchItem.key);
                                    }}>
                                    &times;
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className="searchKey" key={index}>
                            {formatSearchKey(searchItem.key)}
                        </div>
                    );
                })}

                {search && search.length ? (
                    <div className="searchKey hasHandler">
                        <div>{search}</div>
                        <div
                            onClick={() => {
                                setSearchLocal('');
                                setSearch('');
                            }}>
                            &times;
                        </div>
                    </div>
                ) : (
                    <Form.Control
                        as="input"
                        className="searchField"
                        onKeyUp={searchKeyInputHandler}
                        value={searchLocal}
                        onChange={(e) => setSearchLocal(e.target.value)}
                    />
                )}
            </div>
            <Button
                variant="link"
                type="button"
                className="searchButton"
                onClick={searchKeyHandler}>
                <Icon iconName="search-black.svg" />
            </Button>
        </>
    );
};
