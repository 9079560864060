import React, {useRef, useEffect, useCallback} from 'react';

const OutsideClickHandler = (props) => {
    const wrapperRef = useRef(null);

    const {children, onOutsideClick} = props;

    const handleClickOutside = useCallback(
        (event) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                onOutsideClick();
            }
        },
        [onOutsideClick]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;
