import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {ProcessingJobRoute} from 'shared/auth/ProcessingJobRoute';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';

export const JobRoute = ({
    checkProcessingStatus = false,
}: {
    checkProcessingStatus?: boolean;
}) => {
    const {job, setJob} = useJobContext() as {
        job: PartialJob;
        setJob: (job: PartialJob) => void;
    };

    useEffect(() => {
        return () => {
            setJob({} as PartialJob);
        };
    }, []);

    if (job.error) {
        return <Navigate to="/v2/404" replace={true} />;
    } else {
        if (checkProcessingStatus) {
            return <ProcessingJobRoute />;
        }

        return <Outlet />;
    }
};
