import React, {useCallback, useMemo} from 'react';
import {Button, Nav, Col, Row} from 'react-bootstrap';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

import './Wizard.scss';

export const Wizard = ({
    children,
    currentTab,
    setCurrentTab,
    tabErrors,
    id,
    className,
    lazy = false,
    inSizesTab = false,
    imageColumn,
    wizardHeaderRef = null,
}) => {
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    const tabClick = useCallback((key) => {
        setCurrentTab(parseInt(key));
    }, []);

    const filterChildren = useCallback((child) => {
        if (child.props.hasOwnProperty('hide') && child.props.hide) {
            return false;
        }

        if (
            child.props.hasOwnProperty('visibility') &&
            !child.props.visibility
        ) {
            return false;
        }

        return true;
    }, []);

    const setRef = useCallback((el: HTMLDivElement) => {
        if (wizardHeaderRef && el) {
            wizardHeaderRef.current = el;
        }
    }, []);

    const navigationData = useMemo(() => {
        return children.filter(filterChildren).map((child, index) => {
            return {
                eventKey: child.props.eventKey,
                title: child.props.title,
                hasPadding: child.props.hasOwnProperty('noPadding')
                    ? !child.props.noPadding
                    : true,
            };
        });
    }, [children]);

    const [navigation, hasPadding, title] = useMemo(() => {
        {
            let hasPadding = true;
            let navigation = null;
            let title = '';
            if (!isSmallDevice) {
                navigation = (
                    <Nav
                        fill
                        activeKey={currentTab}
                        className="cbc-wizard"
                        id={id}>
                        {navigationData.map((child, index) => {
                            const errors = tabErrors && tabErrors[index];

                            if (currentTab == child.eventKey) {
                                hasPadding = child.hasPadding;
                                title = child.title;
                            }

                            return (
                                <Nav.Link
                                    className="nav-item"
                                    eventKey={child.eventKey}
                                    as={Button}
                                    key={index}
                                    onClick={() => tabClick(child.eventKey)}>
                                    <div className="tab-title">
                                        {child.title}
                                    </div>
                                    {errors ? (
                                        <div className="tab-errors">
                                            {errors} error
                                            {errors > 1 ? 's' : ''}
                                        </div>
                                    ) : null}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                );
            } else {
                const currentChild = navigationData.find(
                    (navigationDatum) => navigationDatum.eventKey == currentTab
                );

                if (currentChild) {
                    hasPadding = currentChild.hasPadding;
                    title = currentChild.title;
                    navigation = (
                        <div className="cbc-wizard-title" ref={setRef}>
                            <span>{currentChild.title}</span>
                        </div>
                    );
                }
            }

            return [navigation, hasPadding, title];
        }
    }, [navigationData, isSmallDevice, tabClick, currentTab, tabErrors]);

    const showDynamicColumns = (inSizesTab) => {
        const filteredChildren = children
            .filter(filterChildren)
            .filter((child) => {
                if (lazy && child.props.eventKey != currentTab) {
                    return false;
                }
                return true;
            });

        const displayChild = (child, index) => {
            const display = child.props.eventKey == currentTab;
            return React.cloneElement(child, {
                props: child.props,
                className: !display ? 'section-hidden' : '',
            });
        };

        if (inSizesTab === true) {
            return (
                <Row>
                    <Col
                        md={isTabletSize ? 4 : 3}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                        {imageColumn}
                    </Col>
                    <Col
                        md={isTabletSize ? 8 : 9}
                        style={
                            isTabletSize
                                ? {paddingLeft: 0, paddingRight: 0}
                                : {}
                        }>
                        {filteredChildren.map(displayChild)}
                    </Col>
                </Row>
            );
        } else {
            return <>{filteredChildren.map(displayChild)}</>;
        }
    };

    return (
        <>
            {navigation}
            <div
                style={hasPadding ? {} : {paddingLeft: 0, paddingRight: 0}}
                className={
                    typeof className !== 'undefined'
                        ? `cbc-wizard-content ${className} ${title}`
                        : `cbc-wizard-content ${title}`
                }>
                {showDynamicColumns(inSizesTab)}
            </div>
        </>
    );
};
