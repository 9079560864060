import {parseHtmlString} from 'shared/helpers/HTMLParser';

export const mapErrors = (
    errors: string[] | string,
    smallLayout = false
): ReturnType<typeof parseHtmlString> => {
    if (Array.isArray(errors)) {
        if (smallLayout) {
            return `* ${errors.join('\n* ')}`;
        }

        return parseHtmlString(`<ul><li>${errors.join('</li><li>')}</li></ul>`);
    }

    return errors;
};
