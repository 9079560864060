import React, {useCallback, useMemo, useRef} from 'react';
import {NoImageAvailable, NoImageAvailablePotrait} from 'assets';

export const Image = ({
    src,
    className = '',
    alt = '',
    potrait = false,
}: {
    src: string;
    className?: string;
    alt?: string;
    potrait?: boolean;
}) => {
    const image = useMemo(() => {
        if (potrait) {
            return String(NoImageAvailablePotrait);
        }

        return String(NoImageAvailable);
    }, [potrait]);

    const imageReference = useRef<HTMLImageElement>(null);
    const imageErorHandler = useCallback(() => {
        if (imageReference.current) {
            imageReference.current.src = image;
        }
    }, [image]);

    return (
        <img
            ref={imageReference}
            onError={imageErorHandler}
            src={typeof src == 'undefined' ? image : src}
            alt={alt}
            className={className}
        />
    );
};
