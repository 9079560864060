import React, {PropsWithChildren, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';

export const PageEvents = ({children}: PropsWithChildren) => {
    const location = useLocation();

    useEffect(
        () =>
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search + location.hash,
            }),
        [location]
    );

    return <>{children}</>;
};
