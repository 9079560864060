import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {JOB_STATUS} from 'components/customer/Dashboard/LastJobButton';

export const ProcessingJobRoute = () => {
    const {isJobProcessing, job} = useJobContext() as PartialJob;

    if (
        isJobProcessing ||
        job.status == JOB_STATUS.JOB_PENDING_CONFIRMATION ||
        job.status == JOB_STATUS.PENDING_APPROVAL
    ) {
        // only show job dashboard
        // 1. if job is processing
        // 2. or job is pending confirmation
        // 3. or job is pending approval
        return <Outlet />;
    }

    // may be show some message later if job is not processing
    return <Navigate to="/v2" replace={true} />;
};
