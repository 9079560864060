import React from 'react';
import parse, {
    HTMLReactParserOptions,
    Element,
    domToReact,
} from 'html-react-parser';

export const parseHtmlString = (
    content: string,
    options?: {
        removeStrongTag?: boolean;
    }
) => {
    if (typeof content == 'string') {
        let parserOptions: HTMLReactParserOptions = null;

        if (options?.removeStrongTag)
            parserOptions = {
                replace({type, name, children}: Element) {
                    if (type === 'tag' && name === 'strong') {
                        return <>{domToReact(children, parserOptions)}</>;
                    }

                    return;
                },
            };

        return parse(content, parserOptions);
    }

    return content || '';
};
