import React, {useCallback} from 'react';
import styled from 'styled-components';

interface DotsProps {
    total: number;
    active: number;
    setContentId: (contentId: number) => void;
}

export const Dots = ({total, active, setContentId}: DotsProps) => {
    const handleClick = useCallback(
        (id: number) => () => {
            setContentId(id);
        },
        []
    );

    return (
        <DotContainer>
            {Array.from({length: total}, (_, index) => (
                <Dot
                    key={index}
                    $active={index === active}
                    onClick={handleClick(index)}
                />
            ))}
        </DotContainer>
    );
};

const DotContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

const Dot = styled.div<{$active: boolean}>`
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    opacity: ${({$active}) => ($active ? 1 : 0.7)};
`;
