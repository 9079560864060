import React, {useMemo} from 'react';
import {useJobContext} from 'contexts';
import {Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Button} from 'react-bootstrap';
import {CartContent} from 'shared';
import useUI from 'shared/useUI';
import Sidebar, {useRightSidebar} from 'shared/components/Sidebar';
import {SecondaryButton} from 'shared/components/SecondaryButton';
import {useTheme} from 'styled-components';
import {PartialJob} from 'shared/types/PartialJob';

export const SidebarRight = () => {
    const {
        rightSidebar,
        rightSidebarBehaviour,
        rightSidebarSoftOpen,
        alternateCartContent,
        showMainCartContent,
        showSidebarOverlays,
        isSmallScreenLayout,
    } = useUI();
    const {sidebarCartButtonHandler, onMouseEnter, onMouseLeave} =
        useRightSidebar();
    const {job, room} = useJobContext() as PartialJob;
    const isSmallDevice = isDeviceSmall();
    const theme = useTheme();

    const jobDashboardLink = useMemo(() => {
        if (job) {
            if (room && room.id) {
                return `/v2/job/${job.displayId}/room/${room.id}/dashboard`;
            } else {
                return `/v2/job/${job.displayId}/dashboard`;
            }
        }

        return '';
    }, [job, room]);

    const cartHeader = useMemo(() => {
        return (
            <>
                {showMainCartContent && alternateCartContent ? (
                    <>
                        <Icon
                            iconName="Layout.svg"
                            className="pointer"
                            onClick={sidebarCartButtonHandler}
                        />
                        <label>Layout Options</label>
                    </>
                ) : (
                    <>
                        <Icon
                            iconName="Cart.svg"
                            className="pointer"
                            onClick={sidebarCartButtonHandler}
                            color={theme.colors.cartIconColor.main}
                        />
                        <label>Cart</label>
                    </>
                )}
                <div
                    className="center-button"
                    style={{display: 'flex', justifyContent: 'center'}}>
                    <SecondaryButton to={jobDashboardLink} className="p-2">
                        Detailed cart view
                    </SecondaryButton>
                </div>
                <div className="text-right">
                    <Button
                        onClick={(e) => sidebarCartButtonHandler(e, true)} // eslint-disable-line react/jsx-no-bind
                        variant="link"
                        id="collapse-cart">
                        Collapse Cart
                    </Button>
                </div>
            </>
        );
    }, [
        job,
        rightSidebar,
        showMainCartContent,
        alternateCartContent,
        jobDashboardLink,
    ]);

    if (rightSidebar && !isSmallDevice) {
        return (
            <Sidebar
                sidebarId="sidebar-right"
                className="sidebar-right"
                anchor="RIGHT"
                behaviour={rightSidebarBehaviour}
                status={rightSidebar}
                header={cartHeader}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                showOverlays={showSidebarOverlays}
                softOpen={rightSidebarSoftOpen ? !isSmallScreenLayout : null}>
                {!showMainCartContent && alternateCartContent ? (
                    alternateCartContent
                ) : (
                    <CartContent />
                )}
            </Sidebar>
        );
    } else {
        return null;
    }
};
