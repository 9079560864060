import React from 'react';
import type {OptionsType} from 'shared/types';
import TableBody from './TableBody';
import TableHead from './TableHead';

const TableWithData = ({
    options,
    ...props
}: {
    options: OptionsType;
}): JSX.Element => {
    return (
        <table
            {...props}
            className="table cbc-table"
            cellSpacing={
                options.hasOwnProperty('cellSpacing') ? options.cellSpacing : 0
            }>
            <TableHead fields={options.fields} options={options.options} />
            <TableBody
                fields={options.fields}
                data={options.data}
                options={options}
            />
        </table>
    );
};

export default TableWithData;
