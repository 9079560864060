/**
 *
 *
 * @export
 * @class Shape
 */
export default class Shape {
    widthOffset: number;
    heightOffset: number;
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    options: Object;
    width: number;
    height: number;
    edgeDistance: number;
    edgeColor: string;
    edgeWidth: number;
    opacity: number = 1;

    /**
     *
     *
     * @param {HTMLElement} canvas
     * @param {Object} options
     * @param {number} width
     * @param {number} height
     * @memberof Shape
     */
    setOptions(
        canvas: HTMLCanvasElement,
        options: Object,
        width: number,
        height: number
    ) {
        this.widthOffset = 40;
        this.heightOffset = 30;
        this.canvas = canvas;
        this.context = this.canvas.getContext('2d');
        this.options = options;
        this.width = width;
        this.height = height;
        this.edgeDistance = 0;
        this.edgeColor = '#1793CD';
        this.edgeWidth = 5;
    }

    /**
     *
     *
     * @param {number} width
     * @param {number} height
     * @return {*}  {[number, number]}
     * @memberof Door
     */
    dimension(width: number, height: number): [number, number] {
        const staticWidth: number =
            this.canvas.width - this.widthOffset * 2 - this.edgeWidth * 2;
        let rectWidth: number = width < staticWidth ? width : staticWidth;

        let ratio: number =
            width < rectWidth ? width / rectWidth : rectWidth / width;
        if (this.options.doorVoid) {
            const voidWidth = ratio * this.options.doorVoid;

            rectWidth = rectWidth - voidWidth;
            ratio = width < rectWidth ? width / rectWidth : rectWidth / width;
        }

        let rectHeight: number = Math.round(height * ratio);

        if (
            rectHeight + this.heightOffset * 3 + this.edgeWidth * 2 >
            this.canvas.height
        ) {
            rectHeight =
                this.canvas.height - this.heightOffset * 3 - this.edgeWidth * 2;
            ratio =
                rectHeight < height ? rectHeight / height : height / rectHeight;

            rectWidth = Math.round(width * ratio);

            if (this.options.doorVoid) {
                const voidWidth = ratio * this.options.doorVoid;

                rectWidth = rectWidth - voidWidth;
            }
        }

        return [rectWidth, rectHeight];
    }

    /**
     *
     *
     * @param {Array<number>} from
     * @param {Array<number>} to
     * @memberof Shape
     */
    line(from: Array<number>, to: Array<number>) {
        this.context.beginPath();
        this.context.moveTo(from[0], from[1]);
        this.context.lineTo(to[0], to[1]);
        this.context.lineWidth = 1;
        this.context.stroke();
    }

    /**
     *
     *
     * @param {Array<number>} rect
     * @param {Array<number>} text
     * @param {string} label
     * @param {number} width
     * @param {number} height
     * @param {number} [fontSize=12]
     * @param {string} [labelTextColor='#000']
     * @memberof Shape
     */
    label(
        rect: Array<number> | null,
        text: Array<number>,
        label: string,
        width: number,
        height: number,
        fontSize: number = 12,
        labelTextColor: string = '#000'
    ) {
        if (rect) {
            this.context.beginPath();
            this.context.fillStyle = '#fff';
            this.context.fillRect(rect[0], rect[1], width, height);
            this.context.stroke();
        }

        void document.fonts.ready.then(() => {
            // get font family declared from body element
            const fontFamily = window.getComputedStyle(
                document.body
            ).fontFamily;
            const font = `${fontSize}px ${fontFamily}`;
            this.context.font = document.fonts.check(font)
                ? font
                : `${fontSize}px Poppins`; // fallback in case of failure
            this.context.fillStyle = labelTextColor;
            this.context.fillText(label, text[0], text[1]);
        });
    }

    /**
     *
     *
     * @param {boolean} isHorizontal
     * @param {Image} image
     * @param {number} [angle=90]
     * @return {*}  {CanvasPattern}
     * @memberof Shape
     */
    pattern(
        isHorizontal: boolean,
        image: Image | boolean,
        angle: number = 90
    ): CanvasPattern {
        if (image instanceof Image) {
            const pattern: CanvasPattern = this.context.createPattern(
                image,
                'repeat'
            );
            const svg: Element | null =
                document.querySelector('#canvas-helper');
            let matrix: SVGMatrix;

            if (svg) {
                // $FlowFixMe : Could not determine the type of svg. FIX LATER.
                matrix = svg.createSVGMatrix();
                if (isHorizontal) {
                    pattern.setTransform(matrix.rotate(angle));
                }
            }

            return pattern;
        }

        // if no image is supplied create a white repeating pattern
        const patternCanvas: HTMLElement | null =
            document.createElement('canvas');
        if (patternCanvas instanceof HTMLCanvasElement) {
            const patternContext: CanvasRenderingContext2D =
                patternCanvas.getContext('2d');

            // Give the pattern a width and height of 50
            patternCanvas.width = 2;
            patternCanvas.height = 2;

            // Give the pattern a background color
            patternContext.beginPath();
            patternContext.fillStyle = '#f2f1f0';
            patternContext.fillRect(
                0,
                0,
                patternCanvas.width,
                patternCanvas.height
            );

            // Create our primary canvas and fill it with the pattern
            const canvas: HTMLElement | null = document.createElement('canvas');
            if (canvas instanceof HTMLCanvasElement) {
                const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
                return ctx.createPattern(patternCanvas, 'repeat');
            } else throw new Error('Could not generate default pattern');
        } else throw new Error('Could not create Canvas');
    }

    /**
     *
     *
     * @param {string} imageUrl
     * @return {*}  {(Promise<Image | boolean>)}
     * @memberof Shape
     */
    image(imageUrl: string): Promise<Image | boolean> {
        return new Promise((resolve, reject) => {
            if (typeof imageUrl === 'undefined') {
                resolve(false);
                return;
            }

            const img: Image = new Image();
            img.src = imageUrl;

            img.onload = () => {
                resolve(img);
            };

            img.onerror = (e) => {
                resolve(false); // return false to load fallback canvas generated pattern
            };
        });
    }

    /**
     *
     *
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {string} [darkColor='#fff']
     * @param {string} [lightColor='#000']
     * @return {*}  {string}
     * @memberof Shape
     */
    color(
        x: number,
        y: number,
        width: number,
        height: number,
        darkColor: string = '#fff',
        lightColor: string = '#000'
    ): string {
        const blockSize = 5; // only visit every 5 pixels
        let imageData;
        let r = 0;
        let g = 0;
        let b = 0;
        let count = 0;
        let i = -4;

        try {
            imageData = this.context.getImageData(x, y, width, height);
        } catch (e) {
            return lightColor;
        }

        while ((i += blockSize * 4) < imageData.data.length) {
            ++count;
            r += imageData.data[i];
            g += imageData.data[i + 1];
            b += imageData.data[i + 2];
        }

        r = ~~(r / count);
        g = ~~(g / count);
        b = ~~(b / count);

        const luminescence: number = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        if (luminescence < 90)
            // color is too dark
            return darkColor;

        return lightColor;
    }

    /**
     *
     *
     * @param {number} [opacity]
     * @memberof Shape
     */
    async draw(opacity?: number) {}
}
