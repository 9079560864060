import React, {useState, useEffect} from 'react';
import {Form, Button} from 'react-bootstrap';
import {formatPrice, genericMessageHandler, Icon} from './helpers';
import {addCouponToJob, deleteCoupon} from '../service';
import {useNotificationContext, useJobContext} from 'contexts';
import {isEmpty, trim} from 'lodash';
import styled from 'styled-components';
import {
    invalidateJob,
    invalidateJobCost,
    useGetJobCostQuery,
} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';
import {DateTime} from '@cabinetsbycomputer/datetime';

const ExpiredCouponText = styled.div`
    color: red;
    font-size: 11px;
`;

export const Coupon = (): JSX.Element => {
    const [couponCode, setCouponCode] = useState('');
    const [couponField, setCouponField] = useState('');
    const [loader, setLoader] = useState(false);
    const {notify} = useNotificationContext();
    const {job, showDialog} = useJobContext();
    const [expiredCoupon, setExpiredCoupon] = useState(false);
    const dispatch = useAppDispatch();
    const {data: cost} = useGetJobCostQuery({jobId: job.id}, {
        skip: typeof job?.id === 'undefined',
    });

    const deletePrompt = () => {
        showDialog({
            title: 'Delete Coupon',
            message: 'Are you sure you want to delete this coupon?',
            yes: deleteCouponFromJob,
        });
    };

    const deleteCouponFromJob = async () => {
        setLoader(true);
        try {
            await deleteCoupon(job.id);
            setCouponCode('');
            setCouponField('');
            setExpiredCoupon(false);
            genericMessageHandler(
                notify,
                {message: 'Coupon deleted successfully!'},
                'success'
            );
            setLoader(false);
            dispatch(invalidateJob());
            dispatch(invalidateJobCost());
        } catch (error) {
            setLoader(false);
            setCouponCode('');
            setCouponField('');
            genericMessageHandler(notify, error);
        }
    };

    const addCoupon = async () => {
        if (isEmpty(trim(couponField))) {
            genericMessageHandler(notify, {
                message: `Coupon code can't be empty`,
            });
            return;
        }
        setLoader(true);
        try {
            await addCouponToJob(job.id, couponField);
            genericMessageHandler(
                notify,
                {message: 'Coupon added successfully!'},
                'success'
            );
            setLoader(false);
            dispatch(invalidateJob());
            dispatch(invalidateJobCost());
        } catch (error) {
            setLoader(false);
            setCouponCode('');
            setCouponField('');
            genericMessageHandler(notify, error);
        }
    };

    useEffect(() => {
        if (cost && cost.couponDetails && !cost.couponDetails.invalid) {
            setCouponCode(cost.couponDetails.coupon_code);
            // check if coupon expired

            if (
                !isEmpty(cost.couponDetails.expiry_date) &&
                DateTime.parse(cost.couponDetails.expiry_date.date).isBefore(
                    new Date()
                )
            ) {
                setExpiredCoupon(true);
            }
        }

        return () => {
            setCouponCode('');
            setCouponField('');
        };
    }, [job, cost]);

    return (
        <Form.Group
            controlId="coupon_"
            style={{display: 'flex', width: '100%', marginBottom: 0}}>
            <div style={{flex: 1, margin: '0px'}}>
                <Form.Control
                    type="text"
                    name="coupon"
                    value={
                        couponCode && cost
                            ? `${couponCode} ${'    '}-${formatPrice(
                                  cost.coupon,
                                  cost
                              )}`
                            : couponField
                    }
                    placeholder="Coupon Code"
                    disabled={
                        (couponCode && couponCode.length) || job.status == 5
                            ? true
                            : false
                    }
                    style={{fontSize: '10px'}}
                    onChange={(e) => setCouponField(e.target.value)}
                />
                {expiredCoupon ? (
                    <ExpiredCouponText>Coupon expired</ExpiredCouponText>
                ) : (
                    <></>
                )}

                {job.couponDetails && job.couponDetails.invalid ? (
                    <ExpiredCouponText>Invalid Coupon.</ExpiredCouponText>
                ) : (
                    <></>
                )}
            </div>
            <div>
                {couponCode && job.status != 5 ? (
                    <Button
                        variant="link"
                        className="mobile-lone-button"
                        style={{
                            margin: '0 2px',
                            padding: 0,
                        }}
                        onClick={deletePrompt}>
                        {loader ? (
                            <Icon
                                iconName="tail-spin.svg"
                                style={{width: '20px', height: '20px'}}
                            />
                        ) : (
                            <Icon
                                iconName="Options-Delete.svg"
                                style={{width: '20px', height: '20px'}}
                            />
                        )}
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        onClick={addCoupon}
                        className="button-blue"
                        style={{
                            borderRadius: '5px',
                            margin: '0 0 0 26px',
                            fontSize: '11px',
                            display: isEmpty(trim(couponField))
                                ? 'none'
                                : 'block',
                        }}>
                        {loader ? (
                            <Icon
                                iconName="tail-spin.svg"
                                style={{width: '20px', height: '20px'}}
                            />
                        ) : (
                            <span>Apply</span>
                        )}
                    </Button>
                )}
            </div>
        </Form.Group>
    );
};
