import {useState} from 'react';

// TODO: React's useTransition is a liar. It does not handle
// async operations, rather is used for synchronous state
// updates.

type Callable = () => Promise<void>;

export const useTransition = (): [boolean, (callable: Callable) => void] => {
    const [isPending, setIsPending] = useState(false);

    const startTransition = (callable: Callable) => {
        setIsPending(true);

        callable()
            .catch(() => {
                // handle error if need be
                // console.log(e);
            })
            .finally(() => setIsPending(false));
    };

    return [isPending, startTransition];
};
