import React, {useMemo} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

import type {FieldType, TableOptionType} from 'shared/types';

type THeadType = {
    fields: FieldType[];
    options: TableOptionType;
};

const TableHead = ({fields, options}: THeadType): JSX.Element => {
    const isSmallDevice = isDeviceSmall();
    const props = useMemo(() => {
        const props: {width?: number} = {};

        if (options && typeof options.width !== 'undefined') {
            props.width = options.width;
        }

        return props;
    }, [options]);

    return (
        <thead>
            <tr>
                {fields
                    .filter((field) => {
                        const hasColSpan =
                            field.hasOwnProperty('colSpan') &&
                            field.colSpan > 1;
                        const isNotMobile = !(
                            isSmallDevice &&
                            typeof field.mobile !== 'undefined' &&
                            !field.mobile
                        );

                        return !hasColSpan && isNotMobile;
                    })
                    .map((field, index) => {
                        const props: {width?: number} = {};
                        if (typeof field.width !== 'undefined') {
                            props.width = field.width;
                        }

                        return (
                            <th {...props} key={index}>
                                {field.title}
                            </th>
                        );
                    })}
                {typeof options === 'undefined' ||
                (options && options.isHidden) ? null : (
                    <th {...props}>{options.title}</th>
                )}
            </tr>
        </thead>
    );
};

export default TableHead;
