// @flow
import {Axios} from 'service';
import type {Handler} from './Handler';

/**
 * @export
 * @class HTTPHandler
 */
export default class HTTPHandler<T> implements Handler<T> {
    resetCache: boolean = false;
    handler: Handler<T>;
    url: string;
    resultName: string = 'data';

    objectProcessor: (object: Object) => T;

    /**
     * Creates an instance of IDBHandler.
     * @param {string} url The URL
     * @param {ObjectProcessor} objectProcessor
     * @param {string} resultName
     * @memberof IDBHandler
     */
    constructor(
        url: string,
        objectProcessor: (object: Object) => T,
        resultName: string
    ) {
        this.url = url;
        this.objectProcessor = objectProcessor;

        if (resultName) {
            this.resultName = resultName;
        }
    }

    /**
     *
     *
     * @param {Handler<T>} handler
     * @return {*}  {Handler<T>}
     * @memberof HTTPHandler
     */
    addHandler(handler: Handler<T>): Handler<T> {
        this.handler = handler;

        return handler;
    }

    /**
     *
     *
     * @param {boolean} resetCache
     * @memberof CacheHandler
     */
    setResetCache(resetCache: boolean) {}

    /**
     *
     *
     * @template T
     * @param {T} object
     * @return {*}  {Promise<Array<T>>}
     * @memberof HTTPHandler
     */
    async getData(): Promise<T[]> {
        const response = await Axios.instance().get(this.url);

        if (response && response.data) {
            if (response.data.success && parseInt(response.data.success) == 0) {
                if (response.data.error) {
                    throw new Error(response.data.error);
                } else {
                    throw new Error('Error occured please try again later');
                }
            } else if (
                response.data.success &&
                parseInt(response.data.success) == 1 &&
                response.data.hasOwnProperty(this.resultName)
            ) {
                return response.data[this.resultName].map((element) => {
                    return this.objectProcessor(element);
                });
            }
        }

        if (this.handler) {
            return await this.handler.getData();
        }

        return [];
    }

    /**
     *
     *
     * @param {T[]} data
     * @memberof HTTPHandler
     */
    setData(data: T[]) {}
}
