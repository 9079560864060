import {useEffect} from 'react';

export const useNumberInputScrollDisabler = (
    scrollableClassName = 'scrollable'
) => {
    useEffect(() => {
        document.addEventListener('wheel', function () {
            const activeElement = document.activeElement as HTMLInputElement;

            if (
                activeElement.type === 'number' &&
                !activeElement.classList.contains(scrollableClassName)
            ) {
                activeElement.blur();
            }
        });
    }, []);
};
