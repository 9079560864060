import {Link} from 'shared/reload/Link';
import styled from 'styled-components';

interface SecondaryButtonProps {
    $fullWidth?: boolean;
}

export const SecondaryButton = styled(Link)<SecondaryButtonProps>`
    width: ${(props) => (props.$fullWidth ? '100%' : 'fit-content')};
    padding-left: 0.4em;
    border-radius: 30px;
    box-sizing: border-box;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    line-height: 1.5;
    background: rgba(var(--secondary_colour), 1);
    color: rgba(var(--button_text_colour), 1);
    cursor: pointer;
    font-size: 12px;
    display: flex;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        color: rgba(var(--button_text_colour), 1);
        background: rgba(var(--primary_colour), 1);
        text-decoration: none;
    }

    > img {
        width: 25px;
    }

    > span {
        flex: 1;
        font-size: 1.4em;
    }
`;
