import React, {useState, useMemo} from 'react';
import {ErrorHandler} from 'shared/components/ErrorHandler';
import {useNotificationContext} from 'contexts';
import {flatten} from 'lodash';

export const Messages = () => {
    const {messages} = useNotificationContext();
    const [show, setShow] = useState(true);
    const {errors} = useMemo(() => {
        let errorMessages = messages.errors;

        if (!Array.isArray(errorMessages)) {
            errorMessages = flatten(
                Object.values(errorMessages).map((errors) => errors)
            );

            if (errorMessages.length > 10) {
                errorMessages.length = 10;
            }
        }
        setShow(true);
        return {
            errors: errorMessages
                .filter((message) => typeof message.onlyField === 'undefined')
                .map((message) => message.message),
        };
    }, [messages]);

    return (
        <ErrorHandler
            show={show}
            className="cbc-top-floating-error"
            errors={{}}
            externalErrors={errors} // errors that are not from formik
            touched={false}
            onClose={() => setShow(false)}
        />
    );
};
