import React, {useCallback, ComponentType} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {GenericItem} from 'shared/components/DragAndDrop/withDropTarget';

export interface DragProps {
    isDragging?: boolean;
    dragRef?: (node: HTMLDivElement) => void;
}

interface DragTargetProps {
    draggable: GenericItem;
    findItem: (id: number) => {item: GenericItem; index: number};
    moveItem: (id: number, atIndex: number) => void;
}

export const withDragTarget = <T extends GenericItem, R>(
    Component: ComponentType<DragProps>
) => {
    const DropTarget = ({
        draggable: item,
        findItem,
        moveItem,
        ...props
    }: R & DragTargetProps) => {
        const originalIndex = findItem(item.id).index;

        const [{isDragging}, drag] = useDrag(
            () => ({
                type: 'ITEM',
                item: {id: item.id, originalIndex},
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(),
                }),
                end: (item, monitor) => {
                    const {id: droppedId, originalIndex} = item;
                    const didDrop = monitor.didDrop();

                    if (!didDrop) {
                        moveItem(droppedId, originalIndex);
                    }
                },
            }),
            [item, originalIndex, moveItem]
        );

        const [, drop] = useDrop(
            () => ({
                accept: 'ITEM',
                hover({id: draggedId}: T) {
                    if (draggedId !== item.id) {
                        const {index: overIndex} = findItem(item.id);
                        moveItem(draggedId, overIndex);
                    }
                },
            }),
            [findItem, moveItem]
        );

        const dragRef = useCallback(
            (node: HTMLDivElement) => {
                return drag(drop(node));
            },
            [drag, drop]
        );

        return (
            <Component {...props} isDragging={isDragging} dragRef={dragRef} />
        );
    };

    return DropTarget;
};
