import {HardwareOption} from 'components/customer/Product/entity';
import React from 'react';
import {Col, Form, Row, Image} from 'react-bootstrap';
import {MoreInfo} from 'shared';
import {
    isDeviceSmall,
    isDeviceMid,
    isDeviceLarge,
} from 'shared/helpers/DeviceSize';
import {Loader} from 'shared/helpers';
import styled from 'styled-components';

export const DynamicRadioDefault = ({
    name,
    value,
    disabled = false,
    isQFP = false,
    isLoading = false,
    yesOption,
    noOption,
    getClassName,
    handleYesCheck,
    handleNoCheck,
    handleChange,
    opacity,
}: {
    name: string;
    value: boolean;
    disabled: boolean;
    isQFP: boolean;
    isLoading: boolean;
    yesOption: HardwareOption;
    noOption: HardwareOption;
    getClassName: (type: string) => string;
    handleYesCheck: () => void;
    handleNoCheck: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    opacity: React.CSSProperties;
}) => {
    const isSmallDevice = isDeviceSmall();
    const isLargeDevice = isDeviceLarge();
    const deviceIsMedium = isDeviceMid(false);

    return (
        <>
            <Loader loader={isLoading}></Loader>
            <Row
                style={
                    isSmallDevice || deviceIsMedium
                        ? {padding: '10px 10px 10px 5px'}
                        : undefined
                }>
                {!yesOption.hasOwnProperty('hidden') || !yesOption.hidden ? (
                    <Col
                        md={
                            isLargeDevice || isSmallDevice
                                ? isQFP
                                    ? 12
                                    : 6
                                : 12
                        }
                        sm={12}
                        xs={6}>
                        <div
                            className={getClassName('yes')}
                            onClick={handleYesCheck}
                            style={!value ? opacity : null}>
                            <RadioContainer
                                $mediumDevice={deviceIsMedium}
                                $largeDevice={isLargeDevice}
                                $isQFP={isQFP}>
                                <div style={{flex: 1}}>
                                    <Form.Check
                                        type="radio"
                                        id={`boolean_${name}_yes`}
                                        name={name}
                                        disabled={isLoading || disabled}
                                        value="1"
                                        checked={value}
                                        label={
                                            <RadioSection>
                                                {yesOption.image ? (
                                                    <RadioImageSection
                                                        $isMobile={
                                                            isSmallDevice
                                                        }>
                                                        {React.isValidElement(
                                                            yesOption.image
                                                        ) ? (
                                                            yesOption.image
                                                        ) : (
                                                            <Image
                                                                src={`/${yesOption.image}`}
                                                                thumbnail
                                                            />
                                                        )}
                                                    </RadioImageSection>
                                                ) : null}
                                                <div style={{flexGrow: 1}}>
                                                    {yesOption.name
                                                        ? yesOption.name
                                                        : 'Yes'}
                                                </div>
                                                <div
                                                    style={{
                                                        minWidth:
                                                            !noOption.description &&
                                                            isSmallDevice
                                                                ? 0
                                                                : 25,
                                                    }}>
                                                    {yesOption.description ? (
                                                        <span
                                                            id={`${name}_yes_option`}
                                                            className="dynamic-radio-more-info">
                                                            <MoreInfo
                                                                dynamicposition
                                                                info={
                                                                    yesOption.description
                                                                }
                                                            />
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </RadioSection>
                                        }
                                        onChange={handleChange}
                                        className={`dynamic-radio-checkbox ${
                                            isSmallDevice ? 'small' : ''
                                        }`}
                                    />
                                </div>
                            </RadioContainer>
                        </div>
                    </Col>
                ) : null}
                {!noOption.hasOwnProperty('hidden') || !noOption.hidden ? (
                    <Col
                        md={
                            isLargeDevice || isSmallDevice
                                ? isQFP
                                    ? 12
                                    : 6
                                : 12
                        }
                        sm={12}
                        xs={6}>
                        <div
                            className={getClassName('no')}
                            onClick={handleNoCheck}
                            style={value ? opacity : null}>
                            <RadioContainer
                                $mediumDevice={deviceIsMedium}
                                $isQFP={isQFP}
                                $largeDevice={isLargeDevice}>
                                <Form.Check
                                    type="radio"
                                    id={`boolean_${name}_no`}
                                    name={name}
                                    value="0"
                                    disabled={isLoading || disabled}
                                    checked={!value}
                                    label={
                                        <RadioSection>
                                            {noOption.image ? (
                                                <RadioImageSection
                                                    $isMobile={isSmallDevice}>
                                                    {React.isValidElement(
                                                        noOption.image
                                                    ) ? (
                                                        noOption.image
                                                    ) : (
                                                        <Image
                                                            src={`/${noOption.image}`}
                                                            thumbnail
                                                        />
                                                    )}
                                                </RadioImageSection>
                                            ) : null}
                                            <div style={{flexGrow: 1}}>
                                                {noOption.name
                                                    ? noOption.name
                                                    : 'No'}
                                            </div>
                                            <div
                                                style={{
                                                    minWidth:
                                                        !noOption.description &&
                                                        isSmallDevice
                                                            ? 0
                                                            : 25,
                                                }}>
                                                {noOption.description ? (
                                                    <span
                                                        id={`${name}_no_option`}
                                                        className="dynamic-radio-more-info">
                                                        <MoreInfo
                                                            dynamicposition
                                                            info={
                                                                noOption.description
                                                            }
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </RadioSection>
                                    }
                                    onChange={handleChange}
                                    className={`dynamic-radio-checkbox ${
                                        isSmallDevice ? 'small' : ''
                                    }`}
                                />
                            </RadioContainer>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </>
    );
};

export const RadioContainer = styled.div<{
    $mediumDevice: boolean;
    $isQFP: boolean;
    $largeDevice: boolean;
}>`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 15px;
    position: relative;
    max-width: ${({$mediumDevice, $isQFP}) =>
        $mediumDevice || $isQFP ? '310px' : '280px'};
    margin-top: ${({$largeDevice, $isQFP}) =>
        $largeDevice ? ($isQFP ? '8px' : '4px') : '8px'};
`;

export const RadioSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -8px;
    margin-left: 5px;
`;

export const RadioImageSection = styled.section<{
    $isMobile?: boolean;
}>`
    cursor: pointer;
    width: 40px;
    margin-right: 3px;
    img {
        min-width: ${({$isMobile}) => ($isMobile ? '40px' : '32px')};
    }
`;
