import React from 'react';
import styled from 'styled-components';

export const Sticky: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = ({
    children,
}) => {
    return <StickyContainer>{children}</StickyContainer>;
};

const StickyContainer = styled.div`
    position: sticky;
    top: 10px;
`;
