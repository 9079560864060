import React, {useEffect} from 'react';
import {useAppContext} from 'contexts';
import {Loader} from 'shared/helpers';
import {useConfirmationDialog} from 'shared';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectLogoutPopup,
    selectMessage,
    Session,
    SESSION_CHANGE_MESSAGE,
    sessionSet,
} from 'components/customer/Auth/store/authSlice';

export const UserCheck = ({children}) => {
    const dispatch = useAppDispatch();
    const {userLevel} = useAppContext();
    const showLoggedoutDialog = useAppSelector(selectLogoutPopup);
    const {dialog, showDialog} = useConfirmationDialog();
    const errorMessage = useAppSelector(selectMessage);

    useEffect(() => {
        if (userLevel === 'USER_NONE' || showLoggedoutDialog) {
            showDialog({
                title: 'Session timed out',
                message:
                    errorMessage == SESSION_CHANGE_MESSAGE
                        ? SESSION_CHANGE_MESSAGE
                        : 'Your session has timed out. Please login again!',
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Go to Login page',
                        show: true,
                        action: () => {
                            dispatch(sessionSet(Session.LOGGED_OUT));
                        },
                    },
                ],
            });
        }
    }, [userLevel, showLoggedoutDialog]);

    if (userLevel === '') {
        return <Loader loader={true} />;
    } else {
        return (
            <>
                {dialog}
                {children}
            </>
        );
    }
};
