import {createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store/dashboard';
import {remove} from 'lodash';
export enum MessageVariant {
    ERROR,
    SUCCESS,
    WARN,
    INFO,
}

export interface Message {
    id: string;
    message: string;
    variant: MessageVariant;
    type: string;
}

const initialState: {messages: Message[]} = {
    messages: [],
};

const NotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: {
            reducer: (state, action: PayloadAction<Message>) => {
                state.messages.push(action.payload);
            },
            prepare: (
                message: string,
                variant: number,
                type: string | undefined = ''
            ) => {
                return {
                    payload: {
                        id: nanoid(),
                        message,
                        variant,
                        type,
                    },
                };
            },
        },
        deleteNotification: (state, action: PayloadAction<string>) => {
            remove(state.messages, function (message) {
                return message.id == action.payload;
            });
        },
        deleteAllNotificationsOfType: (
            state,
            action: PayloadAction<string>
        ) => {
            remove(state.messages, function (message) {
                return message.type == action.payload;
            });
        },
    },
});

export const {
    addNotification,
    deleteNotification,
    deleteAllNotificationsOfType,
} = NotificationSlice.actions;

export const selectMessages = (state: RootState) => state.notification.messages;

export default NotificationSlice.reducer;
