import ReactGA from 'react-ga4';
import {UaEventOptions} from 'react-ga4/types/ga4';

export const sendAnalyticsEvent = (
    action: string,
    category = 'general',
    options: Omit<UaEventOptions, 'action' | 'category'> = {}
) =>
    ReactGA.event({
        ...options,
        action: action,
        category: category,
    });
