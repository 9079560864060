import { boolean } from 'mathjs';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Switch = styled.label`
    text-align: left;
    display: inline-block;
    width: 40px; /*=w*/
    height: 20px; /*=h*/
    margin: 4px;
    position: relative;

    input {
        display: none;
    }
`;

const SliderSpan = styled.span`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px white, 0 0 4px white;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.2s;
    background: #d92c28;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 30px;
        transform: translateX(-19px);
        transition: 0.2s;
    }

    input:checked + & {
        box-shadow: 0 0 0 2px white, 0 0 8px white;
        background: #7dba00;
    }

    input:checked + &:before {
        transform: translateX(19px); /*translateX(w-h)*/
        background: white;
    }
`;

const SliderDiv = styled.div`
    text-align: center;
    justify-content: flex-end;
    align-items: center;
`;

interface SliderInterface {
    name?: string;
    value?: boolean;
    isLoading?: boolean;
    onChange?: (name: string, value: boolean) => void;
}

const Slider = ({name, value, isLoading, onChange}: SliderInterface) => {
    const [toggled, setToggled] = useState(value);

    const handleChange = useCallback(() => {
        if (typeof onChange === 'function') {
            onChange(name, !toggled);
        }

        setToggled(!toggled);
    }, [toggled, onChange]);

    useEffect(() => {
        setToggled(value);
    }, [value]);
    return (
        <SliderDiv>
            <Switch>
                <input
                    type="checkbox"
                    checked={toggled}
                    onChange={handleChange}
                    disabled={isLoading}
                />
                <SliderSpan />
            </Switch>{' '}
        </SliderDiv>
    );
};

export default Slider;
