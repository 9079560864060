const countryPhoneRegex: {
    [index: string]: RegExp;
} = {
    Australia:
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
    'New Zealand': /^(0+(3|4|6|7|9|800|27|50|21|22)|(\++64))+\d{6,8}$/,
};

const defaultPhoneRegex = /^[\+\-\s\(\)\d]*$/;

const getPhoneRegex = (country: string): RegExp => {
    /*
     * Our country-specific regexes are inadequate and we should be using libphonenumber or something instead.
     * Let's just use the simple default regular expression for now
    if (countryPhoneRegex.hasOwnProperty(country)) {
        return countryPhoneRegex[country];
    }
    */

    return defaultPhoneRegex;
};

export {getPhoneRegex};
