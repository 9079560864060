import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {CBCButton, useComponentMountedHelper, Loader} from 'shared/helpers';
import {useParams, useSearchParams} from 'react-router-dom';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useProductContext, useJobContext} from 'contexts';
import {RECENTLY_ADDED_KEYS} from 'hooks';
import {ProductBackgroundImage} from 'components';
import {isDeviceMedium} from 'shared/helpers/DeviceSize';
import {PartialRoom} from 'shared/types/PartialRoom';
import {ItemColumn} from 'shared/components/Product/ItemColumn';
import styled from 'styled-components';

export type ItemType = {
    favourites: number;
    id: number;
    sundryCategoryId: number;
    name: string;
    changed_name: string;
    changedImage: string;
    imageUrl: string;
    stockStatus: number;
    stockStatusText: string;
    advanced?: boolean;
    hidden: boolean;
};

type ItemProps = {
    item: ItemType;
    isProduct: boolean;
    setError?: (errors: string[]) => void;
    loader?: boolean;
    containerLength: number;
};

export const Item = ({
    item,
    isProduct,
    setError,
    containerLength,
}: ItemProps) => {
    const {jobId, roomId} = useParams();
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const subCategory = searchParams.get('subCategory');
    const favourites = searchParams.get('favourites');

    const mediumDevice = isDeviceMedium();

    const {
        AddToCart: addToCart,
        AddToFavourite: addToFavourite,
        addRecentItem,
    } = useProductContext<{
        AddToCart: (item: ItemType, count: number) => Promise<void>;
        AddToFavourite: (
            item: ItemType,
            favourite: boolean,
            isProduct: boolean
        ) => Promise<void>;
        addRecentItem: (id: number, key: string) => void;
    }>();
    const {room} = useJobContext() as {room: PartialRoom};
    const {isMounted} = useComponentMountedHelper();

    const [isFavourite, setIsFavourite] = useState(item.favourites > 0);
    const [count, setCount] = useState(1);
    const [favouriteButtonDisabled, setFavouriteButtonDisabled] =
        useState(false);
    const [cartButtonDisabled, setCartButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const increment = useCallback(() => {
        setCount((count) => count + 1);
    }, []);

    const decrement = useCallback(() => {
        setCount((count) => (count > 0 ? count - 1 : count));
    }, []);

    const addToFavouriteHandler = useCallback(
        async (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setLoading(true);

            setFavouriteButtonDisabled(true);
            await addToFavourite(item, !isFavourite, isProduct);

            setIsFavourite((isFavourite) => !isFavourite);
            setFavouriteButtonDisabled(false);
            setLoading(false);
        },
        [setFavouriteButtonDisabled, addToFavourite]
    );

    const addToCartHandler = useCallback(async () => {
        if (setError && count < 1) {
            setError(['Quantity must be greater than 0']);
            return;
        }
        setCartButtonDisabled(true);
        await addToCart(item, count);
        addRecentItem(item.id, RECENTLY_ADDED_KEYS.HARDWARE);
        isMounted.current && setCartButtonDisabled(false);
    }, [addToCart, addRecentItem, setError, count, item, isMounted]);

    const hardwareLinkFormatter = useCallback(() => {
        const params: {
            category?: string;
            subCategory?: string;
            favourites?: string;
            product?: string;
            sundry?: string;
        } = {};

        if (category) params.category = category;
        if (subCategory) params.subCategory = subCategory;
        if (favourites) params.favourites = '1';

        if (item.id && isProduct) params.product = String(item.id);
        else params.sundry = String(item.id);

        if (params.hasOwnProperty('sundry')) {
            // get category from sundry item
            params.category = String(item.sundryCategoryId);
        }

        const query = new URLSearchParams(params).toString();

        if (isProduct) {
            return `/v2/job/${jobId}/room/${roomId}/product?${query}`;
        }

        return `/v2/job/${jobId}/hardware?${query}`;
    }, [category, subCategory, favourites, item, isProduct, jobId, roomId]);

    const itemClickHandler = useCallback(() => {
        navigate(hardwareLinkFormatter());
    }, [navigate, hardwareLinkFormatter]);

    const countChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCount(parseInt(event.currentTarget.value));
        },
        []
    );

    useEffect(() => {
        if (setError && count > 0) {
            setError([]);
        }
    }, [count]);

    useEffect(() => {
        setCartButtonDisabled(false);
        if (item.hasOwnProperty('stockStatus') && item.stockStatus == 1) {
            setCartButtonDisabled(true);
        }
    }, [item]);

    const style = useMemo(() => {
        return isProduct
            ? {
                  backgroundImage: `url("/uploads/gocabinet_materials/${
                      !item.imageUrl.endsWith('.svg') &&
                      room &&
                      room.extMaterial &&
                      room.extMaterial.image != ''
                          ? room.extMaterial.image
                          : 'default_exterior.jpg'
                  }")`,
              }
            : {};
    }, [isProduct, room, item]);

    return (
        <Loader loader={loading}>
            {!item.hidden && (
                <ItemColumn $hardware={!isProduct} $container={containerLength}>
                    <div className="hardwareItem">
                        <div
                            onClick={itemClickHandler}
                            className={
                                (isProduct
                                    ? 'details'
                                    : 'details hardware-details') +
                                (mediumDevice ? ` no-padding` : '')
                            }>
                            <Button
                                onClick={addToFavouriteHandler}
                                disabled={favouriteButtonDisabled}
                                variant="btn btn-link"
                                className={
                                    isFavourite
                                        ? 'itemFavourite userFavourite'
                                        : 'itemFavourite'
                                }
                            />

                            <div style={{width: '100%', height: 'inherit'}}>
                                {item.hasOwnProperty('imageUrl') ? (
                                    <ProductBackgroundImage
                                        alt={item.name}
                                        image={style.backgroundImage || ''}
                                        transform={parseInt(room.extHorGrain)}
                                        src={item.changedImage}
                                        fallbackSrc={item.imageUrl}
                                        resize={true}
                                        isHardware={!isProduct}
                                    />
                                ) : null}
                            </div>

                            <Label className="item-name">
                                {item.changed_name
                                    ? item.changed_name
                                    : item.name}{' '}
                                {item.hasOwnProperty('stockStatus') &&
                                item.stockStatus == 1
                                    ? ` (${item.stockStatusText})`
                                    : ''}
                            </Label>
                        </div>
                        {isProduct ? null : (
                            <div className="cartButtons">
                                {item.hasOwnProperty('advanced') &&
                                item.advanced ? (
                                    <HardwareButton
                                        disabled={cartButtonDisabled}
                                        asLink={true}
                                        to={hardwareLinkFormatter()}
                                        className="button-blue btn-sm"
                                        iconName="Button-Add.svg">
                                        Click for More Options
                                    </HardwareButton>
                                ) : (
                                    <>
                                        <HardwareButton
                                            disabled={cartButtonDisabled}
                                            onClick={addToCartHandler}
                                            className="button-blue btn-sm"
                                            iconName="Button-Add.svg">
                                            Add to Cart
                                        </HardwareButton>
                                        <div className="numberSpinner">
                                            <label>Qty:</label>
                                            <Form.Control
                                                as="input"
                                                type="number"
                                                value={count}
                                                onChange={countChangeHandler}
                                            />
                                            <div className="spinner">
                                                <div onClick={increment}>
                                                    &and;
                                                </div>
                                                <div onClick={decrement}>
                                                    &or;
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </ItemColumn>
            )}
        </Loader>
    );
};

const Label = styled.div`
    font-size: 0.87em;
`;

const HardwareButton = styled((props) => <CBCButton {...props} />)`
    margin-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        line-height: 10px;
    }
`;
