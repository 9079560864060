import React from 'react';
import {useDrop} from 'react-dnd';

export const DropTarget = ({
    children,
    isTable = false,
}: {
    children: React.ReactNode;
    isTable?: boolean;
}) => {
    const [, drop] = useDrop(() => ({accept: 'ITEM'}));

    if (isTable) {
        return <tbody ref={drop}>{children}</tbody>;
    }

    return <div ref={drop}>{children}</div>;
};
