// @flow
import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router';
import {useNotificationContext} from 'contexts';
import {getAllMessages, deleteMessages} from 'service';
import {useConfirmationDialog} from 'shared';
import {usePromise, genericMessageHandler} from 'shared/helpers';
import {useAppDispatch} from 'store/customer';
import {updateMessageShownSet} from 'store/customer/appSlice';

type Message = {
    id: number,
    heading: string,
    text: string,
};

export const UserMessages = (): React$Element<any> => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const [messages, setMessages] = useState<Array<Message>>([]);

    const location = useLocation();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    const getMainPageLocation = (pageUrl) => {
        let urlArr: Array<string> = pageUrl.split('/');
        // remove empty values from array
        urlArr = urlArr.filter((x) => x);
        if (urlArr.length <= 1) {
            return urlArr[0] || 'v2'; // send v2 as main page
        }
        return urlArr[1];
    };

    const lastUrl = useRef();

    useEffect(() => {
        const newURL: string = getMainPageLocation(location.pathname);
        if (newURL != lastUrl.current) {
            lastUrl.current = newURL;
            return usePromise(
                ([userMessages]) => {
                    if (userMessages.length > 0) {
                        setMessages(userMessages);
                    } else {
                        dispatch(updateMessageShownSet(false));
                    }
                },
                [getAllMessages()],
                (error) => {
                    genericMessageHandler(notify, error);
                }
            );
        }
    }, [location]);

    const hideDialogHandler = () => {
        dispatch(updateMessageShownSet(false));
        hideDialog();
    };

    // on change of messages
    useEffect(() => {
        if (messages.length) {
            const firstMessage = messages[messages.length - 1];
            showDialog({
                title: firstMessage.heading,
                message: firstMessage.text,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => {
                            if (messages.length == 1) {
                                hideDialogHandler();
                            }
                            deleteMessages(firstMessage.id);
                            // delete from state
                            setMessages(
                                [...messages].filter(
                                    (message) => message.id !== firstMessage.id
                                )
                            );
                        },
                    },
                    {
                        name: 'Skip',
                        show: true,
                        variant: 'warning',
                        controlledHideDialog: true,
                        action: () => {
                            deleteMessages(firstMessage.id);
                            if (messages.length == 1) {
                                hideDialogHandler();
                            }
                            // delete from state
                            setMessages(
                                [...messages].filter(
                                    (message) => message.id !== firstMessage.id
                                )
                            );
                        },
                    },
                    {
                        name: 'Skip All',
                        show: true,
                        variant: 'danger',
                        action: () => {
                            deleteMessages();
                            // delete from state
                            setMessages([]);
                            hideDialogHandler();
                        },
                    },
                ],
            });
        }
    }, [messages]);

    return dialog;
};
