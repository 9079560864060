import React, {useCallback} from 'react';
import DropdownListDefault from 'shared/components/Forms/DropdownListDefault';
import DropdownListDoorStyle from 'shared/components/Forms/DropdownListDoorStyle';
import {DropdownOption} from 'shared/components/Forms/Dropdown';

export enum DropdownType {
    DoorStyle,
    Default,
}

type DropdownListItem = {
    children: string | JSX.Element;
    type: DropdownType;
    active: boolean;
    disabled: boolean;
    option: DropdownOption;
    onSelect: (option: DropdownOption) => void;
    name: string;
};

const DropdownListItem = ({
    children,
    type = DropdownType.Default,
    active,
    disabled,
    option,
    onSelect,
    name,
    ...props
}: DropdownListItem): JSX.Element => {
    const onSelectHandler = useCallback(() => {
        if (option && typeof onSelect === 'function') {
            onSelect(option);
        }
    }, [onSelect, option]);

    const optionName =
        option &&
        option.hasOwnProperty('name') &&
        typeof option.name === 'string'
            ? option.name.toLowerCase().replace(/\s/g, '_')
            : '';

    switch (type) {
        case DropdownType.DoorStyle:
            return (
                <DropdownListDoorStyle
                    disabled={disabled}
                    active={active}
                    data-cy={`${name}-dropdown-item-${optionName}`}
                    onSelect={onSelectHandler}
                    {...props}>
                    {children}
                </DropdownListDoorStyle>
            );

        case DropdownType.Default:
            return (
                <DropdownListDefault
                    disabled={disabled}
                    active={active}
                    onSelect={onSelectHandler}
                    data-cy={`${name}-dropdown-item-${optionName}`}
                    {...props}>
                    {children}
                </DropdownListDefault>
            );
    }
};

export default DropdownListItem;
