import {useEffect} from 'react';
import useUI from 'shared/useUI';
import {SidebarStatus} from 'store/customer/UISlice';

export const useSidebarStateToggle = () => {
    const {overrideLeftSidebar, overrideRightSidebar, setRightSidebarSoftOpen} =
        useUI();

    useEffect(() => {
        setRightSidebarSoftOpen(true);
        overrideLeftSidebar(SidebarStatus.CLOSED);
        overrideRightSidebar(SidebarStatus.CLOSED);

        return () => {
            setRightSidebarSoftOpen(false);
        };
    }, []);
};
