import {HardwareOption} from 'components/customer/Product/entity';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {usePromise} from 'shared/helpers';
import useDoorFacesHelper from 'components/customer/Materials/helper/useDoorFacesHelper';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

import {DynamicRadioDefault, DynamicRadioMobile} from 'shared';

export const DynamicRadio = ({
    name,
    value,
    setFieldValue,
    options,
    disabled = false,
    isQFP = false,
    showDisabledMessage,
    yesOptionHidden,
    noOptionHidden,
}: {
    name: string;
    value: boolean;
    setFieldValue: (n: string, v: boolean) => void;
    options: () => Promise<HardwareOption[]>;
    disabled: boolean;
    isQFP: boolean;
    showDisabledMessage?: () => void;
    yesOptionHidden?: boolean;
    noOptionHidden?: boolean;
}) => {
    const [finalOptions, setFinalOptions] = useState<HardwareOption[]>([]);
    const {handleDoorFaceRadioChange} = useDoorFacesHelper(setFieldValue);
    const isSmallDevice = isDeviceSmall();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastClicked, setLastClicked] = useState<string | null>(null);

    useEffect(
        () =>
            usePromise(
                ([data]: [HardwareOption[]]) => {
                    setFinalOptions(data);
                },
                [options()]
            ),
        [options]
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsLoading(true);
            const targetValue = event.target.value == '1';
            setFieldValue(name, targetValue);
            if (finalOptions.some((o) => o.doorFaces)) {
                handleDoorFaceRadioChange(targetValue)
                    .catch(() => {
                        // console.error('An error occurred:', e);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                return;
            } else {
                setIsLoading(false);
            }
        },
        [name, finalOptions, setFieldValue, handleDoorFaceRadioChange]
    );

    const handleCheck = useCallback(
        (name: string) => {
            const element = document.getElementById(name);
            if (element) {
                element.click();
            }
            if (disabled && !!showDisabledMessage) {
                showDisabledMessage();
            }
        },
        [showDisabledMessage, disabled]
    );

    const handleChecks = useCallback(
        (checkType: string) => {
            if (!isLoading) {
                setLastClicked(checkType);
                handleCheck(`boolean_${name}_${checkType}`);
            }
        },
        [handleCheck, name, isLoading]
    );

    const handleYesCheck = () => handleChecks('yes');
    const handleNoCheck = () => handleChecks('no');

    const getClassName = useCallback(
        (type: string) => {
            let classNames = `cbc-radio`;
            if (isLoading && lastClicked === type) {
                classNames += ' await-radio';
            }
            return classNames;
        },
        [isLoading, lastClicked]
    );

    const [yesOption, noOption] = useMemo(() => {
        const finalYesOption = finalOptions.find((v) => v.value == 1);
        const finalNoOption = finalOptions.find((v) => v.value == 0);
        if (yesOptionHidden && finalYesOption) {
            finalYesOption.hidden = true;
        }
        if (noOptionHidden && finalNoOption) {
            finalNoOption.hidden = true;
        }
        return [finalYesOption, finalNoOption];
    }, [finalOptions, yesOptionHidden, noOptionHidden]);

    const opacity: React.CSSProperties = {
        opacity: '0.5',
    };

    if (typeof yesOption == 'undefined' || typeof noOption == 'undefined') {
        return null;
    }

    return isSmallDevice || !isQFP ? (
        <DynamicRadioMobile
            {...{
                name,
                value,
                disabled,
                isLoading,
                yesOption,
                noOption,
                handleYesCheck,
                handleNoCheck,
                handleChange,
            }}
        />
    ) : (
        <DynamicRadioDefault
            {...{
                name,
                value,
                disabled,
                isQFP,
                isLoading,
                yesOption,
                noOption,
                getClassName,
                handleYesCheck,
                handleNoCheck,
                handleChange,
                opacity,
            }}
        />
    );
};

const hasDrawerText = (displayName: string | null) =>
    displayName?.toLowerCase().indexOf('drawer') > -1;
const hasDoorText = (displayName: string | null) =>
    displayName?.toLowerCase().indexOf('door') > -1;

export const getIncludeDoorFacesLabel = (
    displayName: string | null
): string => {
    const hasDrawer = hasDrawerText(displayName);
    const hasDoor = hasDoorText(displayName);

    if (hasDrawer && hasDoor) return 'Door and Drawer';

    if (hasDoor) return 'Door';

    return 'Drawer';
};

export const getIncludeDoorFacesImage = (
    displayName: string | null
): {yesOptionImage: string; noOptionImage: string} => {
    const hasDrawer = hasDrawerText(displayName);
    const hasDoor = hasDoorText(displayName);

    if (hasDrawer && !hasDoor)
        return {
            yesOptionImage: 'DrawerFaces.svg',
            noOptionImage: 'NoDrawerFaces.svg',
        };

    return {
        yesOptionImage: 'DoorFaces.svg',
        noOptionImage: 'NoDoorFaces.svg',
    };
};
