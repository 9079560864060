import {getCabinetTop, saveProduct} from 'service';
import {validateProducts} from 'shared/validator';
import {useGetDrawers} from 'components/customer/Product/Drawer/helpers/getDrawers';
import {getDrawerRunnerDetails} from 'components/customer/Product/helpers/getDrawerRunnerDetails';
import {getInitialValues} from 'components/customer/Product/helpers/getInitialValues';
import {getRequiredReferences} from 'components/customer/Product/helpers/getRequiredReferences';
import {mapDefaults} from 'components/customer/Product/helpers/mapDefaults';
import {mapInitialValues} from 'components/customer/Product/helpers/mapInitialValues';
import {mapProductData} from 'components/customer/Product/helpers/mapProductData';
import {getCabinetFields} from 'shared/helpers';
import Excel from 'shared/Excel';
import {Material} from 'components/customer/Materials/entity';
import {getMaterials} from 'components/customer/QFPRedux/helpers/materialHelpers';
import {Product} from 'components/customer/Product/entity';
import {PartialRoom} from 'shared/types/PartialRoom';
import {mapDoorBack} from 'components/customer/Materials/helper/doorHelper';
import {
    useLazyGetProductDataQuery,
    useLazyGetProductDefaultQuery,
    useLazyGetQFPProductStructureQuery,
} from 'components/customer/Product/store/productApi';
import {cloneDeep} from 'lodash';

const getMinMaxSizes = (color: Material) => {
    const validationData = {
        maxWidth: 9999,
        maxHeight: 9999,
        isGrained: 0,
        thickness: 16.5,
    };

    if (color) {
        validationData.maxWidth = color.width ? color.width : 9999;
        validationData.maxHeight = color.length ? color.length : 9999;
        validationData.isGrained = color.is_grained ? 1 : 0;
        validationData.thickness = color.thickness ? color.thickness : 16.5;
    }

    return validationData;
};

interface AddProductInterface {
    product: Partial<Product>;
    room: PartialRoom;
    width: number;
    height?: number;
    depth?: number;
    cabinetData?: {
        note?: string;
        shelves?: string;
        shelfType?: string;
        shelfPosition?: string;
        shelfOffset?: string;
        shelfStyle?: string;
        drawerDetails?: string;
        doorDetails?: string;
    };
}

export const useAddProductManually = () => {
    const getProductData = useLazyGetProductDataQuery();
    const getProductConfig = useLazyGetQFPProductStructureQuery();
    const getFormFieldDefaults = useLazyGetProductDefaultQuery();
    const getDrawers = useGetDrawers();

    return addProductManually(
        getProductData,
        getProductConfig,
        getFormFieldDefaults,
        getDrawers
    );
};

interface CabinetInfo {
    hasExterior: boolean;
    hasExteriorDoor: boolean;
    hasCarcase: boolean;
}

const checkMaterials = (
    materialInformation: CabinetInfo,
    productData: Product
) => {
    if (materialInformation.hasExterior) {
        if (
            !productData.available_options.is_exterior_material_available ||
            !productData.available_options.is_exterior_edge_material_available
        ) {
            return false;
        }
    }

    if (
        materialInformation.hasExteriorDoor &&
        !productData.available_options.is_door_available
    ) {
        return false;
    }

    if (materialInformation.hasCarcase) {
        if (
            !productData.available_options.is_carcase_material_available ||
            !productData.available_options.is_carcase_edge_material_available
        ) {
            return false;
        }
    }

    return true;
};

const addProductManually =
    (
        [getProductData]: ReturnType<typeof useLazyGetProductDataQuery>,
        [getProductConfig]: ReturnType<
            typeof useLazyGetQFPProductStructureQuery
        >,
        [getFormFieldDefaults]: ReturnType<
            typeof useLazyGetProductDefaultQuery
        >,
        getDrawers: ReturnType<typeof useGetDrawers>
    ) =>
    async ({
        product,
        room,
        width,
        height,
        depth,
        cabinetData = {},
    }: AddProductInterface) => {
        const {data: productData} = await getProductData(
            {
                cabinetType: product.id,
                roomId: room.id,
                cabinetId: null, // we are simply adding data here, this is used only when editing
            },
            true
        );
        const {data: productConfig} = await getProductConfig(
            {cabinetType: product.id},
            true
        );
        const cabinetInformation = getCabinetFields(
            productConfig.originalStructure
        );
        if (!checkMaterials(cabinetInformation, productData)) {
            // Note: Failing here, probably because materials are restricted.
            // check getProductData() api for more info
            throw new Error('Material information is missing');
        }

        const {hasCarcase, hasExterior, hasExteriorDoor} = cabinetInformation;

        const {data: defaultValuesFormFields} = await getFormFieldDefaults(
            {cabinetType: product.id},
            true
        );
        let defaultValues = getInitialValues(productConfig.originalStructure);
        const {
            shelvesStructureStore,
            partitionHeightReferenceStore,
            shelfTypes,
            shelfStyles,
            drawerRunnerInfoReference,
        } = getRequiredReferences(
            {structure: cloneDeep(productConfig.originalStructure)},
            defaultValues
        );

        defaultValues.room_id = room.id;

        const drawerHeight = defaultValues.cabinet_total_drawer_height
            ? defaultValues.cabinet_total_drawer_height
            : '';

        defaultValues = mapInitialValues({
            initialValues: defaultValues,
            productData,
            shelvesStructureStore: {current: shelvesStructureStore},
            simpleShelvesReference: {},
            partitionHeightReferenceStore: {
                current: partitionHeightReferenceStore,
            },
        });

        defaultValues = mapDefaults(defaultValues, defaultValuesFormFields);

        if (height && defaultValues.hasOwnProperty('cabinet_height')) {
            defaultValues.cabinet_height = height;
        }

        if (defaultValues.hasOwnProperty('cabinet_width')) {
            defaultValues.cabinet_width = width;
        }

        if (defaultValues.hasOwnProperty('cabinet_right_width')) {
            defaultValues.cabinet_right_width = width;
        }

        if (depth && defaultValues.hasOwnProperty('cabinet_depth')) {
            defaultValues.cabinet_depth = depth;
        }

        if (
            depth &&
            defaultValues.hasOwnProperty('cabinet_applied_panel_depth')
        ) {
            defaultValues.cabinet_applied_panel_depth = depth;
        }

        if (
            defaultValues.hasOwnProperty('cabinet_total_drawer_height') &&
            !defaultValues.hasOwnProperty('drawer_face_height_default')
        ) {
            defaultValues.cabinet_total_drawer_height = Excel.calculate(
                drawerHeight,
                defaultValues
            );
        }

        if (
            defaultValues.hasOwnProperty('cabinet_total_drawer_height') &&
            !defaultValues.hasOwnProperty('drawer_face_height_default')
        ) {
            defaultValues.cabinet_total_drawer_height = Excel.calculate(
                drawerHeight,
                defaultValues
            );
        }

        if (defaultValues.hasOwnProperty('cabinet_panel_length')) {
            defaultValues.cabinet_panel_length = height;
        }

        if (defaultValues.hasOwnProperty('cabinet_panel_width')) {
            defaultValues.cabinet_panel_width = width;
        }

        const validationData: {
            hasCustomColour: boolean;
            cabinet_ext_colour?: ReturnType<typeof getMinMaxSizes>;
            cabinet_door?: ReturnType<typeof mapDoorBack>;
            cabinet_carc_colour?: ReturnType<typeof getMinMaxSizes>;
        } = {hasCustomColour: false};
        const materials = getMaterials(productData);

        if (hasExterior) {
            validationData['cabinet_ext_colour'] = getMinMaxSizes(
                materials.exterior
            );

            if (hasExteriorDoor) {
                validationData['cabinet_door'] = mapDoorBack(materials.door);
            }

            if (materials.exterior.name.toLowerCase().includes('custom')) {
                validationData.hasCustomColour = true;
            }
        }

        if (hasCarcase) {
            validationData['cabinet_carc_colour'] = getMinMaxSizes(
                materials.carcase
            );

            if (!validationData.hasCustomColour) {
                if (materials.carcase.name.toLowerCase().includes('custom')) {
                    validationData.hasCustomColour = true;
                }
            }
        }

        if (cabinetData) {
            if (cabinetData.note) {
                defaultValues.cabinet_note = cabinetData.note;
            }
            if (
                cabinetData.hasOwnProperty('shelves') &&
                cabinetData.shelves &&
                cabinetData.shelves.length < 7
            ) {
                defaultValues.simple_shelves_amount = cabinetData.shelfCount;
                defaultValues.shelf_type = cabinetData.shelfType;
                defaultValues.shelf_position = cabinetData.shelfPosition;
                defaultValues.shelf_offset = cabinetData.shelfOffset;
                defaultValues.shelf_style = cabinetData.shelfStyle;
                defaultValues.shelves = cabinetData.shelves;
                defaultValues.cabinet_simple_shelves = false;
            }
            if (
                cabinetData.hasOwnProperty('drawerDetails') &&
                cabinetData.drawerDetails
            ) {
                defaultValues.cabinet_drawer_gap =
                    cabinetData.drawerDetails.drawerGap;
                defaultValues.cabinet_drawer_bottom =
                    cabinetData.drawerDetails.bottomMargin;
                defaultValues.cabinet_drawer_top =
                    cabinetData.drawerDetails.topMargin;
                defaultValues.drawer_face_height =
                    cabinetData.drawerDetails.faceDetails;

                if (
                    defaultValues.hasOwnProperty('drawers') &&
                    cabinetData.drawerDetails.hasOwnProperty('faceDetails')
                ) {
                    defaultValues.drawers = defaultValues.drawers.map((drawer, index) => {
                        if (cabinetData.drawerDetails.faceDetails[index]) {
                            drawer.drawer_face_height = cabinetData.drawerDetails.faceDetails[index];
                        }

                        return drawer;
                    });
                }
            }
        }

        if (
            defaultValues.hasOwnProperty('cabinet_top') &&
            productData.cabinet.attributes.hasOwnProperty(
                'available_cabinet_tops'
            )
        ) {
            const availableTops = JSON.parse(
                `[${productData.cabinet.attributes.available_cabinet_tops}]`
            );

            if (Array.isArray(availableTops)) {
                const cabinetTops = await getCabinetTop({
                    availableTops,
                    showNone:
                        productData.hasOwnProperty('has_none_in_tops') &&
                        productData.has_none_in_tops,
                });
                const hasSelectedTop = cabinetTops.find(
                    (cabinetTop) => cabinetTop.id == defaultValues.cabinet_top
                );

                if (!hasSelectedTop) {
                    defaultValues.cabinet_top = -1;
                }
            }
        }

        const drawerStructure = productConfig.originalStructure.find(
            (structure) => structure.name == 'Drawers'
        );
        if (drawerStructure) {
            const drawerRunnerData = getDrawerRunnerDetails({
                values: {...defaultValues, ...validationData},
                drawerRunnerInfoReference: {
                    current: drawerRunnerInfoReference,
                },
                fingerPullStyleOptions: {
                    current: {
                        fingerPull_styles: {
                            drawerGap: defaultValues.cabinet_drawer_gap
                                ? parseFloat(defaultValues.cabinet_drawer_gap)
                                : 0,
                            topMargin: defaultValues.cabinet_drawer_top
                                ? parseFloat(defaultValues.cabinet_drawer_top)
                                : 0,
                        },
                    },
                },
            });

            if (drawerRunnerData.drawerGap < 0) {
                throw new Error('Drawer gap is invalid');
            }

            if (
                drawerRunnerData.margin_top +
                    drawerRunnerData.cabinet_drawer_bottom >=
                drawerRunnerData.totalDrawerHeight
            ) {
                throw new Error('Invalid drawer margins');
            }

            defaultValues['drawers'] = await getDrawers(
                product.id,
                defaultValues,
                drawerRunnerData,
                drawerStructure
            );
        }

        const errors = validateProducts(
            defaultValues,
            productConfig.validation,
            validationData
        );

        defaultValues = mapProductData({
            values: defaultValues,
            productDataStore: {
                current: productData,
            },
            shelvesStructureStore: {
                current: shelvesStructureStore,
            },
            shelfTypes: {
                current: shelfTypes,
            },
            shelfStyles: {
                current: shelfStyles,
            },
        });

        if (errors.length > 0) {
            throw new Error(errors.map((error) => error.message).join('. '));
        }

        const response = await saveProduct(defaultValues);
        return {
            response,
            productData,
        };
    };
