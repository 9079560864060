import React, {useMemo} from 'react';
import {Alert} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {parseHtmlString} from 'shared/helpers/HTMLParser';

interface ErrorHandlerInterface {
    show?: boolean;
    errors?: {[key: string]: string};
    touched: {[key: string]: string};
    externalErrors?: string[];
    onClose?: () => void;
}

const emptyErrors: string[] = [];
const emptyFunction = () => {
    //
};

export const ErrorHandler = ({
    show = true,
    errors,
    touched,
    externalErrors = emptyErrors,
    onClose = emptyFunction,
    ...attr
}: ErrorHandlerInterface) => {
    const errorMessages = useMemo(() => {
        const messages: string[] = [];

        if (Array.isArray(externalErrors) && externalErrors.length) {
            externalErrors.forEach((error) => messages.push(error));
        }

        Object.keys(errors).forEach((key) => {
            if (touched[String(key)] && errors[String(key)]) {
                messages.push(errors[String(key)]);
            }
        });

        return messages;
    }, [externalErrors, errors, touched]);

    if (errorMessages.length) {
        return (
            <Alert
                className="cbc-top-message"
                variant="danger"
                {...attr}
                show={show}
                onClose={onClose}
                dismissible={typeof onClose == 'function'}>
                <Icon iconName="Button-Error.svg" />
                <div>
                    {errorMessages.map((error, index) => {
                        return <div key={index}>{parseHtmlString(error)}</div>;
                    })}
                </div>
            </Alert>
        );
    }

    return null;
};
