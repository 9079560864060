import {ErrorGIF} from 'assets';
import {User, UserApi} from 'components/customer/Auth/store/userSlice';
import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import {connect} from 'react-redux';
import Layout from 'shared/components/Layout';
import {AppDispatch, AppState} from 'store/customer/storeSetup';
import styled from 'styled-components';

interface ErrorBoundaryProps {
    dispatch: AppDispatch;
    user: {data: User};
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

/**
 *
 *
 * @class ErrorBoundary
 * @extends {React.Component}
 */
class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    /**
     * Creates an instance of ErrorBoundary.
     * @param {*} props
     * @memberof ErrorBoundary
     */
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false};
    }

    /**
     *
     *
     * @static
     * @memberof ErrorBoundary
     */
    componentDidMount() {
        const {dispatch, user} = this.props;

        if (!user) {
            void dispatch(UserApi.endpoints.getUser.initiate());
        }
    }

    /**
     *
     *
     * @static
     * @return {*}
     * @memberof ErrorBoundary
     */
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    /**
     *
     * @memberof ErrorBoundary
     */
    componentDidCatch() {
        // You can also log the error to an error reporting service
    }

    /**
     *
     *
     * @return {*}
     * @memberof ErrorBoundary
     */
    render() {
        const {hasError} = this.state;
        if (hasError) {
            const {user} = this.props;
            const manufacturerEmail = user?.data?.manufacturerEmail || '';
            const manufacturerPhone = user?.data?.manufacturerPhone || '';

            // You can render any custom fallback UI
            return (
                <Layout>
                    <>
                        <Row>
                            <Col
                                md={{span: 6, offset: 3}}
                                style={{textAlign: 'center'}}>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Image
                                    src={String(ErrorGIF)}
                                    style={{width: '200px'}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span: 5, offset: 4}}>
                                <Title>
                                    Something went wrong while loading your
                                    page.
                                </Title>
                                <br />
                                <SubTitle>Please try:</SubTitle>
                                <ul>
                                    <li>Refreshing the page</li>
                                    <li>
                                        Log out of your account, then log back
                                        in again
                                    </li>
                                </ul>
                                <SubTitle>
                                    If the issue persists, please contact:
                                </SubTitle>
                                {manufacturerEmail ? (
                                    <>
                                        <strong>
                                            Email:{' '}
                                            <a
                                                href={`mailto:${manufacturerEmail}`}>
                                                {manufacturerEmail}
                                            </a>
                                        </strong>
                                        <br />
                                    </>
                                ) : null}
                                {manufacturerPhone ? (
                                    <>
                                        <strong>
                                            Phone:{' '}
                                            <a
                                                href={`tel:${manufacturerPhone}`}>
                                                {manufacturerPhone}
                                            </a>
                                        </strong>
                                        <br />
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </>
                </Layout>
            );
        }

        const {children} = this.props;
        return children;
    }
}

export default connect((state: AppState) => ({
    user: UserApi.endpoints.getUser.select()(state) as {data: User},
}))(ErrorBoundary);

const Title = styled.p`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
`;

const SubTitle = styled.p`
    font-size: 1rem;
    font-weight: bold;
`;
