import React from 'react';
import {Alert} from 'react-bootstrap';
import {UserType} from 'components/customer/Auth/store/userSlice';
import {
    UserAccount,
    redirectingSet,
} from 'components/customer/Auth/store/authSlice';
import {store} from 'store/customer';

/**
 * Redirects the user who's data is provided to their post-login homepage
 *
 * @param {UserAccount} user User account details
 * @param {boolean} skipInternalRedirect Whether to skip any internal redirects
 * @return {boolean} Whether we performed a redirect or not
 */
export const redirectUser = (
    user: UserAccount,
    skipInternalRedirect = false
): boolean => {
    const location = window.location;
    const userDomain = user.domain == null ? '' : user.domain.toLowerCase();
    const isLocalhost = location.hostname.toLowerCase().endsWith('localhost');

    let destination = user.redirectTo;

    while (destination.endsWith('/')) {
        destination = destination.substring(0, destination.length - 1);
    }

    if (destination == '') {
        if (user.user_type === UserType.CUSTOMER) {
            destination = 'v2';
        } else {
            store.dispatch(redirectingSet(true));
            destination = 'index.php';
        }
    }

    if (
        !isLocalhost &&
        userDomain != '' &&
        userDomain != location.host.toLowerCase()
    ) {
        // dispatch loading state to prevent rendering page while redirecting to subdomain
        store.dispatch(redirectingSet(true));
        window.location.replace(
            `${location.protocol}//${userDomain}/${destination}`
        );

        return true;
    }

    if (destination.startsWith('v2') && skipInternalRedirect) {
        return false;
    }

    window.location.href = `/${destination}`;

    return true;
};

export const DisplayMessage = ({
    errors,
    touched,
}: {
    errors: object;
    touched: object;
}): JSX.Element => {
    return (
        <>
            {Object.keys(errors).map((key, index) => {
                return touched[key] ? (
                    <Alert
                        key={index}
                        style={{
                            marginBottom: '15px',
                            paddingLeft: '15px',
                            textAlign: 'left',
                        }}
                        variant="danger">
                        {errors[key]}
                    </Alert>
                ) : (
                    <React.Fragment key={index}></React.Fragment>
                );
            })}
        </>
    );
};
