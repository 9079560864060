import {useAppContext, useProductContext} from 'contexts';
import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useRef} from 'react';
import {InputGroup} from 'react-bootstrap';
import {FormControl} from 'shared';
import {Icon} from 'shared/helpers';
import {DoorHinge} from 'shared/types';
import excel from 'shared/Excel';
import {useSearchParams} from 'react-router-dom';

type SizeType = {
    name: string;
    value: string | number;
    metric: string;
    hideDialog: () => void;
    inSpecsTab?: boolean;
    isQFP?: boolean;
    withDeleteIcon?: boolean;
    deleteAction?: () => void;
    customWidth?: string;
};

export const Size = ({
    name,
    value,
    metric,
    hideDialog,
    isQFP,
    inSpecsTab,
    withDeleteIcon,
    deleteAction,
    customWidth,
    ...otherParams
}: SizeType): JSX.Element => {
    const {getMaterialOptions}: {getMaterialOptions: () => object} =
        useProductContext();
    const {userProfile} = useAppContext();
    const formik = useFormikContext<{[key: string]: number}>();
    const [searchParams] = useSearchParams();

    const updateFields = useRef<string[]>([
        'rem_door_shelf_height',
        'cabinet_height',
    ]).current;

    const cabinetId = searchParams.get('cabinetId');

    const updateHingeHolesAmount = useCallback(
        (height) => {
            if (formik) {
                let hingeReference: DoorHinge[] = [
                    {
                        amount: 3,
                        height: 900,
                    },
                    {
                        amount: 4,
                        height: 1500,
                    },
                    {
                        amount: 5,
                        height: 2200,
                    },
                ];
                let newHingeAmount = 2;

                if (
                    userProfile.doorHingeHoles &&
                    Array.isArray(userProfile.doorHingeHoles)
                ) {
                    hingeReference = userProfile.doorHingeHoles;
                }

                hingeReference.forEach((hingeRef) => {
                    if (height >= hingeRef.height) {
                        newHingeAmount = hingeRef.amount;
                    }
                });

                formik.setFieldValue('door_hinge_amount', newHingeAmount);
                formik.setFieldValue('door_hinge_amount_1', newHingeAmount);
            }
        },
        [userProfile]
    );

    useEffect(() => {
        if (
            typeof cabinetId === 'undefined' &&
            updateFields.includes(name) &&
            formik &&
            formik.values.hasOwnProperty('door_hinge_amount')
        ) {
            if (
                name === 'cabinet_height' &&
                formik.values.hasOwnProperty('rem_door_shelf_height')
            ) {
                return;
            }

            const height = excel.calculate(formik.values[name], {
                ...formik.values,
                ...getMaterialOptions(),
            });

            updateHingeHolesAmount(height);
        }
        return () => {
            hideDialog && hideDialog();
        };
    }, [value, cabinetId]);

    return (
        <InputGroup
            className="cbc-size"
            style={{
                width: !!customWidth
                    ? customWidth
                    : inSpecsTab && !isQFP
                    ? '150px'
                    : 'auto',
            }}>
            <FormControl
                {...otherParams}
                type="number"
                name={name}
                value={value}
                isQFP={isQFP}
            />
            <InputGroup.Append>
                <InputGroup.Text>{metric}</InputGroup.Text>
            </InputGroup.Append>
            {withDeleteIcon ? (
                <Icon
                    iconName="Options-Delete.svg"
                    style={{width: 22, cursor: 'pointer', margin: '0 8px'}}
                    onClick={deleteAction}
                />
            ) : null}
        </InputGroup>
    );
};
