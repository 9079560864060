import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';
import styled from 'styled-components';
import {Flex} from 'shared/components/StyledComponents';

const StyledFooter = styled(Modal.Footer)`
    background: rgb(var(--primary_colour));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 10px;
    justify-content: space-around;
    gap: 5px;
`;

const ButtonContainer = styled(Flex)`
    @media (max-width: 695px) {
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }
    & > .button-transparent {
        padding: 5px 10px !important;
        min-height: 40px;
        border: 2px solid white !important;
        border-radius: 40px !important;
        &:hover {
            border: 2px solid rgb(var(--secondary_colour)) !important;
            background: rgb(var(--secondary_colour));
        }
    }
`;

// FIXME: Use CBCButton instead of inline component when we have
// a proper cypress test.
const Button = styled((props) => <CBCButton {...props} />)`
    border-radius: 40px !important;
    padding: 5px 10px 5px 5px !important;
    width: fit-content !important;
    font-size: 0.95em !important;
    &:hover {
        border: 1px solid white;
    }
    @media (max-width: 695px) {
        font-size: 0.8em !important;
        width: 80% !important;
    }
`;

export enum CloseEventType {
    CANCEL = 'cancel',
    APPLY = 'apply',
    OK = 'ok',
}

interface FooterInterface {
    yesButtonText?: string;
    noButtonText?: string;
    okButtonText?: string;
    onClose?: (event: CloseEventType) => void;
    className?: string;
    okIcon?: string;
    okButtonClassname?: string;
}

const Footer = ({
    yesButtonText,
    noButtonText,
    okButtonText,
    onClose,
    className,
    okIcon,
    okButtonClassname,
}: FooterInterface) => {
    const cancel = useCallback(() => {
        onClose(CloseEventType.CANCEL);
    }, [onClose]);

    const apply = useCallback(() => {
        onClose(CloseEventType.APPLY);
    }, [onClose]);

    const ok = useCallback(() => {
        onClose(CloseEventType.OK);
    }, [onClose]);

    return (
        <StyledFooter className={className}>
            {noButtonText ? (
                <Button
                    onClick={cancel}
                    iconName="Button-Cancel.svg"
                    className="job-button button-light"
                    style={{color: '#8A8080'}}>
                    {noButtonText}
                </Button>
            ) : null}
            <ButtonContainer $gap="20px" $alignItems="center">
                <>
                    {!!okButtonText ? (
                        <Button
                            onClick={ok}
                            type="button"
                            iconName={okIcon}
                            className={
                                okButtonClassname
                                    ? okButtonClassname
                                    : 'job-button button-blue'
                            }>
                            {okButtonText}
                        </Button>
                    ) : null}

                    {!!yesButtonText ? (
                        <Button
                            onClick={apply}
                            type="button"
                            iconName="Button-Tick.svg"
                            className="job-button button-blue">
                            {yesButtonText}
                        </Button>
                    ) : null}
                </>
            </ButtonContainer>
        </StyledFooter>
    );
};

export default Footer;
