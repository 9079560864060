import Shape from './Shape';

interface DrawHangInterface {
    xOffset: number;
    yOffset: number;
    width: number;
    height: number;
    isRight: boolean;
    small: boolean;
    horizontalHinge: boolean;
    reverseDoorHang: boolean;
}

/**
 *
 *
 * @export
 * @class Rectangle
 * @extends {Shape}
 */
export default class Rectangle extends Shape {
    /**
     *
     *
     * @param {number} rectWidth
     * @param {number} rectXOffset
     * @param {number} rectYOffset
     * @param {string} text
     * @memberof Rectangle
     */
    horizontalLabel(
        rectWidth: number,
        rectXOffset: number,
        rectYOffset: number,
        text: string
    ) {
        this.line(
            [rectXOffset, rectYOffset - 15],
            [rectXOffset + rectWidth, rectYOffset - 15]
        );

        this.label(
            [this.canvas.width / 2 - 25, rectYOffset - 30],
            [parseInt(this.canvas.width) / 2 - 15, rectYOffset - 12],
            text,
            60,
            20
        );
    }

    /**
     *
     *
     * @param {number} rectWidth
     * @param {number} rectHeight
     * @param {number} rectXOffset
     * @param {number} rectYOffset
     * @param {string} text
     * @memberof Rectangle
     */
    verticalLabel(
        rectWidth: number,
        rectHeight: number,
        rectXOffset: number,
        rectYOffset: number,
        text: string
    ) {
        this.line(
            [rectWidth + rectXOffset + 15, rectYOffset],
            [rectWidth + rectXOffset + 15, rectYOffset + rectHeight]
        );

        this.label(
            [rectWidth + rectXOffset + 2, rectYOffset + rectHeight / 2 - 13],
            [rectWidth + rectXOffset + 5, rectYOffset + rectHeight / 2],
            text,
            32,
            20
        );
    }

    /**
     *
     *
     * @param {Array<number>} start
     * @param {Array<number>} end
     * @memberof Rectangle
     */
    drawLine(start: Array<number>, end: Array<number>) {
        this.context.beginPath();
        this.context.moveTo(start[0], start[1]);
        this.context.lineTo(end[0], end[1]);
        this.context.stroke();
    }

    /**
     *
     *
     * @param {number} rectXOffset
     * @param {number} rectYOffset
     * @param {number} rectWidth
     * @param {number} rectHeight
     * @param {boolean} [rotated=false]
     * @memberof Rectangle
     */
    edges(
        rectXOffset: number,
        rectYOffset: number,
        rectWidth: number,
        rectHeight: number,
        rotated: boolean = false
    ) {
        this.context.lineWidth = this.edgeWidth;
        this.context.strokeStyle = this.edgeColor;

        const edgeOffset: number = this.edgeDistance + this.edgeWidth;
        const topEdge: Array<Array<number>> = [
            [rectXOffset - edgeOffset, rectYOffset - edgeOffset],
            [rectXOffset + rectWidth + edgeOffset, rectYOffset - edgeOffset],
        ];

        const bottomEdge: Array<Array<number>> = [
            [rectXOffset - edgeOffset, rectYOffset + rectHeight + edgeOffset],
            [
                rectXOffset + rectWidth + edgeOffset,
                rectYOffset + rectHeight + edgeOffset,
            ],
        ];

        const leftEdge: Array<Array<number>> = [
            [rectXOffset - edgeOffset, rectYOffset - edgeOffset],
            [rectXOffset - edgeOffset, rectYOffset + rectHeight + edgeOffset],
        ];

        const rightEdge: Array<Array<number>> = [
            [rectXOffset + rectWidth + edgeOffset, rectYOffset - edgeOffset],
            [
                rectXOffset + rectWidth + edgeOffset,
                rectYOffset + rectHeight + edgeOffset,
            ],
        ];

        if (rotated) {
            this.options.edging.edgeLength1 &&
                this.drawLine(leftEdge[0], leftEdge[1]);

            this.options.edging.edgeLength2 &&
                this.drawLine(rightEdge[0], rightEdge[1]);

            this.options.edging.edgeWidth1 &&
                this.drawLine(topEdge[0], topEdge[1]);

            this.options.edging.edgeWidth2 &&
                this.drawLine(bottomEdge[0], bottomEdge[1]);
        } else {
            this.options.edging.edgeLength1 &&
                this.drawLine(topEdge[0], topEdge[1]);

            this.options.edging.edgeLength2 &&
                this.drawLine(bottomEdge[0], bottomEdge[1]);

            this.options.edging.edgeWidth1 &&
                this.drawLine(leftEdge[0], leftEdge[1]);

            this.options.edging.edgeWidth2 &&
                this.drawLine(rightEdge[0], rightEdge[1]);
        }

        this.context.strokeStyle = '#000';
        this.context.lineWidth = 3;
    }

    /**
     *
     *
     * @memberof Rectangle
     */
    async draw() {
        const edging = this.options.hasOwnProperty('edging');
        const options = this.options;

        let width: number = options.hasOwnProperty('height')
            ? options.height
            : options.length
            ? options.length
            : options.depth;
        let height: number = options.hasOwnProperty('width')
            ? options.width
            : options.depth;
        let rotated: boolean = false;

        if (options.hasOwnProperty('rotated') && options.rotated) {
            rotated = true;
            width = options.hasOwnProperty('width')
                ? options.width
                : options.depth;
            height = options.hasOwnProperty('height')
                ? options.height
                : options.length
                ? options.length
                : options.depth;
        }

        const [rectWidth, rectHeight] = this.dimension(width, height);
        const rectXOffset: number =
            this.canvas.getBoundingClientRect().width / 2 - rectWidth / 2;
        const rectYOffset: number =
            this.canvas.getBoundingClientRect().height / 2 - rectHeight / 2;

        this.context.beginPath();
        this.context.rect(rectXOffset, rectYOffset, rectWidth, rectHeight);
        this.context.strokeStyle = '#000';
        this.context.lineWidth = 3;
        this.context.stroke();

        // write dimensions
        if (
            this.options.hasOwnProperty('height') ||
            this.options.hasOwnProperty('length')
        ) {
            const text: string = this.options.hasOwnProperty('horizontalLabel')
                ? this.options.horizontalLabel
                : this.options.hasOwnProperty('height')
                ? 'Height'
                : 'Length';

            if (!edging) {
                if (rotated)
                    this.verticalLabel(
                        rectWidth,
                        rectHeight,
                        rectXOffset,
                        rectYOffset,
                        text
                    );
                else
                    this.horizontalLabel(
                        rectWidth,
                        rectXOffset,
                        rectYOffset,
                        text
                    );
            }
        }

        if (
            this.options.hasOwnProperty('width') ||
            this.options.hasOwnProperty('depth')
        ) {
            const text: string = this.options.hasOwnProperty('verticalLabel')
                ? this.options.verticalLabel
                : this.options.hasOwnProperty('width')
                ? 'Width'
                : 'Depth';

            if (!edging) {
                if (!rotated)
                    this.verticalLabel(
                        rectWidth,
                        rectHeight,
                        rectXOffset,
                        rectYOffset,
                        text
                    );
                else
                    this.horizontalLabel(
                        rectWidth,
                        rectXOffset,
                        rectYOffset,
                        text
                    );
            }
        }

        edging &&
            this.edges(
                rectXOffset,
                rectYOffset,
                rectWidth,
                rectHeight,
                rotated
            );
    }

    /**
     *
     *
     * @param {number} xOffset
     * @param {number} yOffset
     * @param {number} width
     * @param {number} height
     * @param {boolean} [isRight=true]
     * @param {boolean} [small=false]
     * @param {boolean} [horizontalHinge=false]
     * @param {boolean} [reverseDoorHang=false]
     * @memberof Rectangle
     */
    drawHang({
        xOffset,
        yOffset,
        width,
        height,
        isRight = true,
        small = false,
        horizontalHinge = false,
        reverseDoorHang = false,
    }: DrawHangInterface) {
        if (this.options.doorHang != 2) {
            const hangAndHingeColour: string = this.color(
                xOffset,
                yOffset,
                width,
                height,
                '#cfcfcf',
                '#242424'
            );
            const hangOffset: number =
                (this.options.doorType > 2 || this.options.hangType > 2) &&
                this.options.widths
                    ? 8
                    : 4;

            this.context.beginPath();
            this.context.lineWidth = 1;
            this.context.strokeStyle =
                hangAndHingeColour == '#242424' ? '#cfcfcf' : '#242424';
            this.context.fillStyle = hangAndHingeColour;

            if (horizontalHinge) {
                this.context.moveTo(xOffset - hangOffset + 1.5, yOffset);
                this.context.lineTo(xOffset + width / 2, yOffset + height);
                this.context.lineTo(
                    xOffset + width + hangOffset - 1.5,
                    yOffset
                );

                this.context.lineTo(xOffset + width, yOffset);
                this.context.lineTo(
                    xOffset + width / 2,
                    yOffset + height - hangOffset
                );
                this.context.lineTo(xOffset, yOffset);
            } else {
                const arrowPresentation = reverseDoorHang ? !isRight : isRight;

                if (arrowPresentation) {
                    this.context.moveTo(xOffset + width - 2, yOffset);
                    this.context.lineTo(xOffset, yOffset + height / 2);
                    this.context.lineTo(xOffset + width - 2, yOffset + height);

                    this.context.lineTo(xOffset + width, yOffset + height - 2);
                    this.context.lineTo(
                        xOffset + hangOffset,
                        yOffset + height / 2
                    );
                    this.context.lineTo(xOffset + width, yOffset + 2);
                    this.context.lineTo(xOffset + width - 2, yOffset);
                } else {
                    this.context.moveTo(xOffset + 2, yOffset);
                    this.context.lineTo(xOffset + width, yOffset + height / 2);
                    this.context.lineTo(xOffset + 2, yOffset + height);

                    this.context.lineTo(xOffset, yOffset + height - 2);
                    this.context.lineTo(
                        xOffset + width - hangOffset,
                        yOffset + height / 2
                    );
                    this.context.lineTo(xOffset, yOffset + 2);
                    this.context.lineTo(xOffset, yOffset);
                }
            }
            this.context.fill();
            this.context.stroke();

            if (
                this.options.hasOwnProperty('drillings') &&
                this.options.drillings.length > 0
            ) {
                const ratio: number =
                    height < this.options.height
                        ? height / this.options.height
                        : this.options.height / height;

                this.options.drillings.forEach((drilling, index) => {
                    let y = ratio * drilling.drilling_offset_y;

                    if (index > 0) {
                        y = height - y + yOffset;
                    } else {
                        y = y + yOffset;
                    }

                    this.drawHinge(
                        height,
                        xOffset + (isRight ? width : 0),
                        y,
                        isRight,
                        small,
                        hangAndHingeColour
                    );
                });
            }
        }
    }

    /**
     *
     *
     * @param {number} doorHeight
     * @param {number} xOffset
     * @param {number} yOffset
     * @param {boolean} [rightHang=false]
     * @param {boolean} [small=false]
     * @param {string} hangAndHingeColour
     * @memberof Rectangle
     */
    drawHinge(
        doorHeight: number,
        xOffset: number,
        yOffset: number,
        rightHang: boolean = false,
        small: boolean = false,
        hangAndHingeColour: string
    ) {
        if (
            this.options.hasOwnProperty('hingeBlack') ||
            this.options.hasOwnProperty('hingeWhite')
        ) {
            const ratio: number =
                doorHeight < this.options.height
                    ? doorHeight / this.options.height
                    : this.options.height / doorHeight;

            const width: number = Math.floor(35 * ratio);
            const height: number = Math.floor(51 * ratio);
            const hingeOffset: number = Math.floor(4 * ratio);

            const hingeImage: HTMLImageElement = document.createElement('img');
            hingeImage.style.width = `${width}px`;
            hingeImage.style.height = `${height}px`;
            hingeImage.setAttribute('width', `${width}`);
            hingeImage.setAttribute('height', `${height}`);

            hingeImage.onload = (target) => {
                if (rightHang) {
                    xOffset = xOffset * -1 - width;
                    this.context.save();
                    this.context.scale(-1, 1);
                }

                xOffset =
                    xOffset + (rightHang ? width + hingeOffset : hingeOffset);

                this.context.drawImage(
                    hingeImage,
                    xOffset,
                    yOffset - height / 2,
                    width,
                    height
                );
                rightHang && this.context.restore();
            };

            let hingeImageSrc: string = this.options.hingeWhite;

            if (hangAndHingeColour == '#242424')
                hingeImageSrc = this.options.hingeBlack;

            hingeImage.src = hingeImageSrc;
        }
    }
}
