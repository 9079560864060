import LShape from "./LShape";

export default class Pentagon extends LShape{

    draw() {
        let options = this.options;
        const [ leftWidth, rightWidth, leftDepth, rightDepth ] = this.dimension(
            options.leftWidth,
            options.rightWidth,
            options.leftDepth,
            options.rightDepth
        );
        let xOffset = (this.canvas.getBoundingClientRect().width / 2) - (rightWidth/2);
        let yOffset = (this.canvas.getBoundingClientRect().height / 2) - (leftWidth/2);

        this.context.beginPath();
        this.context.moveTo( xOffset, yOffset );
        this.context.lineTo( xOffset, yOffset + leftWidth);
        this.context.lineTo( xOffset + leftDepth, yOffset + leftWidth);
        this.context.lineTo( xOffset + rightWidth, yOffset + rightDepth );
        this.context.lineTo( xOffset + rightWidth, yOffset);
        this.context.lineTo(xOffset , yOffset);

        this.context.lineWidth = 3;
        this.context.stroke();

        this.lines(
            xOffset,
            yOffset,
            leftWidth,
            rightWidth,
            leftDepth,
            rightDepth
        );

        this.labels(
            xOffset,
            yOffset,
            leftWidth,
            rightWidth,
            leftDepth,
            rightDepth
        );
    }
}