import React, {useEffect} from 'react';
import {Row, Col, Image, Container} from 'react-bootstrap';
import {ErrorGIF} from 'assets';

type SuspendedProp = {
    userProfile: {
        manufacturerName: string;
        manufacturerEmail: string;
        manufacturerPhone: string;
    };
};

/**
 *
 *
 * @export
 * @param {SuspendedProp} {userProfile}
 * @return {React.JSX}
 */
export function Suspended({userProfile}: SuspendedProp) {
    useEffect(() => {
        if (window.indexedDB) {
            window.indexedDB.deleteDatabase('CBCClientDatabase');
        }
    }, []);

    return (
        <Container>
            <Row>
                <Col md={{span: 6, offset: 3}} style={{textAlign: 'center'}}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Image src={String(ErrorGIF)} style={{width: '200px'}} />
                    <br />
                    <p>Your account has been suspended.</p>
                    <p>
                        Please contact your manufacturer,{' '}
                        {userProfile.manufacturerName}, to resolve any issues.{' '}
                    </p>
                    <p>Thank You</p>
                    <p>{userProfile.manufacturerName}</p>{' '}
                    <p>Email: {userProfile.manufacturerEmail}</p>
                    <p> Phone : {userProfile.manufacturerPhone}</p>
                </Col>
            </Row>
        </Container>
    );
}
