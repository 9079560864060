import React from 'react';

import TableWithAPI from 'shared/components/Table/TableWithAPI';
import TableWithData from 'shared/components/Table/TableWithData';

import type {OptionsType} from 'shared/types';

import './Table.scss';

export const CBCTable = ({
    options,
    ...props
}: {
    options: OptionsType;
}): JSX.Element => {
    if (typeof options.data == 'undefined' || options.api) {
        return <TableWithAPI options={options} {...props} />;
    }
    return <TableWithData options={options} {...props} />;
};
