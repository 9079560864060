import {number, string} from 'yup';
import {FIELD_TYPES} from 'shared';
import excel from 'shared/Excel';
import {isDrillOnly} from 'components/customer/Product/Drawer/helpers';
import {intersection} from 'lodash/array';
import {sum} from 'lodash';
import {formatStringWithFields} from 'shared/helpers';

const CUSTOM_MESSAGE_KEY = 'message';

export const isNumericField = (field) => {
    return ['numeric', 'size', 'part_top'].indexOf(field) > -1;
};

const message = (field) => {
    if (field.options.hasOwnProperty(CUSTOM_MESSAGE_KEY))
        return field.options[String(CUSTOM_MESSAGE_KEY)];
    else return `Please enter a value for ${field.displayName}`;
};

const numeric = (field, fieldset, allFields) => {
    let rule = number().transform((value) =>
        isNaN(value) ? undefined : value
    );
    let min =
        field.hasOwnProperty('options') &&
        field.options.hasOwnProperty('minimum')
            ? field.options.minimum
            : 0;
    let max =
        field.hasOwnProperty('options') &&
        field.options.hasOwnProperty('maximum')
            ? field.options.maximum
            : 99999;

    min = !isNaN(min) ? parseInt(min) : min;
    max = !isNaN(max) ? parseInt(max) : max;

    const dependentFields = [];

    allFields.forEach((field) => {
        if (isNaN(min) && min.indexOf(field) > -1) {
            dependentFields.push(field);
        }

        if (isNaN(max) && max.indexOf(field) > -1) {
            dependentFields.push(field);
        }
    });

    if (dependentFields.length) {
        rule = rule.when(dependentFields, (values, schema) => {
            const scope = {};
            dependentFields.forEach((field, i) => {
                scope[field] = values[i];
            });

            const displayName = field.hasOwnProperty('displayName')
                ? formatStringWithFields(field.displayName, {
                      ...scope,
                      fieldset: {index: field.index},
                  })
                : 'Value';

            if (isNaN(min)) {
                const value = excel.calculate(min, scope);
                schema = schema.min(
                    value,
                    `${fieldset.title} ${displayName} cannot be less than ${value}`
                );
            }

            if (isNaN(max)) {
                const value = excel.calculate(max, scope);
                schema = schema.max(
                    value,
                    `${fieldset.title} ${displayName} cannot be more than ${value}`
                );
            }

            return schema;
        });
    } else {
        const displayName = field.hasOwnProperty('displayName')
            ? formatStringWithFields(field.displayName, {
                  fieldset: {index: field.index},
              })
            : 'Value';

        rule = rule
            .min(min, `${displayName} cannot be less than ${min}`)
            .max(max, `${displayName} cannot be more than ${max}`);
    }

    if (field.options.hasOwnProperty('mandatory') && field.options.mandatory) {
        rule = rule.required(message(field));
    }

    return rule;
};

const partTop = (field) => {
    if (field.options.hasOwnProperty('mandatory') && field.options.mandatory)
        return number().required(message(field));
    else return number();
};

const text = (field) => {
    let rule;

    if (field.options.hasOwnProperty('mandatory') && field.options.mandatory)
        rule = string().required(message(field));
    else rule = string();

    if (
        field.options.hasOwnProperty('maxLength') &&
        !isNaN(field.options.hasOwnProperty('maxLength'))
    ) {
        rule = rule.test(
            'textLength',
            `${field.displayName} has too many characters. Must be less than ${
                field.options.maxLength + 1
            } characters`,
            (val) => (val ? val.length <= field.options.maxLength : true)
        );
    }

    return rule;
};

export const checkMaterialRestrictions = (validationData) => {
    const errors = [];
    if (
        validationData.cabinet_ext_colour &&
        validationData.cabinet_ext_colour.hidden
    ) {
        errors.push({
            message: 'Restricted exterior color selected',
            fields: [],
        });
    }

    if (
        validationData.cabinet_ext_edge_colour &&
        validationData.cabinet_ext_edge_colour.hidden
    ) {
        errors.push({
            message: 'Restricted exterior edge color selected',
            fields: [],
        });
    }
    if (
        validationData.cabinet_carc_colour &&
        validationData.cabinet_carc_colour.hidden
    ) {
        errors.push({
            message: 'Restricted carcase color selected',
            fields: [],
        });
    }

    if (
        validationData.cabinet_carc_edge_colour &&
        validationData.cabinet_carc_edge_colour.hidden
    ) {
        errors.push({
            message: 'Restricted carcase edge color selected',
            fields: [],
        });
    }

    if (validationData.cabinet_door && validationData.cabinet_door.hidden) {
        errors.push({
            message: 'Restricted door style selected',
            fields: [],
        });
    }

    return errors;
};

export const validator = (field, fieldset, allFields) => {
    if (field.name === 'cabinet_void_width') {
        return number().when(
            'cabinet_cover_void',
            ([cabinet_cover_void], schema) => {
                if (cabinet_cover_void) {
                    return schema
                        .typeError('Width must be a number')
                        .required('Please enter value for cabinet void width')
                        .min(
                            1,
                            'Please enter valid value for cabinet void width'
                        );
                }

                return schema;
            }
        );
    }

    if (field.name == 'cabinet_ext_radius') {
        return number().min(
            1,
            `${
                field.displayName ? field.displayName : 'Exterior radius'
            } cannot be less than 1`
        );
    }

    if (field.name == 'cabinet_int_radius') {
        return number().min(
            1,
            `${
                field.displayName ? field.displayName : 'Interior radius'
            } cannot be less than 1`
        );
    }

    const allowNegativeMargin = [
        'cabinet_drawer_top',
        'cabinet_drawer_right',
        'cabinet_drawer_bottom',
        'cabinet_drawer_left',
        'cabinet_door_top',
        'cabinet_door_right',
        'cabinet_door_bottom',
        'cabinet_door_left',
    ];
    if (allowNegativeMargin.includes(field.name)) {
        return number().min(-9999);
    }

    switch (field.type) {
        case FIELD_TYPES.NUMERIC:
        case FIELD_TYPES.SIZE:
            return numeric(field, fieldset, allFields);
        case FIELD_TYPES.PART_TOP:
            return partTop(field);
        case FIELD_TYPES.TEXT:
            return text(field);
    }
};

/**
 * Returns whether the value passed is a valid value for a numeric field
 *
 * @param {any} value Value to validate
 * @return {boolean}
 */
const isFieldValid = (value) => {
    // Zero is explicitly valid
    if (value === 0 || value === '0') {
        return true;
    }

    if (isNaN(value) || value == '' || value == null) {
        return false;
    }

    return true;
};

/**
 * Fixes any array fields present by substituting in the index value passed
 *
 * @param {string[]} fields Fields to fix
 * @param {number} index Index value
 * @return {string[]}
 */
const fixArrayFields = (fields, index) => {
    return fields.map((field) => {
        const regs = field.match(/^(.+)\[index\]$/);

        if (regs) {
            return regs[1] + '[' + index + ']';
        }

        return field;
    });
};

/**
 * Format an error object
 *
 * @param {string} message Error message
 * @param {string[]} fields Fields
 * @param {Object} scope Scope
 * @return {Object}
 */
const formatError = (message, fields, scope) => {
    return {
        message: formatStringWithFields(message, {
            ...scope,
            fieldset: {index: scope.index},
        }),
        fields: fixArrayFields(fields, scope.index),
    };
};

export const validateProducts = (
    values,
    validators,
    validationData,
    isQFP = false
) => {
    const errors = [];

    const scope = {
        drawer_face_height: values.drawers
            ? values.drawers.map((value) => value.drawer_face_height)
            : [],
        ...JSON.parse(JSON.stringify(values)),
        ...JSON.parse(JSON.stringify(validationData)),
    };

    const materialRestrictions = checkMaterialRestrictions(validationData);

    if (materialRestrictions.length > 0) {
        return materialRestrictions;
    }

    const enabledValidators = [];
    const invalidFields = [];

    const quantityValidators = [];
    const expandedValidators = validators.filter((validator) => {
        if (validator.hasOwnProperty('quantity')) {
            quantityValidators.push(validator);

            return false;
        }

        return true;
    });

    quantityValidators.forEach((validator) => {
        const quantity = excel.calculate(validator.quantity, scope);

        for (let i = 0; i < quantity; i++) {
            expandedValidators.push({...validator, index: i});
        }
    });

    expandedValidators.forEach((validator) => {
        if (validator.hasOwnProperty('index')) {
            scope.index = validator.index + 1; // Excel indexes start at 1
        } else {
            scope.index = 0;
        }

        if (
            validator.hasOwnProperty('enabled') &&
            !excel.calculate(validator.enabled, scope)
        ) {
            return;
        }

        let fields = [];
        switch (validator.type) {
            case 'panel':
                const widthFields =
                    validator.hasOwnProperty('widthFields') &&
                    validator.widthFields.length
                        ? validator.widthFields
                        : [];
                const heightFields =
                    validator.hasOwnProperty('heightFields') &&
                    validator.heightFields.length
                        ? validator.heightFields
                        : [];

                fields = [...widthFields, ...heightFields];

                break;
            case 'single':
            case 'range':
                fields =
                    validator.hasOwnProperty('fields') &&
                    validator.fields.length
                        ? validator.fields
                        : [];

                break;
        }

        fields = fixArrayFields(fields, scope.index);

        const localInvalidFields = fields.filter((field) => {
            if (field == 'custom_colour') {
                return false;
            }

            const regs = field.match(/^(.+)\[(\d+)\]$/);

            if (regs) {
                field = regs[1];

                if (
                    !scope.hasOwnProperty(field) ||
                    !Array.isArray(scope[field])
                ) {
                    return true;
                }

                return !isFieldValid(scope[field][parseInt(regs[2]) - 1]);
            }

            if (!scope.hasOwnProperty(field)) {
                return true;
            }

            if (Array.isArray(scope[field])) {
                return scope[field].find((value) => !isFieldValid(value));
            }

            return !isFieldValid(scope[field]);
        });

        if (localInvalidFields.length == 0) {
            enabledValidators.push(validator);
        } else {
            invalidFields.push(...localInvalidFields);
        }
    });

    if (invalidFields.length > 0) {
        errors.push({
            message: 'Invalid field value',
            fields: invalidFields.filter((value, index, self) => {
                return self.indexOf(value) === index;
            }),
        });
    }

    enabledValidators.forEach((validator) => {
        if (validator.hasOwnProperty('index')) {
            scope.index = validator.index + 1; // Excel indexes start at 1
        } else {
            scope.index = 0;
        }

        switch (validator.type) {
            case 'panel':
                const material = validationData[validator.field];

                const hasHorizontalGrain = scope[validator.horizontal_grain];

                if (validator.width === '' || validator.height === '') {
                    return;
                }

                const panelWidth = excel.calculate(validator.width, scope);
                const panelHeight = excel.calculate(validator.height, scope);

                if (!material) {
                    return;
                }

                let validWidth = false;
                let validHeight = false;

                let materialWidthMax = material['maxWidth'];
                let materialHeightMax = material['maxHeight'];

                if (material.isGrained) {
                    if (hasHorizontalGrain == 1 || hasHorizontalGrain == true) {
                        materialWidthMax = material['maxHeight'];
                        materialHeightMax = material['maxWidth'];
                    }

                    validWidth = panelWidth <= materialWidthMax;
                    validHeight = panelHeight <= materialHeightMax;
                } else {
                    /* If the material isn't grained, then for it to not be valid, it won't fit
                     * _both_ ways, therefore both width and height fields are invalid
                     */
                    let valid =
                        panelWidth <= materialWidthMax &&
                        panelHeight <= materialHeightMax;

                    if (!valid) {
                        valid =
                            panelWidth <= materialHeightMax &&
                            panelHeight <= materialWidthMax;
                    }

                    validWidth = validHeight = valid;
                }

                if (!validWidth || !validHeight) {
                    const fields = [];

                    if (
                        !validHeight &&
                        validator.heightFields &&
                        validator.heightFields.length
                    ) {
                        fields.push(...validator.heightFields);
                    }

                    if (
                        !validWidth &&
                        validator.widthFields &&
                        validator.widthFields.length
                    ) {
                        fields.push(...validator.widthFields);
                    }

                    errors.push(formatError(validator.message, fields, scope));
                }

                break;
            case 'single':
                const result = excel.calculate(validator.rule, scope);

                if (!result) {
                    let fields = [];

                    if (validator.fields) {
                        fields = validator.fields;
                    }

                    const errorMessage =
                        intersection(fields, [
                            'cabinet_vert_shelves',
                            'cabinet_hori_shelves',
                        ]).length != 0
                            ? validator.message +
                              ' ' +
                              Math.floor(values.cabinet_width / 100, 1)
                            : validator.message;

                    errors.push(formatError(errorMessage, fields, scope));
                }

                break;
            case 'range':
                let minimum = null;

                if (
                    validator.hasOwnProperty('minimum') &&
                    validator.minimum !== null
                ) {
                    minimum = validator.minimum;
                }

                if (minimum !== null) {
                    minimum = excel.calculate(minimum, scope);
                }

                let maximum = null;

                if (
                    validator.hasOwnProperty('maximum') &&
                    validator.maximum !== null
                ) {
                    maximum = validator.maximum;
                }

                if (maximum !== null) {
                    maximum = excel.calculate(maximum, scope);
                }

                const value = excel.calculate(validator.value, scope);
                let message = '';

                if (minimum !== null && maximum !== null) {
                    if (value < minimum || value > maximum) {
                        message = `must be between ${minimum}mm and ${maximum}mm`; // TODO: Format sizes
                    }
                } else if (minimum !== null) {
                    if (value < minimum) {
                        message = `must be greater than ${minimum}mm`; // TODO: Format size
                    }
                } else if (maximum !== null) {
                    if (value > maximum) {
                        message = `must be less than ${maximum}mm`; // TODO: Format size
                    }
                }

                if (message != '') {
                    let fields = [];

                    if (validator.fields) {
                        fields = validator.fields;
                    }

                    errors.push(
                        formatError(
                            validator.hasOwnProperty('label')
                                ? validator.label + ' ' + message
                                : validator.message,
                            fields,
                            scope
                        )
                    );
                }
                break;
        }
    });

    // validate hinge hole
    if (
        values?.drillings &&
        Array.isArray(values.drillings) &&
        values.drillings.length > 0 &&
        values?.door_hang != 2
    ) {
        values.drillings.forEach((drilling, index) => {
            if (drilling.drilling_offset_y < 1) {
                errors.push({
                    message: `Distance from Top/Bottom must be greater than 0mm`,
                    fields: [`drillings[${index}].drilling_offset_y`],
                });
            }
        });
    }

    // validate upper_shelves
    if (
        values?.upper_shelves &&
        values?.shelvesHeightReference?.upper_shelves &&
        !values?.cabinet_simple_shelves
    ) {
        const upperShelvesHeightReference =
            values.shelvesHeightReference.upper_shelves;
        const invalidUpperShelves = values.upper_shelves.filter(
            (shelf) =>
                shelf['upper_shelf_position'] > upperShelvesHeightReference
        );

        if (invalidUpperShelves.length > 0) {
            const errorMessage = `Value cannot be greater than ${upperShelvesHeightReference}`;
            errors.push({
                message: errorMessage,
                fields: ['upper_shelf_position'],
            });
        }
    }

    // validate lower shelves
    if (
        values?.lower_shelves &&
        values?.shelvesHeightReference?.lower_shelves &&
        !values?.cabinet_simple_shelves
    ) {
        const shelvesHeightReference =
            values.shelvesHeightReference.lower_shelves;
        const invalidShelves = values.lower_shelves.filter(
            (shelf) => shelf['lower_shelf_position'] > shelvesHeightReference
        );

        if (invalidShelves.length > 0) {
            const errorMessage = `Value cannot be greater than ${shelvesHeightReference}`;
            errors.push({
                message: errorMessage,
                fields: ['lower_shelf_position'],
            });
        }
    }

    // validate shelves
    if (
        values?.shelves &&
        values?.shelvesHeightReference?.shelves &&
        !values?.cabinet_simple_shelves
    ) {
        const shelvesHeightReference = values.shelvesHeightReference.shelves;
        const invalidShelves = values.shelves.filter(
            (shelf) => shelf['shelf_position'] > shelvesHeightReference
        );

        if (invalidShelves.length > 0) {
            const errorMessage = `Value cannot be greater than ${shelvesHeightReference}`;
            errors.push({
                message: errorMessage,
                fields: ['shelf_position'],
            });
        }
    }

    // validate vertical_partition
    const verticalPartition = values?.vertical_partition;
    const verticalPartitionHeightReference =
        values?.shelvesHeightReference?.vertical_partition;

    if (
        verticalPartition &&
        verticalPartitionHeightReference &&
        !values?.cabinet_simple_shelves
    ) {
        const normalizedVerticalPartition =
            verticalPartition < 0
                ? verticalPartition * verticalPartitionHeightReference
                : verticalPartition;

        if (normalizedVerticalPartition > verticalPartitionHeightReference) {
            const errorMessage = `Vertical partition position value cannot be greater than ${verticalPartitionHeightReference}`;
            errors.push({
                message: errorMessage,
                fields: ['vertical_partition'],
            });
        }
    }

    if (values.hasOwnProperty('drawers') && Array.isArray(values.drawers)) {
        // drawer runner validation
        const errorFields = [];
        const drillOnlyErrorFields = [];
        values.drawers.forEach((drawer, index) => {
            if (!drawer.hasOwnProperty('drawer_runner_specs')) {
                return;
            }

            if (
                drawer.drawer_runner_specs == -1 ||
                drawer.drawer_runner_specs == undefined
            ) {
                errorFields.push(`drawer_runner_specs[${index + 1}]`);
            } else if (
                values.cabinet_include_hardware &&
                isDrillOnly(drawer.drawer_runner_specs)
            ) {
                drillOnlyErrorFields.push(`drawer_runner_specs[${index + 1}]`);
            }
        });

        if (errorFields.length) {
            errors.push({
                message: 'Please select valid runners for the drawers',
                fields: errorFields,
            });
        }
        if (drillOnlyErrorFields.length) {
            errors.push({
                message:
                    "This drawer runner hardware cannot be supplied as it's drill only.",
                fields: drillOnlyErrorFields,
            });
        }
    }

    // NOTE: Stick this validation to backend later
    if (
        validationData?.cabinet_door?.advanced &&
        values.hasOwnProperty('hori_amount') &&
        values.hori_amount > 0
    ) {
        if (
            values.hasOwnProperty('hori_mid_rail_positions') &&
            values.hori_mid_rail_positions.length > 0
        ) {
            const cabinetHeight = parseInt(
                isQFP ? values.height : values.cabinet_height
            );
            const railHeight = parseInt(values.hori_height);
            const borderWidthTop = parseInt(values.border_width_top);
            const borderWidthBottom = parseInt(values.border_width_bottom);
            const minDistanceTopBottom =
                validationData.cabinet_door.hasOwnProperty(
                    'minimumDistanceTopBottom'
                ) && validationData.cabinet_door.minimumDistanceTopBottom
                    ? parseInt(
                          validationData.cabinet_door.minimumDistanceTopBottom
                      )
                    : 50;

            const positionValidation = validatePositions(
                values.hori_mid_rail_positions
            );
            if (!positionValidation.validated) {
                positionValidation.errors.forEach((error) =>
                    errors.push(error)
                );
            }

            const heights = getPanelSizes(
                values.hori_mid_rail_positions,
                railHeight,
                borderWidthBottom,
                borderWidthTop,
                cabinetHeight
            );

            heights.forEach((height, index) => {
                if (height < minDistanceTopBottom) {
                    const fieldIndex =
                        index == heights.length - 1 ? index - 1 : index;

                    errors.push({
                        message: `There must be at least ${minDistanceTopBottom}mm distance between rails`,
                        fields: [`hori_mid_rail_positions[${fieldIndex}]`],
                    });
                }
            });
        }
    }

    if (
        validationData?.cabinet_door?.advanced &&
        values.hasOwnProperty('vert_amount') &&
        values.vert_amount > 0
    ) {
        if (
            values.hasOwnProperty('vert_mid_rail_positions') &&
            values.vert_mid_rail_positions.length > 0
        ) {
            const cabinetWidth = parseInt(
                isQFP ? values.width : values.cabinet_width
            );
            const railWidth = parseInt(values.vert_width);
            const borderWidthLeft = parseInt(values.border_width_left);
            const borderWidthRight = parseInt(values.border_width_right);
            const minimumDistanceLeftRight =
                validationData.cabinet_door.hasOwnProperty(
                    'minimumDistanceLeftRight'
                ) && validationData.cabinet_door.minimumDistanceLeftRight
                    ? parseInt(
                          validationData.cabinet_door.minimumDistanceLeftRight
                      )
                    : 50;

            const positionValidation = validatePositions(
                values.vert_mid_rail_positions,
                false
            );
            if (!positionValidation.validated) {
                positionValidation.errors.forEach((error) =>
                    errors.push(error)
                );
            }

            const widths = getPanelSizes(
                values.vert_mid_rail_positions,
                railWidth,
                borderWidthLeft,
                borderWidthRight,
                cabinetWidth
            );

            widths.forEach((width, index) => {
                if (width < minimumDistanceLeftRight) {
                    const fieldIndex =
                        index == widths.length - 1 ? index - 1 : index;

                    errors.push({
                        message: `There must be at least ${minimumDistanceLeftRight}mm distance between rails`,
                        fields: [`vert_mid_rail_positions[${fieldIndex}]`],
                    });
                }
            });
        }
    }

    if (values.shelves) {
        const depth = values?.cabinet_depth;
        values.shelves.forEach((shelf, index) => {
            if (shelf.shelf_offset < 0) {
                errors.push({
                    message: `Setback cannot be less than zero.`,
                    fields: [`shelf_offset[${index + 1}]`],
                });
            }

            if (shelf.shelf_offset > depth - 100) {
                errors.push({
                    message: `Setback cannot be greater than ${depth - 100}.`,
                    fields: [`shelf_offset[${index + 1}]`],
                });
            }
        });
    }

    return errors;
};

export const getPanelSizes = (
    positions,
    railSize,
    firstBorder,
    lastBorder,
    totalSize
) => {
    const sizes = [];
    let index = 0;
    while (sizes.length <= positions.length) {
        let size;
        if (sizes.length < positions.length) {
            const position = positions[Number(index)];

            size =
                position -
                railSize * 0.5 * (2 * index + 1) -
                firstBorder -
                sum(sizes);
        } else {
            size =
                totalSize -
                (sum(sizes) + firstBorder + lastBorder) -
                railSize * positions.length;
        }

        sizes.push(size);
        index++;
    }

    return sizes;
};

export const validatePositions = (positions, horizontal = true) => {
    let validated = true;
    const errors = [];
    const field = horizontal
        ? 'hori_mid_rail_positions'
        : 'vert_mid_rail_positions';

    positions.forEach((position, index) => {
        if (typeof position == 'undefined') {
            validated = false;
            errors.push({
                message: `Set a value for position ${index + 1}`,
                fields: [`${field}[${index}]`],
            });
            return;
        }

        if (index == 0) return;

        if (position <= positions[index - 1]) {
            validated = false;
            errors.push({
                message: `Position ${
                    index + 1
                } value must be greater than Position ${index}`,
                fields: [`${field}[${index}]`],
            });
            return;
        }
    });

    return {
        validated,
        errors,
    };
};

/**
 * Returns whether the field with the name specified is referenced by the error provided
 *
 * @param {Object} error Error to check
 * @param {string} fieldName Name of the field
 * @param {number|null} index Index of the field
 * @return {boolean} Whether the field is referenced by the error
 */
export const validationErrorAppliesToField = (
    error,
    fieldName,
    index = null
) => {
    if (!error.fields) {
        return false;
    }

    if (error.fields.includes(fieldName)) {
        return true;
    }

    if (index !== null) {
        return error.fields.includes(`${fieldName}[${index + 1}]`);
    }

    return false;
};
