import React from 'react';

import type {OptionsType, TableRowType} from 'shared/types';
import {
    withDragTarget,
    DragProps,
} from 'shared/components/DragAndDrop/withDragTarget';

interface RowProps<T> extends DragProps {
    children: React.ReactNode;
    options: OptionsType<T>;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    className: string;
}

const Row = <T,>({
    children,
    options,
    onClick,
    className,
    isDragging,
    dragRef,
}: RowProps<T>) => {
    if (typeof options.reorder === 'undefined') {
        return (
            <tr onClick={onClick} className={className}>
                {children}
            </tr>
        );
    }

    const opacity = isDragging ? 0.5 : 1;

    return (
        <tr
            ref={dragRef}
            style={{
                cursor: 'grab',
                opacity,
            }}
            onClick={onClick}
            className={className}>
            {children}
        </tr>
    );
};

export const DndRow = withDragTarget<TableRowType, RowProps<TableRowType>>(Row);

export default Row;
