import React from 'react';
import {formatPrice} from 'shared/helpers';
import {useListBenchesQuery} from 'components/customer/BTM/store/btmApi';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {TableRow, TableCell} from './VariationCostMoreInfo';

const BenchtopVariationCost = ({jobId}: {jobId: number}) => {
    const {data: {data: benches} = {data: [] as Bench[]}} = useListBenchesQuery(
        {
            jobId,
        },
        {
            skip: typeof jobId == 'undefined' || jobId == null,
        }
    );

    return (
        <>
            {benches &&
                benches.map((bench, index) => {
                    if (bench.variation_cost) {
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <strong>
                                        {`${bench.job_benchtop_number} ${bench.shape.name} Benchtop:`}
                                    </strong>
                                </TableCell>
                                <TableCell style={{color: 'rgb(255, 0, 0)'}}>
                                    {bench.comment}
                                </TableCell>
                                <TableCell $align="right">
                                    {formatPrice(bench.variation_cost, {})}
                                </TableCell>
                            </TableRow>
                        );
                    }
                })}
        </>
    );
};

export default BenchtopVariationCost;
