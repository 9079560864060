import {useLayoutEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {
    getUI,
    UISliceInterface,
    setIsSmallScreenLayout,
    SidebarStatus,
    SidebarBehaviour as SidebarBehaviourType,
} from 'store/customer/UISlice';
import {useSidebarBehaviour, SidebarBehaviour} from 'hooks';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

const STANDARD_SIZE = 1440;

const useUI = (): SidebarBehaviour & {
    showSidebarOverlays: boolean;
    isSmallScreenLayout: boolean;
} => {
    const dispatch = useAppDispatch();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);
    const smallScreenLayout = ui.isSmallScreenLayout;
    const isSmallDevice = isDeviceSmall();

    useLayoutEffect(() => {
        const availableWidth: number = document.body.clientWidth;

        if (!isSmallDevice && availableWidth <= STANDARD_SIZE) {
            dispatch(setIsSmallScreenLayout(true));
        }
    }, [isSmallDevice]);

    const {
        isCartToggleVisible,
        alternateCartContent,
        showMainCartContent,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        leftSidebar,
        rightSidebar,
        rightSidebarSoftOpen,
        setIsCartToggleVisible,
        setAlternateCartContent,
        setShowMainCartContent,
        overrideLeftSidebar,
        overrideRightSidebar,
        setLeftSidebar,
        setRightSidebar,
        restoreLeftSidebar,
        restoreRightSidebar,
        setLeftSidebarBehaviour,
        setRightSidebarBehaviour,
        overrideLeftSidebarBehaviour,
        overrideRightSidebarBehaviour,
        restoreLeftSidebarBehaviour,
        restoreRightSidebarBehaviour,
        setRightSidebarSoftOpen,
        setRightSmallScreenBehaviour,
        setRightSmallScreenStatus,
    } = useSidebarBehaviour(smallScreenLayout);

    return {
        isCartToggleVisible,
        alternateCartContent,
        showMainCartContent,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        leftSidebar,
        rightSidebar,
        rightSidebarSoftOpen,
        setIsCartToggleVisible,
        setAlternateCartContent,
        setShowMainCartContent,
        overrideLeftSidebar,
        overrideRightSidebar,
        setLeftSidebar,
        setRightSidebar,
        restoreLeftSidebar,
        restoreRightSidebar,
        setLeftSidebarBehaviour: (value: SidebarBehaviourType) => {
            if (
                value === SidebarBehaviourType.INVISIBLE ||
                value === SidebarBehaviourType.AUTOHIDE
            )
                setLeftSidebar(SidebarStatus.CLOSED);

            setLeftSidebarBehaviour(value);
        },
        setRightSidebarBehaviour: (value: SidebarBehaviourType) => {
            if (
                value === SidebarBehaviourType.INVISIBLE ||
                value === SidebarBehaviourType.AUTOHIDE
            )
                setRightSidebar(SidebarStatus.CLOSED);

            setRightSidebarBehaviour(value);
        },
        overrideLeftSidebarBehaviour: (value) => {
            overrideLeftSidebarBehaviour(value);
            if (
                value === SidebarBehaviourType.AUTOHIDE ||
                value === SidebarBehaviourType.INVISIBLE
            ) {
                overrideLeftSidebar(SidebarStatus.CLOSED);
            }
        },
        overrideRightSidebarBehaviour: (value) => {
            overrideRightSidebarBehaviour(value);
            if (
                value === SidebarBehaviourType.AUTOHIDE ||
                value === SidebarBehaviourType.INVISIBLE
            )
                overrideRightSidebar(SidebarStatus.CLOSED);
        },
        restoreLeftSidebarBehaviour,
        restoreRightSidebarBehaviour,
        setRightSidebarSoftOpen,
        showSidebarOverlays: ui.showSidebarOverlays,
        isSmallScreenLayout: smallScreenLayout,
        setRightSmallScreenBehaviour,
        setRightSmallScreenStatus,
    };
};

export default useUI;
