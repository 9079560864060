import React, {forwardRef} from 'react';

interface DropdownMenuProps {
    children: JSX.Element;
    style?: React.CSSProperties;
    className?: string;
    'aria-labelledby'?: string;
}

const DropdownMenu = forwardRef(
    (
        {
            children,
            style,
            className,
            'aria-labelledby': labeledBy,
        }: DropdownMenuProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        delete style.opacity;

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}>
                {children}
            </div>
        );
    }
);
DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
