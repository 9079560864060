import React, {useReducer} from 'react';
import {PromptButton} from 'shared';

import type {TableOptionType, TableRowType} from 'shared/types';
import DropdownOption from './DropdownOption';
import styled from 'styled-components';

type MobileOptionsType = {
    colspan: number;
    options: TableOptionType;
    row: TableRowType;
    isOptionsMenuOpen: boolean;
    className: string;
};

const MobileOptions = ({
    colspan,
    options,
    row,
    isOptionsMenuOpen,
    className,
}: MobileOptionsType): JSX.Element => {
    const [icons, setIcons] = useReducer(
        (state: string[], action: {index: number; payload: string}) => {
            if (typeof state[action.index] !== 'undefined') {
                const updatedState = [...state];

                updatedState[action.index] = action.payload;

                return updatedState;
            }

            return state;
        },
        [],
        () => {
            if (options.hasOwnProperty('buttons')) {
                return options.buttons.map((button) => button.iconName);
            }

            return [];
        }
    );

    if (isOptionsMenuOpen) {
        return (
            <tr className={`mobile-menu-table ${className}`}>
                <td colSpan={colspan}>
                    <MobileMenuWrapper>
                        {options.buttons.map((button, index) => {
                            if (
                                typeof button.isHidden !== 'undefined' &&
                                button.isHidden(row)
                            )
                                return <i key={index} />;

                            if (
                                button.hasOwnProperty('dropdown') &&
                                button.dropdown.length
                            ) {
                                return (
                                    <DropdownOption
                                        row={row}
                                        key={index}
                                        button={button}
                                    />
                                );
                            } else {
                                return (
                                    <PromptButton
                                        key={index}
                                        iconName={icons[index]}
                                        action={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) =>
                                            button.action(
                                                row,
                                                e,
                                                icons[index],
                                                (icon) => {
                                                    setIcons({
                                                        index,
                                                        payload: icon,
                                                    });
                                                }
                                            )
                                        }
                                        showConfirmation={button.confirmChanges}
                                        className={
                                            options.buttons.length === 1
                                                ? 'mobile-lone-button'
                                                : ''
                                        }
                                    />
                                );
                            }
                        })}
                    </MobileMenuWrapper>
                </td>
            </tr>
        );
    } else {
        return <></>;
    }
};

const MobileMenuWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export default MobileOptions;
