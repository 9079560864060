import React, {useEffect} from 'react';
import {useTableAPI} from 'hooks';
import Pagination from './Pagination';
import TableHead from './TableHead';
import TableBody from './TableBody';

import type {OptionsType} from 'shared/types';

const TableWithAPI = ({
    options,
    ...props
}: {
    options: OptionsType<any>;
}): JSX.Element => {
    const {records, pagination, setPage} = useTableAPI(options);

    useEffect(() => {
        if (
            typeof options.after !== 'undefined' &&
            typeof records !== 'undefined'
        ) {
            options.after(records);
        }
    }, [records]);

    return (
        <>
            <table
                {...props}
                className={
                    options.hasCursor
                        ? 'table cbc-table-cursor'
                        : 'table cbc-table'
                }
                cellSpacing={
                    options.hasOwnProperty('cellSpacing')
                        ? options.cellSpacing
                        : 0
                }>
                <TableHead fields={options.fields} options={options.options} />
                <TableBody
                    fields={options.fields}
                    data={records}
                    options={options}
                />
            </table>
            {typeof options.pagination !== 'undefined' &&
            typeof records !== 'undefined' &&
            records.length > 0 ? (
                <Pagination pagination={pagination} setPage={setPage} />
            ) : (
                <></>
            )}
        </>
    );
};

export default TableWithAPI;
