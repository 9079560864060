import React, {useCallback, useMemo} from 'react';

import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import {StyledPicker} from 'components/customer/Job/Job';
import {Button} from 'react-bootstrap';
import {ErrorMessage, Formik, FormikProps, Form} from 'formik';
import {useJobForm} from 'hooks';
import {object, string} from 'yup';
import {useParams} from 'react-router-dom';
import {useUpdateRequestedDeliveryDateMutation} from 'components/customer/Job/store/jobApi';
import {useAppContext, useJobContext} from 'contexts';
import {DateTime, Format} from '@cabinetsbycomputer/datetime';
import {PartialJob} from 'shared/types/PartialJob';
import {RequestedDeliveryDateFrequencyEnum as RDD} from 'components/manufacturer/Preferences/entity/Preferences';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {Event} from 'shared/events/Event';

import 'react-datepicker/dist/react-datepicker.css';

const validationSchema = object().shape({
    requestedDeliveryDate: string().required(
        'Please select a requested delivery date.'
    ),
});

export const useRequestedDeliveryDateHandler = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void,
    isDashboard = false
): Event => {
    const {job} = useJobContext() as PartialJob;
    const {userProfile} = useAppContext();
    const {jobId} = useParams();
    const {minimumLeadTime = 0, allowDeliveryDateRequest} = useJobForm(!!jobId);
    const [updateJob, {isLoading}] = useUpdateRequestedDeliveryDateMutation();

    const initialValues = {
        requestedDeliveryDate: '',
    };

    const disablePrompt = useMemo(() => {
        if (isDashboard) {
            // prompt only when JOB_EDIT_AND_SUBMISSION, otherwise skip
            return [RDD.NEVER, RDD.JOB_SUBMISSION].includes(
                userProfile?.requestedDeliveryDateFrequency
            );
        } else {
            // prompt on either JOB_EDIT_AND_SUBMISSION OR JOB_SUBMISSION, otherwise skip
            return userProfile?.requestedDeliveryDateFrequency === RDD.NEVER;
        }
    }, [userProfile, isDashboard]);

    const requestedDeliveryDate = useMemo(
        () => job.requestedDeliveryDate,
        [job]
    );

    const isDateExpired = useMemo(() => {
        if (
            requestedDeliveryDate === undefined ||
            requestedDeliveryDate === '' ||
            requestedDeliveryDate === null
        ) {
            return false;
        }

        const minLeadTime = DateTime.startOf('day').add({
            days: minimumLeadTime,
        });
        const deliveryDate = DateTime.parse(requestedDeliveryDate);

        const {days} = deliveryDate.diff(minLeadTime.get(), 'days');
        return allowDeliveryDateRequest && days < 0;
    }, [minimumLeadTime, allowDeliveryDateRequest, requestedDeliveryDate]);

    const submitHandler = useCallback(
        (resolve: (value: boolean) => void) =>
            ({requestedDeliveryDate}: typeof initialValues) => {
                void updateJob({
                    jobId: Number(jobId) - 10000,
                    requestedDeliveryDate,
                })
                    .unwrap()
                    .then(() => {
                        hideDialog();
                        resolve(true);
                    });
            },
        [hideDialog, jobId, updateJob]
    );

    const action = useCallback((): Promise<boolean | void> | boolean => {
        if (disablePrompt) {
            return true;
        } else {
            if (isDateExpired) {
                return new Promise((resolve) => {
                    showDialog({
                        title: 'Select a new Requested Delivery Date',
                        message: (
                            <>
                                <div>
                                    The Requested Delivery Date for your job is
                                    no longer available, please update to
                                    continue with your order.
                                </div>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={submitHandler(resolve)}>
                                    {({
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                    }: FormikProps<typeof initialValues>) => {
                                        const handleDateChange = useCallback(
                                            (date: Date) => {
                                                void setFieldValue(
                                                    'requestedDeliveryDate',
                                                    DateTime.fromDate(date)
                                                        .format(Format.Date)
                                                        .toString()
                                                );
                                            },
                                            []
                                        );
                                        const preventDefault = useCallback(
                                            (
                                                event: React.KeyboardEvent<HTMLDivElement>
                                            ) => event.preventDefault(),
                                            []
                                        );
                                        return (
                                            <Form
                                                id="cbc-job-form"
                                                noValidate
                                                style={{padding: '20px 0 5px'}}
                                                className="cbc-form">
                                                <div className="form-group">
                                                    <label
                                                        style={{
                                                            fontSize: '0.85rem',
                                                        }}>
                                                        Requested Delivery Date:
                                                        <span>*</span>
                                                    </label>
                                                    <StyledPicker>
                                                        <DatePicker
                                                            showIcon
                                                            className={`form-control${
                                                                touched.requestedDeliveryDate &&
                                                                errors.requestedDeliveryDate
                                                                    ? ' is-invalid'
                                                                    : ''
                                                            }`}
                                                            wrapperClassName="full-width"
                                                            dateFormat="dd MMM yyyy"
                                                            selected={
                                                                (values.requestedDeliveryDate &&
                                                                    DateTime.parseCustom(
                                                                        values.requestedDeliveryDate
                                                                    ).get()) ||
                                                                null
                                                            }
                                                            placeholderText="Select a requested delivery date"
                                                            minDate={DateTime.now()
                                                                .add({
                                                                    days: minimumLeadTime,
                                                                })
                                                                .get()}
                                                            onChange={
                                                                handleDateChange
                                                            }
                                                            onKeyDown={
                                                                preventDefault
                                                            }
                                                            toggleCalendarOnIconClick
                                                        />
                                                    </StyledPicker>
                                                </div>
                                                <ErrorMessage
                                                    name="requestedDeliveryDate"
                                                    component={Error}
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                    }}>
                                                    <SubmitButton
                                                        disabled={isLoading}
                                                        type="submit"
                                                        className="button-blue btn-link">
                                                        Submit
                                                    </SubmitButton>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </>
                        ),
                        hideYesButton: true,
                        hideNoButton: true,
                        hideFooter: true,
                    });
                });
            } else {
                return true;
            }
        }
    }, [isDateExpired, disablePrompt]);

    return {
        action,
    };
};

const Error = styled.div`
    color: red;
    margin-top: -10px;
`;

const SubmitButton = styled(Button)`
    font-size: 0.85rem;
    width: 85px;
    padding: 6px 12px;
    justify-content: center;
`;
