import {
    MinimumUsage,
    useGetJobCostQuery,
} from 'components/customer/Job/store/jobApi';
import {useJobContext} from 'contexts';
import React, {useMemo} from 'react';
import {MoreInfo} from 'shared';
import {formatPrice} from 'shared/helpers';
import MinimumUsageMoreInfo from './MinimumUsageMoreInfo';
import {
    transformDisplayName,
    transformDisplayNameEdge,
} from 'components/customer/Materials/helper';
import {useListBenchesQuery} from 'components/customer/BTM/store/btmApi';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {mapDisplayName} from 'components/customer/BTM/helper/materialMappers';
import {mapMaterialEdge} from 'components/customer/Materials/helper/edgeHelper';
import {PartialJob} from 'shared/types/PartialJob';

type ExtMaterialType = {
    id: number;
};

export const MinimumUsageFee = () => {
    const {job} = useJobContext() as PartialJob;
    const jobId = job.id;

    const {data: cost} = useGetJobCostQuery(
        {jobId},
        {
            skip: typeof job?.id === 'undefined',
        }
    );
    const {data: {data: benches} = {data: [] as Bench[]}} = useListBenchesQuery(
        {
            jobId,
        },
        {
            skip: typeof jobId == 'undefined' || jobId == null,
        }
    );

    const mapEdgeDisplayName = (edgeId: number) => {
        const roomEdge = job?.rooms
            ? job.rooms.flatMap((room) =>
                  room?.jobCabinets?.flatMap(
                      (cabinetValue) => cabinetValue?.extEdge
                  )
              )
            : [];

        const edge = roomEdge?.find((m) => m?.id == edgeId);

        if (!edge) return null;
        return transformDisplayNameEdge(mapMaterialEdge(edge))?.displayName;
    };

    const mapMaterialDisplayName = (materialId: number) => {
        const roomMaterials = job?.rooms
            ? job.rooms.flatMap((room) =>
                  room?.jobCabinets?.flatMap(
                      (cabinetValue) => cabinetValue?.extMaterial
                  )
              )
            : [];

        let material = roomMaterials?.find(
            (m: ExtMaterialType) => m?.id == materialId
        );

        if (!material) {
            const roomMaterialsCarc = job?.rooms
                ? job.rooms.flatMap((room) =>
                      room?.jobCabinets.flatMap(
                          (cabinetValue) => cabinetValue?.carcMaterial
                      )
                  )
                : [];

            material = roomMaterialsCarc?.find(
                (m: ExtMaterialType) => m?.id == materialId
            );
        }

        if (benches && !material) {
            material = benches?.find(
                (m) => m?.material_id == materialId
            )?.material;

            if (material) {
                const benchTopMaterial = mapDisplayName({...material});
                return benchTopMaterial?.displayName;
            }
        }

        if (!material) return mapEdgeDisplayName(materialId);

        const materialTransformed = transformDisplayName({
            ...(material ? material : {}),
            brand: {name: material?.brandName},
        });

        return materialTransformed?.displayName;
    };

    const moreInfoData = useMemo(() => {
        if (!cost) {
            return {filteredMinUsage: [], filteredMinUsageEdge: []};
        }

        const filteredMinUsage = cost.minUsage
            ? cost.minUsage.filter(
                  (min) => (min.areaCost || min.lengthCost > 0) && min.cost > 0
              )
            : [];

        const filteredMinUsageEdge = cost.minUsageEdge
            ? cost.minUsageEdge.filter(
                  (minEdge) =>
                      (minEdge.areaCost || minEdge.lengthCost > 0) &&
                      minEdge.cost > 0
              )
            : [];

        return {
            filteredMinUsage,
            filteredMinUsageEdge,
        };
    }, [cost]) as {
        filteredMinUsage: MinimumUsage[];
        filteredMinUsageEdge: MinimumUsage[];
    };

    if (job.manufacturerEditMinMatUsage) {
        if (cost.totalMinUsage > 0) {
            // manufacturer edit the min usage and set it to a custom value
            return (
                <li>
                    <span style={{display: 'flex'}}>Minimum Usage Fee: </span>
                    <span>{formatPrice(cost.totalMinUsage, {})}</span>
                </li>
            );
        }
        return null;
    }
    if (
        moreInfoData.filteredMinUsage.length === 0 &&
        moreInfoData.filteredMinUsageEdge.length === 0
    ) {
        return null;
    }

    return (
        <li>
            <span style={{display: 'flex'}}>
                Minimum Usage Fee:{' '}
                <MoreInfo
                    minUsage={true}
                    editbuttonVisible={false}
                    hoverText="Minimum Usage Fee"
                    dialogClassName="modal-md"
                    info={
                        <MinimumUsageMoreInfo
                            minimumUsages={moreInfoData}
                            mapMaterialDisplayName={mapMaterialDisplayName}
                        />
                    }
                />
            </span>

            <span>{formatPrice(cost.totalMinUsage, {})}</span>
        </li>
    );
};
