import React from 'react';
import styled from 'styled-components';
import {useJobContext} from 'contexts';
import {formatPrice} from 'shared/helpers';
import BenchtopVariationCostMoreInfo from './BenchtopVariationCostMoreInfo';
import {PartialJob} from 'shared/types/PartialJob';
import JobExtraVariationCostMoreInfo from 'shared/components/Header/JobExtraVariationCostMoreInfo';

type RoomParams = {
    jobCabinets: {
        variationCost: number;
        typeName: number;
        comment: string;
    }[];
};

export const Table = styled.table<{$noBorder?: boolean}>`
    width: 100%;
    border-bottom: ${({$noBorder}) =>
        $noBorder ? 'none' : '2px solid #e0e0e0'};
`;

export const TableRow = styled.tr<{$isEven?: boolean}>`
    ${({$isEven}) => ($isEven ? 'background: #f5f5f5;' : '')};
`;

export const TableHead = styled.th<{$align?: string}>`
    padding: 5px 8px;
    text-wrap: wrap;
    text-align: ${({$align}) => ($align ? $align : 'center')};
    font-weight: 600;
    border-bottom: 2px solid #e0e0e0;
`;

export const TableCell = styled.td<{$align?: string}>`
    padding: 5px 8px;
    text-wrap: wrap;
    text-align: ${({$align}) => ($align ? $align : 'left')};
`;

const TotalWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
`;

const Grow = styled.div`
    flex-grow: 1;
`;

const Total = styled.div`
    text-align: right;
`;

const VariationCostMoreInfo = () => {
    const {job} = useJobContext() as PartialJob;

    return (
        <>
            <Table>
                {job.rooms &&
                    job.rooms.map((value: RoomParams, key: number) => {
                        return value.jobCabinets
                            .filter(
                                (cabinetValue) => cabinetValue.variationCost > 0
                            )
                            .map((cabinetValue, cabinetKey: number) => (
                                <TableRow $isEven={cabinetKey % 2 === 0}>
                                    <TableCell>
                                        <strong>
                                            {key + 1 + '-' + (cabinetKey + 1)}{' '}
                                            {cabinetValue.typeName}
                                        </strong>
                                    </TableCell>
                                    <TableCell
                                        style={{color: 'rgb(255, 0, 0)'}}>
                                        {cabinetValue.comment}
                                    </TableCell>
                                    <TableCell $align="right">
                                        {formatPrice(
                                            cabinetValue.variationCost,
                                            job
                                        )}
                                    </TableCell>
                                </TableRow>
                            ));
                    })}

                <BenchtopVariationCostMoreInfo jobId={job.id} />
                <JobExtraVariationCostMoreInfo jobId={job.id} />
                {job.variationCost ? (
                    <TableRow>
                        <TableCell>
                            <strong>Extra Job Variation Cost:</strong>
                        </TableCell>
                        <TableCell />
                        <TableCell $align="right">
                            {formatPrice(job.variationCost, job)}
                        </TableCell>
                    </TableRow>
                ) : null}
            </Table>
            <TotalWrapper>
                <Grow />
                <Total>
                    <strong style={{marginRight: 5}}>TOTAL:</strong>{' '}
                    {formatPrice(job.totalVariationCost, job)}
                </Total>
            </TotalWrapper>
        </>
    );
};

export default VariationCostMoreInfo;
