import React, {useCallback, useMemo} from 'react';
import {Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Button} from 'react-bootstrap';

interface WizardNavigationProps {
    currentTab: number;
    setCurrentTab: (currentTab: number, previousTab: number) => void;
    max: number;
    dots: number | number[];
    disableNext?: boolean;
    wizardHeaderRef?: React.MutableRefObject<HTMLDivElement> | null;
    className?: string;
}

export const WizardNavigation = ({
    currentTab,
    setCurrentTab,
    max,
    dots,
    disableNext = false,
    wizardHeaderRef = null,
    className = '',
}: WizardNavigationProps) => {
    const isSmallDevice = isDeviceSmall();

    const scrollToHeader = useCallback(() => {
        if (isSmallDevice && wizardHeaderRef && wizardHeaderRef.current) {
            wizardHeaderRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [isSmallDevice]);

    const previous = useCallback(() => {
        setCurrentTab(currentTab - 1, currentTab);
        scrollToHeader();
    }, [currentTab, setCurrentTab, scrollToHeader]);

    const next = useCallback(() => {
        setCurrentTab(currentTab + 1, currentTab);
        scrollToHeader();
    }, [currentTab, setCurrentTab, scrollToHeader]);

    const dotsArray = useMemo(() => {
        if (Array.isArray(dots)) return dots;

        if (!Array.isArray(dots) && !isNaN(dots)) {
            return Array(dots)
                .fill(null)
                .map((u, i) => i);
        }

        return [];
    }, [dots]);

    return (
        <div className={`cbc-wizard-navigation ${className}`}>
            <div className="previous">
                {currentTab > 0 ? (
                    <Button onClick={previous}>
                        <Icon iconName="Button-Previous.svg" /> Previous
                    </Button>
                ) : null}
            </div>

            {isSmallDevice && typeof dots !== 'undefined' ? (
                <div className="dots">
                    {dotsArray
                        ? dotsArray.map((dot) => {
                              return (
                                  <div
                                      key={dot}
                                      className={
                                          dot === currentTab
                                              ? 'active dot'
                                              : 'dot'
                                      }
                                  />
                              );
                          })
                        : null}
                </div>
            ) : null}

            <div className="next">
                {currentTab < max ? (
                    <Button disabled={disableNext} onClick={next}>
                        Next <Icon iconName="Button-Next.svg" />{' '}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
