import React from 'react';
import {Dropdown} from 'react-bootstrap';
import styled from 'styled-components';
import {DropdownOption} from 'shared/components/Forms/Dropdown';

type dropdownListDefaultType = {
    children: string | JSX.Element;
    active: boolean;
    disabled?: boolean;
    onSelect?: (option: DropdownOption) => void;
};

const DropdownListDefault = ({
    children,
    active,
    disabled = false,
    onSelect,
    ...props
}: dropdownListDefaultType): JSX.Element => {
    return (
        <DropdownItem
            active={active}
            disabled={disabled}
            onSelect={onSelect}
            {...props}>
            {children}
        </DropdownItem>
    );
};

const DropdownItem = styled(Dropdown.Item)<{disabled: boolean}>`
    ${({disabled}) => disabled && `opacity: 0.6`};
`;

export default DropdownListDefault;
