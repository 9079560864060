import {useCallback, useEffect, useRef, useState} from 'react';

const fetchVersion = async () => {
    const response = await fetch(`/v2/version?v=${Date.now()}`);
    const data = await response.text();
    return data;
};

interface usePollVersionProps {
    pollingInterval: number;
    skip: boolean;
}

export const usePollVersion = ({
    pollingInterval,
    skip,
}: usePollVersionProps) => {
    const [version, setVersion] = useState<string>();
    const timeoutId = useRef<number | null>(null);

    const poll = useCallback(async () => {
        const version = await fetchVersion();
        setVersion(version);
    }, []);

    const clearTimer = useCallback(() => {
        if (timeoutId.current !== null) {
            window.clearTimeout(timeoutId.current);
        }
    }, []);

    useEffect(() => {
        clearTimer();

        if (!skip) {
            timeoutId.current = window.setInterval(() => {
                if (!skip) {
                    void poll();
                }
            }, pollingInterval);
        }

        return () => {
            clearTimer();
        };
    }, [skip]);

    return {
        version,
    };
};
