import React from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

/**
 * Wraps it's children with a div with it's properties if we're on a small device
 *
 * @return {ReactNode}
 */
export const MobileWrapper = ({
    children,
    ...props
}: JSX.IntrinsicElements['div']) => {
    if (!isDeviceSmall()) {
        return children;
    }

    if (!children) {
        return null;
    }

    if (Object.keys(props).length == 0) {
        props.className = 'mobile-buttons';
    }

    return <div {...props}>{children}</div>;
};
