import React, {useCallback, useMemo} from 'react';
import {Link as OGLink, LinkProps, useLocation} from 'react-router-dom';
import {useAppSelector} from 'store/customer';
import {selectReloadScript} from 'store/customer/appSlice';

interface LinkPropsWithReload extends LinkProps {
    className?: string;
    activeClassName?: string;
}

export const Link = (props: LinkPropsWithReload) => {
    const reloadScript = useAppSelector(selectReloadScript);
    const location = useLocation();
    const {to, onClick, className, activeClassName} = props;

    const classNames = useMemo(() => {
        const classes = [className || ''];

        if (activeClassName && location.pathname.includes(String(to))) {
            classes.push(activeClassName);
        }

        return classes.join(' ');
    }, [location, to, className, activeClassName]);

    const onClickHandler = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            // calling this function regardless what happens
            if (onClick && typeof onClick === 'function') {
                onClick(event);

                if (event.defaultPrevented) {
                    return;
                }
            }

            if (!reloadScript) {
                return;
            }

            event.preventDefault();

            // force reload the page, which will fetch new scripts
            window.location.href = to as string;
        },
        [to, onClick, reloadScript]
    );

    return (
        <OGLink {...props} className={classNames} onClick={onClickHandler} />
    );
};
