// @flow
import {IDBHandler, CacheHandler, HTTPHandler} from 'shared';
import type {Handler} from './Handler';
/**
 *
 *
 * @export
 * @class APIRequestBuilder
 */
export default class HandlerBuilder<T> {
    static cache: Map<string, HandlerBuilder<any>> = new Map();

    url: string;
    storeName: string;
    resultName: string = 'data';
    index: string;
    search: any;
    resetCache: boolean = false;
    memoryCache: boolean = true;

    objectProcessor: (object: Object) => T;
    idbHandler: IDBHandler<T>;
    cacheHandler: CacheHandler<T>;
    httpHandler: HTTPHandler<T>;

    /**
     *
     *
     * @param {string} url
     * @return {*}  {HandlerBuilder<T>}
     * @memberof HandlerBuilder
     */
    static createInstance<T>(url: string): HandlerBuilder<T> {
        if (HandlerBuilder.cache.has(url)) {
            const object = HandlerBuilder.cache.get(url);

            if (typeof object !== 'undefined') {
                return object;
            }
        }

        const handlerBuilder = new HandlerBuilder<T>(url);
        HandlerBuilder.cache.set(url, handlerBuilder);
        return handlerBuilder;
    }

    /**
     * Creates an instance of HandlerBuilder.
     * @param {string} url
     * @memberof HandlerBuilder
     */
    constructor(url: string) {
        this.url = url;
    }

    /**
     *
     *
     * @param {ObjectProcessor} objectProcessor
     * @return {*}  {APIRequestBuilder<T>}
     * @memberof APIRequestBuilder
     */
    setObjectProcessor(
        objectProcessor: (object: Object) => T
    ): HandlerBuilder<T> {
        this.objectProcessor = objectProcessor;

        return this;
    }

    /**
     *
     *
     * @param {string} storeName
     * @return {*}  {APIRequestBuilder<T>}
     * @memberof APIRequestBuilder
     */
    setStoreName(storeName: string): HandlerBuilder<T> {
        this.storeName = storeName;

        return this;
    }

    /**
     *
     *
     * @param {string} resultName
     * @return {*}  {APIRequestBuilder<T>}
     * @memberof APIRequestBuilder
     */
    setResultName(resultName: string): HandlerBuilder<T> {
        this.resultName = resultName;

        return this;
    }

    /**
     *
     * @param {*} search
     * @param {string} index
     * @return {*}  {Handler<T>}
     * @memberof IDBHandler
     */
    setIndex(search: any, index?: string): HandlerBuilder<T> {
        if (index) {
            this.index = index;
        }
        this.search = search;

        return this;
    }

    /**
     *
     * @param {boolean} bypass
     * @return {*}  {Handler<T>}
     * @memberof IDBHandler
     */
    setResetCache(bypass: boolean): HandlerBuilder<T> {
        this.resetCache = bypass;

        return this;
    }

    /**
     *
     *
     * @param {boolean} memoryCache
     * @return {*}  {HandlerBuilder<T>}
     * @memberof HandlerBuilder
     */
    setMemoryCache(memoryCache: boolean): HandlerBuilder<T> {
        this.memoryCache = memoryCache;

        return this;
    }

    /**
     *
     *
     * @return {*}  {Handler<T>}
     * @memberof APIRequestBuilder
     */
    build(): Handler<T> {
        if (this.url) {
            this.httpHandler = new HTTPHandler<T>(
                this.url,
                this.objectProcessor,
                this.resultName
            );

            if (this.memoryCache) {
                this.cacheHandler = new CacheHandler<T>(
                    this.url,
                    this.objectProcessor
                );

                if (this.resetCache) {
                    this.cacheHandler.setResetCache(true);
                }

                this.cacheHandler.addHandler(this.httpHandler);
            }
        }

        if (this.storeName) {
            this.idbHandler = new IDBHandler<T>(
                this.storeName,
                this.objectProcessor
            );

            this.idbHandler.setIndex(this.search, this.index);

            if (this.resetCache) {
                this.idbHandler.setResetCache(true);
            }

            if (this.url) {
                if (this.memoryCache) {
                    this.idbHandler.addHandler(this.cacheHandler);
                } else {
                    this.idbHandler.addHandler(this.httpHandler);
                }
            }
        }

        if (this.url && this.storeName) {
            return this.idbHandler;
        } else if (this.url) {
            return this.cacheHandler;
        }

        throw new Error('No Handlers were attached');
    }
}
