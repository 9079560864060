import React, {useState, useEffect, useCallback} from 'react';
import {Spinner} from 'react-bootstrap';
import TableRow from './TableRow';
import type {OptionsType, FieldType, TableRowType} from 'shared/types';
import useAutoScroll from 'hooks/AutoScroll';
import {
    DropProps,
    withDropTarget,
} from 'shared/components/DragAndDrop/withDropTarget';

interface TBodyType<T> extends DropProps<TableRowType> {
    fields: FieldType<T>[];
    options: OptionsType<T>;
    data?: TableRowType[];
    focusRowIndex?: Parameters<typeof useAutoScroll>[0];
}

const TableBody = <T extends TableRowType>({
    fields,
    data,
    options,
    focusRowIndex = null,
    hasBody,
    isDnD,
    findItem,
    moveItem,
}: TBodyType<T>) => {
    const [colspan, setColspan] = useState<number>(0);
    const {setRef} = useAutoScroll(focusRowIndex, data?.length);

    useEffect(() => {
        let span = fields.length;

        if (typeof options.options !== 'undefined') {
            span += 1;
        }

        setColspan(span);
    }, [fields]);

    const referenceHandler = useCallback(
        (index: number) => (el: HTMLTableRowElement) => setRef(el, index),
        [setRef]
    );

    let bodyContent = (
        <tr>
            <td colSpan={colspan}>
                <section className="loading-screen loading-absolute">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </section>
            </td>
        </tr>
    );

    if (typeof data !== 'undefined' && colspan !== 0) {
        if (data.length > 0) {
            bodyContent = (
                <>
                    {data.map((row, index) => {
                        let showRow = true;
                        if (options.filter) {
                            options.filter.forEach((condition) => {
                                if (
                                    row.hasOwnProperty(condition.field) &&
                                    row[condition.field] !== condition.value
                                ) {
                                    showRow = false;
                                }
                            });
                        }

                        if (!showRow) {
                            return null;
                        }

                        return (
                            <TableRow
                                key={index}
                                ref={referenceHandler(index)}
                                row={row}
                                fields={fields}
                                options={options}
                                colspan={colspan}
                                index={index}
                                isDnD={isDnD}
                                findItem={findItem}
                                moveItem={moveItem}
                            />
                        );
                    })}
                </>
            );
        } else {
            bodyContent = (
                <tr>
                    <td colSpan={colspan}>
                        {options.hasOwnProperty('noRecordsText')
                            ? options.noRecordsText
                            : `No records.`}
                    </td>
                </tr>
            );
        }
    }

    if (hasBody) {
        return bodyContent;
    }

    return <tbody>{bodyContent}</tbody>;
};

export const DnDTableBody = withDropTarget<
    TableRowType,
    TBodyType<TableRowType>
>(TableBody);

export default TableBody;
