import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Image} from 'react-bootstrap';
import useDoorFacesHelper from 'components/customer/Materials/helper/useDoorFacesHelper';
import {useProductContext} from 'contexts';
import {Loader} from 'shared/helpers';

interface OptionsType {
    hidden?: boolean;
    image?: string;
    description?: string;
    name?: string;
}

type RadioType = {
    name: string;
    value: any;
    setFieldValue: (name: string, value: any) => void;
    type?: string;
    alignRight?: boolean;
    disabled?: boolean;
    disabledMessage?: () => void;
    yesOptions?: OptionsType;
    noOptions?: OptionsType;
};

export const Radio = ({
    name,
    value,
    setFieldValue,
    yesOptions = {},
    noOptions = {},
    type = 'radio',
    disabled = false,
    disabledMessage,
}: RadioType) => {
    value = value ? value : type === 'radio' ? false : 0;
    const {productDataStore} = useProductContext();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [lastClicked, setLastClicked] = useState(null);

    const {handleDoorFaceRadioChange} = useDoorFacesHelper(setFieldValue);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        try {
            setIsDisabled(true)
            const targetValue = event.target.value == 'true';
            setFieldValue(name, targetValue);

            if (event.target.name.includes('include_drawer_faces')) {
                await handleDoorFaceRadioChange(targetValue);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setIsDisabled(false)
        }
    };

    useEffect(() => {
        if (name.includes('include_drawer_faces')) {
            setFieldValue(
                name,
                productDataStore.current.include_drawer_faces ? true : false
            );
        }
    }, []);

    const handleCheck = (name: string, checkType: string) => {
        
        if (disabled && disabledMessage) {
            disabledMessage();
        }

        const element = document.getElementById(`boolean_${name}_${checkType}`);

        if (element && !isDisabled) {
            setLastClicked(checkType);
            element.click();
        }
    };
    
    const getClassName = (type: string) => {
        let classNames = `cbc-radio`;
        if (isDisabled && lastClicked === type) {
            classNames += ' await-radio';
        }
        return classNames;
    };

    const opacity = {
        opacity: '0.5',
    };

    return (
        <>
            <Loader loader={isDisabled}></Loader>
            <Row>
                {!yesOptions.hasOwnProperty('hidden') || !yesOptions.hidden ? (
                    <Col md={6} xs="auto">
                        <div
                            className={getClassName('yes')}
                            onClick={() => handleCheck(name, 'yes')}
                            style={!value ? opacity : null}>
                            {yesOptions.image || yesOptions.description ? (
                                <section
                                    style={{
                                        display: 'flex',
                                        padding: '10px 0',
                                        flexWrap: 'wrap',
                                    }}>
                                    {yesOptions.image ? (
                                        <section
                                            style={{
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                width: '150px',
                                            }}>
                                            <Image
                                                src={`/${yesOptions.image}`}
                                                thumbnail
                                            />
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                    {yesOptions.description ? (
                                        <section
                                            style={{
                                                fontSize: '.75em',
                                                textAlign: 'justify',
                                                flex: '1',
                                            }}>
                                            {yesOptions.description}
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                </section>
                            ) : (
                                <></>
                            )}
                            <Form.Check
                                type="radio"
                                id={`boolean_${name}_yes`}
                                name={name}
                                disabled={isDisabled}
                                value={true}
                                checked={value}
                                label={yesOptions.name ? yesOptions.name : 'Yes'}
                                onChange={handleChange}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
                {!noOptions.hasOwnProperty('hidden') || !noOptions.hidden ? (
                    <Col md={6} xs="auto">
                        <div
                            className={getClassName('no')}
                            onClick={() => handleCheck(name, 'no')}
                            style={value ? opacity : null}>
                            {noOptions.image || noOptions.description ? (
                                <section
                                    style={{
                                        display: 'flex',
                                        padding: '10px 0',
                                        flexWrap: 'wrap',
                                    }}>
                                    {noOptions.image ? (
                                        <section
                                            style={{
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                width: '150px',
                                            }}>
                                            <Image
                                                src={`/${noOptions.image}`}
                                                thumbnail
                                            />
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                    {noOptions.description ? (
                                        <section
                                            style={{
                                                fontSize: '.75em',
                                                textAlign: 'justify',
                                                flex: '1',
                                            }}>
                                            {noOptions.description}
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                </section>
                            ) : (
                                <></>
                            )}
                            <Form.Check
                                type="radio"
                                id={`boolean_${name}_no`}
                                name={name}
                                value={false}
                                disabled={isDisabled}
                                checked={!value}
                                label={noOptions.name ? noOptions.name : 'No'}
                                onChange={handleChange}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
        </>
    );
};
