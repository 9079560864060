import React, {useRef} from 'react';
import styled, {keyframes} from 'styled-components';
import {NoImageAvailable} from 'assets';

const CSSLoader = keyframes`
    0% {
        background-position: 0px 0;
    }
    
    100% {
        background-position: 100em 0;
    }
`;

export const ImageWrapper = styled.div`
    animation-duration: 13s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${CSSLoader};
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(
        to right,
        #f5f5f5 8%,
        /* lighter grey color */ #eeeeee 18%,
        /* lighter grey color */ #f5f5f5 33% /* lighter grey color */
    );
    -webkit-backface-visibility: hidden;
`;

export const Image = ({src = null, alt = ''}: {src?: string; alt?: string}) => {
    const ref = useRef<HTMLImageElement>();

    const errorHandler = () => {
        if (ref.current) {
            ref.current.src = String(NoImageAvailable);
        }
    };

    return (
        <img
            ref={ref}
            onError={errorHandler}
            src={src == null ? String(NoImageAvailable) : src}
            alt={alt}
        />
    );
};
