// @flow
import {Button, Modal, Spinner} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import React, {useState} from 'react';
import {getInfo, saveInfo} from 'service';
import {useAppContext, useProductContext} from 'contexts';
import {WYSIWYGEditor, OverlayTrigger} from 'shared';
import {Icon, productMaxDimensions, getFieldValue} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

type MoreInfoProps = {
    hoverText?: string,
    info: number | string | JSX.Element,
    className?: string,
    visible?: boolean,
    minUsage?: boolean,
    scope?: Object,
    dynamicposition?: boolean,
    disabled?: boolean,
    styleOverride?: Object,
    editbuttonVisible?: boolean,
    dialogClassName?: string,
};

export const MoreInfo = ({
    hoverText,
    info,
    className,
    visible = false,
    minUsage = false,
    editbuttonVisible = true,
    scope = {},
    dynamicposition = false,
    disabled = false,
    styleOverride = {},
    dialogClassName,
}: MoreInfoProps): React$Element<any> => {
    const isSmallDevice = isDeviceSmall();
    const [infoText, setInfoText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [editInfo, setEditInfo] = useState<boolean>(false);
    const [newInfoOnSave, setNewInfoOnSave] = useState<string>('');
    const [show, setShow] = useState<boolean>(visible);

    const productData = useProductContext();
    const {userProfile} = useAppContext();

    const handleClose = () => {
        setShow(false);
        setEditInfo(false);
    };

    const saveMoreInfo = async () => {
        const text: string = await saveInfo(info, newInfoOnSave);

        setInfoText(text);
        handleClose();
    };

    const setEdit = async () => {
        const text: string = await getInfo(info);
        setInfoText(text);
        setEditInfo(true);
    };

    const handleShow = async (edit: boolean = false) => {
        setShow(true);
        setLoading(true);

        let text: string = await getInfo(info);
        if (/\{.*\}/.test(text) && productData) {
            text = productMaxDimensions(
                productData.productDataStore.current,
                text,
                userProfile,
                scope
            );
        } else {
            text = getFieldValue(text.replace(/\$/g, ''), scope, userProfile);
        }

        setInfoText(text);
        setLoading(false);
    };

    hoverText = typeof hoverText !== 'undefined' ? hoverText : 'More Info';
    className =
        typeof className !== 'undefined'
            ? className + ' info-modal'
            : 'info-modal';

    if (isSmallDevice) className += ' mobile';

    const dynamicMoreInfo = () => {
        const modalHeader = (
            <Modal.Header>
                <div className="modal-title h4">{hoverText}</div>
                {userProfile?.isAllowedToEditInfoText && editbuttonVisible && (
                    <Button
                        variant="link"
                        className="mobile-lone-button"
                        style={{padding: '0'}}
                        onClick={setEdit}>
                        <Icon
                            iconName="Options-Edit.svg"
                            style={{height: '25px'}}
                        />
                    </Button>
                )}
            </Modal.Header>
        );

        const modalBody = (
            <div className="modal-body">
                {React.isValidElement(info) ? (
                    info
                ) : editInfo ? (
                    <WYSIWYGEditor
                        inputText={infoText}
                        setInputText={setNewInfoOnSave}
                    />
                ) : isNaN(info) ? (
                    parseHtmlString(info)
                ) : (
                    <section style={{minHeight: '33px'}}>
                        {loading ? (
                            <section style={{textAlign: 'center'}}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </section>
                        ) : (
                            parseHtmlString(infoText)
                        )}
                    </section>
                )}
            </div>
        );

        const modalFooter = (
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {editInfo ? 'Cancel' : 'Close'}
                </Button>
                {editInfo && (
                    <Button variant="success" onClick={saveMoreInfo}>
                        Save
                    </Button>
                )}
            </Modal.Footer>
        );

        const modalContent = (
            <Modal
                show={show}
                onHide={handleClose}
                className={className}
                dialogClassName={dialogClassName}
                centered>
                {modalHeader}
                {modalBody}
                {modalFooter}
            </Modal>
        );

        const button = (
            <Button
                tabIndex="-1"
                variant="link"
                onClick={handleShow}
                style={
                    minUsage
                        ? {width: '20px', margin: '0 0 0 10px'}
                        : dynamicposition
                        ? {width: '20px', margin: '-8px 0 0 5px'}
                        : {}
                }
                disabled={disabled}>
                <Icon
                    style={{
                        verticalAlign: minUsage ? 'baseline' : 'middle',
                    }}
                    iconName="More-Info.svg"
                />
            </Button>
        );

        const overlayTrigger = (
            <OverlayTrigger
                placement={isSmallDevice ? 'top' : 'right'}
                overlay={hoverText}>
                {button}
            </OverlayTrigger>
        );

        const moreInfoDiv = (
            <div
                className="more-info"
                style={{
                    position:
                        dynamicposition === true
                            ? 'relative'
                            : minUsage
                            ? 'initial'
                            : 'absolute',
                    ...styleOverride,
                }}>
                {overlayTrigger}
                {modalContent}
            </div>
        );

        return moreInfoDiv;
    };

    return <>{dynamicMoreInfo()}</>;
};
