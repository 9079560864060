import React, {useState, useReducer, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {PromptButton, OverlayTrigger} from 'shared';
import {Icon} from 'shared/helpers';

import type {TableOptionType, TableRowType} from 'shared/types';
import DropdownOption from './DropdownOption';

type TableOptionsType = {
    hasExtraRow: boolean | number;
    options: TableOptionType;
    row: TableRowType;
    isSmallDevice: boolean;
    isOptionsMenuOpen: boolean;
    setIsOptionsMenuOpen: (isOptionsMenuOpen: boolean) => void;
};

const TableOptions = ({
    hasExtraRow,
    options,
    row,
    isSmallDevice,
    isOptionsMenuOpen,
    setIsOptionsMenuOpen,
}: TableOptionsType): JSX.Element => {
    const [optionIcon, setOptionIcon] = useState<string>(
        'Button-Down-Table.svg'
    );
    const [icons, setIcons] = useReducer(
        (state: string[], action: {index: number; payload: string}) => {
            if (typeof state[action.index] !== 'undefined') {
                const updatedState = [...state];

                updatedState[action.index] = action.payload;

                return updatedState;
            }

            return state;
        },
        [],
        () => {
            if (options.hasOwnProperty('buttons')) {
                return options.buttons.map((button) => button.iconName);
            }

            return [];
        }
    );

    useEffect(() => {
        if (isOptionsMenuOpen) setOptionIcon('Button-Up-Table.svg');
        else setOptionIcon('Button-Down-Table.svg');
    }, [isOptionsMenuOpen]);

    if (options && options.isHidden) {
        return null;
    }

    if (
        isSmallDevice &&
        options &&
        options.hasOwnProperty('buttons') &&
        options.buttons.length > 1
    ) {
        return (
            <td rowSpan={hasExtraRow ? 2 : 1}>
                <Button
                    variant="link"
                    className="mobile-lone-button"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setIsOptionsMenuOpen(!isOptionsMenuOpen);
                    }}>
                    <Icon iconName={optionIcon} />
                </Button>
            </td>
        );
    } else {
        return (
            <td rowSpan={hasExtraRow ? 2 : 1}>
                <section style={{display: 'flex'}}>
                    {options.buttons?.map((button, index) => {
                        let disabled = false;

                        if (
                            typeof button.isHidden !== 'undefined' &&
                            button.isHidden(row)
                        )
                            return <i key={index} />;

                        if (button.hasOwnProperty('disabled')) {
                            if (typeof button.disabled == 'boolean') {
                                disabled = button.disabled;
                            } else if (typeof button.disabled == 'function') {
                                disabled = button.disabled(row);
                            }
                        }

                        if (
                            button.hasOwnProperty('dropdown') &&
                            button.dropdown.length
                        ) {
                            return (
                                <OverlayTrigger
                                    key={index}
                                    overlay={`${button.title}`}>
                                    <div style={{display: 'inline'}}>
                                        <DropdownOption
                                            row={row}
                                            key={index}
                                            button={button}
                                            isSmallDevice={isSmallDevice}
                                            hasTitle={false}
                                        />
                                    </div>
                                </OverlayTrigger>
                            );
                        } else {
                            if (typeof button.title === 'undefined') {
                                return (
                                    <PromptButton
                                        key={index}
                                        disabled={disabled}
                                        iconName={button.iconName}
                                        action={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) =>
                                            button.action(
                                                row,
                                                e,
                                                icons[Number(index)],
                                                (icon) =>
                                                    setIcons({
                                                        index,
                                                        payload: icon,
                                                    })
                                            )
                                        }
                                        showConfirmation={button.confirmChanges}
                                        className={
                                            options.buttons.length === 1
                                                ? 'mobile-lone-button'
                                                : ''
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <OverlayTrigger
                                        key={index}
                                        overlay={button.title}>
                                        <div style={{display: 'inline'}}>
                                            <PromptButton
                                                disabled={disabled}
                                                iconName={icons[Number(index)]}
                                                action={(
                                                    e: React.MouseEvent<HTMLElement>
                                                ) =>
                                                    button.action(
                                                        row,
                                                        e,
                                                        icons[Number(index)],
                                                        (icon) => {
                                                            setIcons({
                                                                index,
                                                                payload: icon,
                                                            });
                                                        }
                                                    )
                                                }
                                                showConfirmation={
                                                    button.confirmChanges
                                                }
                                                className={
                                                    options.buttons.length === 1
                                                        ? 'mobile-lone-button'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </OverlayTrigger>
                                );
                            }
                        }
                    })}
                </section>
            </td>
        );
    }
};

export default TableOptions;
