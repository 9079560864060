import React, {useEffect, useMemo, useState, forwardRef} from 'react';
import styled from 'styled-components';

export interface Option {
    label: string;
    value: string;
    image?: string;
    allowBackground?: boolean;
}

interface CustomSelectProps {
    options: Option[];
    onSelect: (option: Option) => void;
    title?: string;
    isInvalid?: boolean;
    value?: string | number;
    backgroundImageUrl?: string;
    onBlur?: (event: React.FocusEvent<HTMLDivElement> | MouseEvent) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void;
    showOptionLabel?: boolean;
    fieldName?: string;
}

const CustomSelect = forwardRef<HTMLDivElement, CustomSelectProps>(
    (
        {
            options,
            onSelect,
            title,
            isInvalid,
            value,
            onBlur,
            backgroundImageUrl,
            onMouseEnter,
            onMouseLeave,
            showOptionLabel = true,
            fieldName,
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false);

        const selectedOption = useMemo(() => {
            if (options && value) return options.find((o) => o.value == value);

            return null;
        }, [value, options]);

        const handleOptionClick = (option: Option) => {
            setIsOpen(false);
            onSelect(option);
        };

        const handleOutsideClick = (event: MouseEvent) => {
            if (
                ref &&
                'current' in ref &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
                onBlur && onBlur(event);
            }
        };

        const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
            setIsOpen(false);
            onBlur && onBlur(event);
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, []);

        return (
            <SelectComponent
                ref={ref}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onBlur={handleBlur}
                $isSelectOptionOpen={isOpen}>
                <SelectHeader
                    onClick={() => setIsOpen(!isOpen)}
                    $isInvalid={isInvalid}
                    title={title}>
                    {selectedOption ? (
                        <div
                            data-cy={fieldName}
                            style={{display: 'flex', alignItems: 'center'}}>
                            <SelectOptionImageContainer>
                                <SelectHeaderImage
                                    src={selectedOption.image}
                                    alt={selectedOption?.label}
                                    style={{
                                        backgroundImage:
                                            selectedOption.allowBackground
                                                ? `url("${backgroundImageUrl}")`
                                                : '',
                                    }}
                                />
                            </SelectOptionImageContainer>

                            {showOptionLabel ? selectedOption?.label : null}
                            <SelectArrowDown $topStyle={!showOptionLabel} />
                        </div>
                    ) : (
                        'Select'
                    )}
                </SelectHeader>
                {isOpen ? (
                    <SelectOptions $defaultWidth={showOptionLabel}>
                        {options?.map((option) => (
                            <SelectOption
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                                $isActive={
                                    selectedOption.value === option.value
                                }>
                                {option.image ? (
                                    <SelectOptionImageContainer>
                                        <SelectOptionImage
                                            src={option.image}
                                            alt={option?.label}
                                            style={{
                                                backgroundImage:
                                                    option.allowBackground
                                                        ? `url("${backgroundImageUrl}")`
                                                        : '',
                                            }}
                                        />
                                    </SelectOptionImageContainer>
                                ) : null}

                                {showOptionLabel ? option?.label : null}
                            </SelectOption>
                        ))}
                    </SelectOptions>
                ) : null}
            </SelectComponent>
        );
    }
);
CustomSelect.displayName = 'CustomSelect';

const SelectComponent = styled.div<{$isSelectOptionOpen: boolean}>`
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 1.5em;
    text-align: left;
    outline: ${({$isSelectOptionOpen}) =>
        $isSelectOptionOpen ? '-webkit-focus-ring-color auto 1px' : 'none'};
    border-radius: 4px;
`;

const SelectHeader = styled.div<{$isInvalid: boolean}>`
    cursor: pointer;
    padding: 6px 10px;
    background: ${({$isInvalid}) => ($isInvalid ? '#f7d0c2' : 'transparent')};
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const SelectOptions = styled.ul<{$defaultWidth: boolean}>`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #dadedf;
    border: 1px solid #ccc;
    border-radius: 0 0 8px 8px;
    box-shadow: 2px 1px 3px 0px #7c7c7c;
    width: ${({$defaultWidth}) => ($defaultWidth ? '280px' : 'auto')};
    max-height: 310px;
    overflow: auto;
    color: #6b6f70;
    font-size: 0.85rem;
    & li:last-child {
        border-radius: 0 0 8px 8px;
    }

    &.noLabelStyles {
        overflow-y: scroll;
        max-height: 200px;
        margin-left: auto;
    }
}
`;

const SelectOption = styled.li<{$isActive: boolean}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 500;
    &:hover {
        background: #c5c9ca;
    }
    background: ${({$isActive}) => ($isActive ? '#c5c9ca' : 'transparent')};
`;

const SelectHeaderImage = styled.img`
    height: 23px;
    width: auto;
    box-sizing: border-box;
    border-radius: 6px;
`;

const SelectOptionImage = styled.img`
    height: 65px;
    width: auto;
    padding: 0;
    box-sizing: border-box;
    border-radius: 6px;
`;

const SelectOptionImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 1px 0;
    border: 1px solid ${({theme}) => theme.colors.secondary.main};
    width: auto;
    background-color: #fff;
    border-radius: 6px;
`;

const SelectArrowDown = styled.i<{$topStyle: boolean}>`
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3.5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    right: 8px;
    top: ${({$topStyle}) => ($topStyle ? 'auto' : '13px')};
`;

export default CustomSelect;
