declare let applicationVersion: string;
declare let versionPollInterval: number;

import {useAppDispatch, useAppSelector} from 'store/customer';
import {useEffect, useMemo, useRef, useState} from 'react';
import {reloadScriptSet, selectReloadScript} from 'store/customer/appSlice';
import {usePollVersion} from 'shared/reload/helper/usePollVersion';
import {BaseConfig} from 'config';

const DEFAULT_VERSION = 'dev-manual';

export const CheckScriptVersion = (): null => {
    const firstRender = useRef(true);
    const reloadScript = useAppSelector(selectReloadScript);
    const [versionMismatch, setVersionMismatch] = useState(false);
    const dispatch = useAppDispatch();
    const waitTime = useMemo(() => {
        if (versionPollInterval > 0) {
            return versionPollInterval;
        }

        if (
            BaseConfig &&
            'USER_CUSTOMER' in BaseConfig &&
            'ScriptHotReloadWaitTime' in BaseConfig.USER_CUSTOMER
        ) {
            return BaseConfig.USER_CUSTOMER.ScriptHotReloadWaitTime; // 30 minutes
        }

        return 5000; // 5 seconds
    }, [BaseConfig]);
    const trimmedApplicationVersion = applicationVersion
        ? applicationVersion.trim()
        : null;
    const isUnversionedBuild = trimmedApplicationVersion == DEFAULT_VERSION;

    // NOTE: This stops or pauses hot reload in three instances
    // 1. If we're running an unversionsed build
    // 2. If version mismatch is detected
    // 3. If reloadScript is set to true i.e we have already detected
    // that reload is required but scripts are not reloaded yet.
    const {version} = usePollVersion({
        pollingInterval: waitTime,
        skip: isUnversionedBuild || versionMismatch || reloadScript,
    });

    const trimmedVersion = version ? version.trim() : null;

    useEffect(() => {
        if (isUnversionedBuild) {
            return;
        }

        if (trimmedApplicationVersion && trimmedVersion) {
            if (trimmedApplicationVersion != trimmedVersion) {
                if (firstRender.current) {
                    /*
                     * If this is our first execution, then we somehow loaded
                     * a version of the script with the wrong version
                     */
                    setVersionMismatch(true);
                    console.error('Version mismatch, please reload the page');
                } else {
                    // Otherwise let's reload the page!
                    dispatch(reloadScriptSet(true));
                }
            }

            firstRender.current = false;
        }
    }, [trimmedVersion]);

    return null;
};
