import {Event} from 'shared/events/Event';
import {useCallback} from 'react';

export const useEventProcessor = (handlers: Event[]) => {
    const processEvents = useCallback(
        async (event: React.MouseEvent) => {
            for (const handler of handlers) {
                const proceed = await handler.action(event);

                if (!proceed) {
                    break;
                }
            }
        },
        [handlers]
    );

    return {processEvents};
};
