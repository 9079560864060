import React, {useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {Icon, Loader} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useAppliance} from 'hooks';
import {FIELDSET_LAYOUT, GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import {MoreInfo, OverlayTrigger} from 'shared';
import {Appliance as ApplianceInterface} from 'components/customer/Product/entity/Appliance';

const APPLIANCE_TYPES: {[key: string]: number} = {
    oven: 0,
    rangehood: 1,
    microwave: 2,
};

export const getApplianceType = (type: number) => {
    const APPLIANCES = ['Oven', 'Rangehood', 'Microwave'];

    return APPLIANCES[Number(type)];
};

interface ApplianceProps {
    dataSourceParams: {
        type: number;
    };
    field: {
        name: string;
        displayName: string;
        options: {
            applianceType: string;
            heightFieldName: string;
            heightFieldMoreInfo: string;
            advancedCheckboxFieldName: string;
            moreInfo: string;
        };
    };
    dropdown: {
        render: (props: ApplianceProps) => JSX.Element;
    };
}

interface ProductContextType {
    productDataStore: {
        current: {
            cabinet: {
                attributes: object;
            };
        };
    };
}

export const Appliance = (props: ApplianceProps) => {
    const {dataSourceParams, field, dropdown} = props;
    const isSmallDevice = isDeviceSmall();
    const type = APPLIANCE_TYPES[field.options.applianceType];
    const {loader, dialog, buttonClickHandher} = useAppliance(type, field.name);

    const [dataSource, setDataSource] = useState({
        dataSourceParams: {
            ...dataSourceParams,
            type,
        },
    });
    const {values, setFieldValue} = useFormikContext<{
        [key: string]: boolean;
    }>();
    const {productDataStore}: ProductContextType = useProductContext();

    const {heightFieldName, heightFieldMoreInfo, advancedCheckboxFieldName} =
        useMemo(() => {
            const heightFieldName = field.options['heightFieldName'];
            const heightFieldMoreInfo = field.options['heightFieldMoreInfo'];
            const advancedCheckboxFieldName =
                field.options['advancedCheckboxFieldName'];

            return {
                heightFieldName,
                heightFieldMoreInfo,
                advancedCheckboxFieldName,
            };
        }, [field]);

    const formConfig = useMemo(() => {
        return type == 1
            ? []
            : [
                  {
                      name: heightFieldName,
                      displayName: 'Opening Height',
                      type: 'size',
                      value: 0,
                      options: {
                          moreInfo: heightFieldMoreInfo,
                          enabled: `${advancedCheckboxFieldName} == 0`,
                      },
                  },
                  {
                      name: advancedCheckboxFieldName,
                      displayName: 'Advanced Selection',
                      type: 'boolean',
                      value: 0,
                  },
              ];
    }, [type, heightFieldName, heightFieldMoreInfo, advancedCheckboxFieldName]);

    useEffect(() => {
        if (!loader) {
            setDataSource({
                dataSourceParams: {
                    ...dataSourceParams,
                    type,
                },
            });
        }
    }, [loader, dataSourceParams, type]);

    const applianceHandler = (
        name: string,
        value: number,
        options: ApplianceInterface[]
    ) => {
        const selectedOption = options.find((option) => option.id == value);

        if (selectedOption) {
            void setFieldValue(heightFieldName, selectedOption.height);
        }
    };

    return (
        <Loader loader={loader}>
            <div style={{borderBottom: '1px solid', marginBottom: '15px'}}>
                <h2 style={{width: 'fit-content', padding: '5px 15px'}}>
                    {field.displayName}
                </h2>
            </div>

            <GenericFields
                fields={formConfig}
                fieldsetName="appliance"
                fieldsetLayout={FIELDSET_LAYOUT.COMPOUND}
            />

            {type == 1 || values[String(advancedCheckboxFieldName)] ? (
                <Form.Group as={Row}>
                    <Form.Label column xs={4}>
                        {`${field.displayName} Selection`}

                        <MoreInfo
                            scope={
                                productDataStore.current &&
                                productDataStore.current.cabinet &&
                                productDataStore.current.cabinet.attributes
                                    ? productDataStore.current.cabinet
                                          .attributes
                                    : {}
                            }
                            info={field.options.moreInfo}
                        />
                    </Form.Label>
                    <Col xs="12" sm="auto" style={{width: 250}}>
                        {dropdown.render({
                            ...props,
                            ...dataSource,
                            ...{
                                selectHandler: (
                                    name: string,
                                    value: number,
                                    options: ApplianceInterface[]
                                ) => {
                                    applianceHandler(name, value, options);
                                    void setFieldValue(name, value);
                                },
                                hasImage: 'image',
                                extraHandler: applianceHandler,
                                updateValueOnFetchCompleted: true,
                            },
                        })}
                    </Col>
                    <Col xs="12" sm="1">
                        <OverlayTrigger
                            placement={isSmallDevice ? 'top' : 'right'}
                            overlay="Add a new Appliance">
                            <Icon
                                style={{
                                    width: '25px',
                                    cursor: 'pointer',
                                    marginTop: '6px',
                                    marginLeft: isSmallDevice ? '0' : '-10px',
                                }}
                                iconName="Button-Plus.svg"
                                onClick={buttonClickHandher}
                            />
                        </OverlayTrigger>
                    </Col>
                </Form.Group>
            ) : null}
            {dialog}
        </Loader>
    );
};
