import {useRecentlyAdded} from 'hooks';
import React from 'react';
import {Items} from 'shared';

interface RecentItemsProps {
    isProduct: boolean;
    setError?: (errors: string[]) => void;
    colWidth: number;
}

export const RecentItems = ({isProduct, setError}: RecentItemsProps) => {
    const {items} = useRecentlyAdded(isProduct, setError);

    if (items.length) {
        return (
            <Items
                items={items}
                title="Recently Added"
                noHr={false}
                isProduct={isProduct}
                setError={setError}
            />
        );
    }

    return null;
};
