// @flow
import {toFixed} from 'shared/helpers';
import Shape from './Shape';

const SHELVES_PREVIEWS = {
    WINERACK: 'winerack',
    THREE_TIER: 'three_tier',
    TWO_PART_PANTRY: 'two_part_pantry',
    TWO_PART_BROOM: 'two_part_broom',
    TWO_TIER: 'two_tier',
    NORMAL_SHELVES: 'normal',
    NORMAL_UPPER: 'normal_upper',
    BROOM: 'broom',
    WALL_OVEN_MICRO: 'wall_oven_micro',
    DRAWER_DOOR: 'drawer_door',
};

const SHELVES_TYPE = {
    UPPER: 'upper',
    LOWER: 'lower',
    MIDDLE: 'middle',
    SIMPLE: '',
    NONE: 'none',
    DRAWER_DOOR: 'drawer_door',
};

type drawShelfSectionProps = {
    positions: Array<{x: number, y: number}>,
    shadow?: boolean,
    topOpen?: boolean,
    fill?: string | CanvasPattern,
    stroke?: boolean,
    type?: number,
    borderColor?: string,
};

type mapperProps = {
    label: string,
    position: string,
    type: string,
    style?: string,
};

type Shelf = {
    label: string,
    position: number,
    type: number,
    shelf: number,
};

type drawShelvesProps = {
    height: number,
    width?: number,
    type?: string,
    hasBase?: boolean,
    topOpen?: boolean,
    rectYOffsetAddOn?: number,
    rectXOffsetAddOn?: number,
    hasTop?: boolean,
    hasRight?: boolean,
    hasLeft?: boolean,
    drawPartition?: boolean,
    ratio?: number,
    shelfLabel?: string,
    drawCarcase?: boolean,
    fill?: string | CanvasPattern,
    fillEdge?: string | CanvasPattern,
};

type drawWineRackProps = {
    verticalShelves: Array<number>,
    horizontalShelves: Array<number>,
    thickness: number,
};

/**
 *
 *
 * @export
 * @class Shelves
 * @extends {Shape}
 */
export default class Shelves extends Shape {
    preview: string;
    rectWidth: number;
    rectHeight: number;
    edgePattern: string | CanvasPattern;
    carcasePattern: string | CanvasPattern;
    rectXOffset: number;
    rectYOffset: number;
    isHorizontal: boolean;

    /**
     *
     *
     * @return {*}  {[number, number]}
     * @memberof Shelves
     */
    dimension(): [number, number] {
        const width = this.options.width;
        let height = this.options.height;

        if (this.preview === SHELVES_PREVIEWS.NORMAL_UPPER) {
            height = height - this.options.partitionHeight;
        }

        const [rectWidth, rectHeight] = super.dimension(width, height);

        this.rectWidth = rectWidth;
        this.rectHeight = rectHeight;

        return [rectWidth, rectHeight];
    }

    /**
     *
     *
     * @param {drawShelfSectionProps} {
     *         positions,
     *         shadow = false,
     *         topOpen = false
     *         fill,
     *         stroke = true,
     *         type = 0,
     *         borderColor = '#434343',
     *     }
     * @memberof Shelves
     */
    drawShelfSection({
        positions,
        shadow = false,
        topOpen = false,
        fill,
        stroke = true,
        type = 0,
        borderColor = '#434343',
    }: drawShelfSectionProps) {
        this.context.beginPath();
        this.context.strokeStyle = borderColor;
        this.context.fillStyle = fill ? fill : this.edgePattern;
        this.context.lineWidth = 2.5;
        this.context.moveTo(positions[0].x, positions[0].y);

        positions
            .filter((position, index) => index > 0)
            .forEach((position) => this.context.lineTo(position.x, position.y));

        if (!topOpen) {
            this.context.lineTo(positions[0].x, positions[0].y);
        }

        if (shadow) {
            this.context.shadowColor = 'white';
            this.context.shadowBlur = 2;
        }

        if (type == 1) {
            this.context.setLineDash([2, 2]);
        } else {
            this.context.setLineDash([]);
        }

        stroke && this.context.stroke();
        this.context.fill();

        if (shadow) {
            this.context.shadowBlur = 0;
        }
    }

    /**
     *
     *
     * @param {mapperProps} {label, position, type, style = 'shelf_style'}
     * @return {*}  {Shelf}
     * @memberof Shelves
     */
    mapper({
        label,
        position,
        type,
        style = 'shelf_style',
    }: mapperProps): Function {
        return (shelf, index) => ({
            type: shelf[type],
            position: shelf[position],
            label: `${label} ${index + 1}`,
            shelf: shelf[style],
        });
    }

    /**
     *
     *
     * @param {String} type
     * @return {*}  {Array<Shelf>}
     * @memberof Shelves
     */
    advancedShelves(type: string): Array<Shelf> {
        if (type === SHELVES_TYPE.UPPER) {
            return this.options.upperShelves.map(
                this.mapper(
                    this.options.shelvesMapper
                        ? this.options.shelvesMapper
                        : {
                              label: 'Upper Shelf',
                              position: 'upper_shelf_position',
                              type: 'upper_shelf_type',
                              style: 'upper_shelf_style',
                          }
                )
            );
        } else if (type === SHELVES_TYPE.MIDDLE) {
            return this.options.middleShelves.map(
                this.mapper({
                    label: 'Middle Shelf',
                    position: 'middle_shelf_position',
                    type: 'middle_shelf_type',
                    style: 'middle_shelf_style',
                })
            );
        } else if (type === SHELVES_TYPE.LOWER) {
            return this.options.lowerShelves.map(
                this.mapper({
                    label:
                        this.preview === SHELVES_PREVIEWS.BROOM
                            ? 'BL'
                            : 'Lower Shelf',
                    position: 'lower_shelf_position',
                    type: 'lower_shelf_type',
                    style: 'lower_shelf_style',
                })
            );
        } else if (type === SHELVES_TYPE.DRAWER_DOOR) {
            return this.options.advancedShelves;
        } else {
            return this.options.advancedShelves.map(
                this.mapper({
                    label:
                        this.preview === SHELVES_PREVIEWS.BROOM
                            ? 'BR'
                            : 'Shelf',
                    position: 'shelf_position',
                    type: 'shelf_type',
                })
            );
        }
    }

    /**
     *
     *
     * @param {string} type
     * @return {*}  {Array<Shelf>}
     * @memberof Shelves
     */
    simpleShelves(type: string): Array<Shelf> {
        if (type == SHELVES_TYPE.LOWER || type == SHELVES_TYPE.DRAWER_DOOR) {
            return [];
        }

        let index = 0;
        return Array.from({length: this.options.simpleShelvesAmount}, () => {
            const shelf = toFixed(
                (index + 1) / (this.options.simpleShelvesAmount + 1),
                2
            );
            index++;

            return {
                type: this.options.simpleShelvesType,
                position: shelf,
                label: `Shelf ${index}`,
                shelf: this.options.simpleShelvesType,
            };
        });
    }

    /**
     *
     *
     * @param {drawShelvesProps} {
     *         height,
     *         width = this.rectWidth,
     *         type,
     *         hasBase = true,
     *         hasTop = true,
     *         hasRight = true,
     *         hasLeft = true,
     *         drawPartition = true,
     *         topOpen = false,
     *         rectYOffsetAddOn = 0,
     *         ratio = -1,
     *     }
     * @memberof Shelves
     */
    async drawShelves({
        height,
        width = this.rectWidth,
        type = SHELVES_TYPE.SIMPLE,
        hasBase = true,
        hasTop = true,
        hasRight = true,
        hasLeft = true,
        drawPartition = true,
        topOpen = false,
        ratio = -1,
        rectYOffsetAddOn = 0,
        rectXOffsetAddOn = 0,
        shelfLabel,
        drawCarcase = true,
        fillEdge,
        fill,
    }: drawShelvesProps) {
        let labelTextColor: string;
        let borderColor: string;
        if (this.preview === SHELVES_PREVIEWS.NORMAL_UPPER)
            type = SHELVES_TYPE.UPPER;

        let shelves = this.options.isAdvanced
            ? this.advancedShelves(type)
            : this.simpleShelves(type);

        if (type === SHELVES_TYPE.NONE) {
            shelves = [];
        }

        if (
            this.preview !== SHELVES_PREVIEWS.NORMAL_UPPER &&
            this.preview !== SHELVES_PREVIEWS.NORMAL_SHELVES &&
            drawPartition
        ) {
            if (!hasBase) {
                height = height - 5;

                this.drawShelfSection({
                    positions: [
                        {
                            x: this.rectXOffset + this.edgeWidth,
                            y: this.rectYOffset + height,
                        },
                        {
                            x: this.rectXOffset + width - this.edgeWidth,
                            y: this.rectYOffset + height,
                        },
                        {
                            x: this.rectXOffset + width - this.edgeWidth,
                            y: this.rectYOffset + height + 5,
                        },
                        {
                            x: this.rectXOffset + this.edgeWidth,
                            y: this.rectYOffset + height + 5,
                        },
                    ],
                    fill: '#000',
                });
            }

            if (!hasTop && hasBase) {
                height = height - 5;
            }
        }

        // this is carcase material
        if (drawCarcase) {
            let carcaseHeight: number = height;
            let carcaseWidth: number = width;

            if (this.preview == SHELVES_PREVIEWS.TWO_TIER) {
                carcaseHeight -= hasBase ? 1 : 0;
            }

            if (this.preview == SHELVES_PREVIEWS.THREE_TIER) {
                carcaseHeight += hasBase ? 5 : 0;
            }

            if (!hasLeft || !hasRight) {
                carcaseWidth = width - 3;

                if (this.preview == SHELVES_PREVIEWS.BROOM) {
                    carcaseWidth -= 2;
                }
            }

            let xOffset: number = this.rectXOffset;
            if (!hasLeft) {
                xOffset = this.rectXOffset + 3;
            }

            this.drawShelfSection({
                positions: [
                    {x: xOffset, y: this.rectYOffset},
                    {x: xOffset + carcaseWidth, y: this.rectYOffset},
                    {
                        x: xOffset + carcaseWidth,
                        y: this.rectYOffset + carcaseHeight,
                    },
                    {x: xOffset, y: this.rectYOffset + carcaseHeight},
                ],
                fill: fill ? fill : this.carcasePattern,
                stroke: false,
            });

            labelTextColor = this.color(
                xOffset + this.edgeWidth,
                this.rectYOffset + this.edgeWidth,
                carcaseWidth - this.edgeWidth,
                carcaseHeight - this.edgeWidth
            );
            borderColor = this.color(
                xOffset + this.edgeWidth,
                this.rectYOffset + this.edgeWidth,
                carcaseWidth - this.edgeWidth,
                carcaseHeight - this.edgeWidth,
                '#fcfcfc',
                '#434343'
            );
        }

        if (hasTop) {
            this.drawShelfSection({
                positions: [
                    // top side
                    {
                        x: this.rectXOffset + this.edgeWidth - rectXOffsetAddOn,
                        y: this.rectYOffset,
                    },
                    {
                        x: this.rectXOffset + width - this.edgeWidth,
                        y: this.rectYOffset,
                    },
                    {
                        x: this.rectXOffset + width - this.edgeWidth,
                        y: this.rectYOffset + this.edgeWidth,
                    },
                    {
                        x: this.rectXOffset + this.edgeWidth - rectXOffsetAddOn,
                        y: this.rectYOffset + this.edgeWidth,
                    },
                ],
                fill: fillEdge ? fillEdge : undefined,
                topOpen:
                    this.preview == SHELVES_PREVIEWS.TWO_PART_BROOM && topOpen
                        ? true
                        : false,
            });
        }

        if (hasBase) {
            if (this.preview === SHELVES_PREVIEWS.THREE_TIER) {
                height = height + 5;
            }

            this.drawShelfSection({
                positions: [
                    // bottom side
                    {
                        x: this.rectXOffset + (hasLeft ? this.edgeWidth : 3),
                        y: this.rectYOffset + height,
                    },
                    {
                        x:
                            this.rectXOffset +
                            width -
                            (hasRight ? this.edgeWidth : 3),
                        y: this.rectYOffset + height,
                    },
                    {
                        x:
                            this.rectXOffset +
                            width -
                            (hasRight ? this.edgeWidth : 3),
                        y: this.rectYOffset + height - this.edgeWidth,
                    },
                    {
                        x: this.rectXOffset + (hasLeft ? this.edgeWidth : 3),
                        y: this.rectYOffset + height - this.edgeWidth,
                    },
                ],
                fill: fillEdge ? fillEdge : undefined,
            });
        }

        if (hasLeft) {
            this.drawShelfSection({
                positions: [
                    // left side
                    {
                        x: this.rectXOffset,
                        y: this.rectYOffset - rectYOffsetAddOn,
                    },
                    {x: this.rectXOffset, y: this.rectYOffset + height},
                    {
                        x: this.rectXOffset + this.edgeWidth,
                        y: this.rectYOffset + height,
                    },
                    {
                        x: this.rectXOffset + this.edgeWidth,
                        y: this.rectYOffset - rectYOffsetAddOn,
                    },
                ],
                shadow: true,
                fill: fillEdge ? fillEdge : undefined,
                topOpen:
                    this.preview == SHELVES_PREVIEWS.TWO_TIER && topOpen
                        ? true
                        : false,
            });
        }

        if (hasRight) {
            this.drawShelfSection({
                positions: [
                    // right side
                    {
                        x: this.rectXOffset + width,
                        y: this.rectYOffset - rectYOffsetAddOn,
                    },
                    {x: this.rectXOffset + width, y: this.rectYOffset + height},
                    {
                        x: this.rectXOffset + width - this.edgeWidth,
                        y: this.rectYOffset + height,
                    },
                    {
                        x: this.rectXOffset + width - this.edgeWidth,
                        y: this.rectYOffset - rectYOffsetAddOn,
                    },
                ],
                shadow: true,
                fill: fillEdge ? fillEdge : undefined,
                topOpen:
                    this.preview == SHELVES_PREVIEWS.TWO_TIER && topOpen
                        ? true
                        : false,
            });
        }

        shelves.forEach((shelfInfo) => {
            let rectHeight: number =
                height -
                Math.round(height * shelfInfo.position) -
                this.edgeWidth / 2;
            if (ratio > -1 && shelfInfo.position >= 1)
                rectHeight =
                    height -
                    Math.round(ratio * shelfInfo.position) -
                    this.edgeWidth / 2;

            this.drawShelfSection({
                positions: [
                    {
                        x:
                            this.rectXOffset +
                            (hasLeft ? this.edgeWidth : 1) +
                            2,
                        y: this.rectYOffset + rectHeight,
                    },
                    {
                        x:
                            this.rectXOffset +
                            width -
                            (hasRight ? this.edgeWidth : 1) -
                            2,
                        y: this.rectYOffset + rectHeight,
                    },
                    {
                        x:
                            this.rectXOffset +
                            width -
                            (hasRight ? this.edgeWidth : 1) -
                            2,
                        y: this.rectYOffset + rectHeight + this.edgeWidth,
                    },
                    {
                        x:
                            this.rectXOffset +
                            (hasLeft ? this.edgeWidth : 1) +
                            2,
                        y: this.rectYOffset + rectHeight + this.edgeWidth,
                    },
                ],
                shadow: !hasRight || !hasLeft ? false : true,
                type: shelfInfo.shelf,
                borderColor,
            });

            this.label(
                undefined,
                [
                    this.rectXOffset + this.edgeWidth + 2 + 5,
                    this.rectYOffset + rectHeight - 5 - this.edgeWidth / 2,
                ],
                shelfInfo.label,
                40,
                10,
                12,
                labelTextColor
            );
        });

        if (
            !shelves.length &&
            hasBase &&
            (shelfLabel || this.options.innerText)
        ) {
            let text = this.options.innerText ? this.options.innerText : '';

            if (shelfLabel) {
                text = shelfLabel;
            }

            if (text === 'Drawers') {
                const extImage: Image | boolean = await this.image(
                    this.options.extColor
                );
                if (this.options.hasOwnProperty('drawerFaceHeight')) {
                    height = Math.round(this.options.drawerFaceHeight * ratio);
                }

                let marginTop = 0;
                let marginLeft = 0;
                let marginRight = 0;

                if (this.options.drawerMargins.top) {
                    marginTop = this.options.drawerMargins.top * ratio;
                }

                if (this.options.drawerMargins.left) {
                    marginLeft = this.options.drawerMargins.left * ratio;
                }

                if (this.options.drawerMargins.right) {
                    marginRight = this.options.drawerMargins.right * ratio;
                }

                const extPattern = this.pattern(
                    this.options.horizontalExt,
                    extImage,
                    90
                );
                this.context.fillStyle = extPattern;
                this.context.fillRect(
                    this.rectXOffset + marginLeft,
                    this.rectYOffset + marginTop,
                    width - marginRight - marginLeft,
                    height - marginTop
                );
            }

            this.label(
                undefined,
                [
                    this.rectXOffset + this.edgeWidth + 2 + 5,
                    this.rectYOffset + height / 2 - 5 - this.edgeWidth / 2,
                ],
                text,
                80,
                10,
                12,
                labelTextColor
            );
        }
    }

    /**
     *
     *
     * @param {drawWineRackProps} {
     *         verticalShelves,
     *         horizontalShelves,
     *         thickness,
     *     }
     * @memberof Shelves
     */
    drawWineRack({
        verticalShelves,
        horizontalShelves,
        thickness,
    }: drawWineRackProps) {
        const height = this.rectHeight;

        this.drawShelfSection({
            positions: [
                {x: this.rectXOffset, y: this.rectYOffset},
                {x: this.rectXOffset + this.rectWidth, y: this.rectYOffset},
                {
                    x: this.rectXOffset + this.rectWidth,
                    y: this.rectYOffset + this.rectHeight,
                },
                {x: this.rectXOffset, y: this.rectYOffset + this.rectHeight},
            ],
            fill: this.carcasePattern,
        });

        this.drawShelfSection({
            positions: [
                // top side
                {
                    x: this.rectXOffset + this.edgeWidth + 1.5,
                    y: this.rectYOffset,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth - 1.5,
                    y: this.rectYOffset,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth - 1.5,
                    y: this.rectYOffset + this.edgeWidth,
                },
                {
                    x: this.rectXOffset + this.edgeWidth + 1.5,
                    y: this.rectYOffset + this.edgeWidth,
                },
            ],
        });

        this.drawShelfSection({
            positions: [
                // bottom side
                {
                    x: this.rectXOffset + this.edgeWidth + 1.5,
                    y: this.rectYOffset + height,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth - 1.5,
                    y: this.rectYOffset + height,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth - 1.5,
                    y: this.rectYOffset + height - this.edgeWidth,
                },
                {
                    x: this.rectXOffset + this.edgeWidth + 1.5,
                    y: this.rectYOffset + height - this.edgeWidth,
                },
            ],
        });

        this.drawShelfSection({
            positions: [
                // left side
                {x: this.rectXOffset, y: this.rectYOffset},
                {x: this.rectXOffset, y: this.rectYOffset + height},
                {
                    x: this.rectXOffset + this.edgeWidth,
                    y: this.rectYOffset + height,
                },
                {x: this.rectXOffset + this.edgeWidth, y: this.rectYOffset},
            ],
            shadow: true,
        });

        this.drawShelfSection({
            positions: [
                // right side
                {x: this.rectXOffset + this.rectWidth, y: this.rectYOffset},
                {
                    x: this.rectXOffset + this.rectWidth,
                    y: this.rectYOffset + height,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y: this.rectYOffset + height,
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y: this.rectYOffset,
                },
            ],
            shadow: true,
        });

        verticalShelves.forEach((position) => {
            const rectWidth =
                this.rectWidth -
                Math.round(this.rectWidth * position) -
                thickness / 2;

            this.drawShelfSection({
                positions: [
                    {
                        x: this.rectXOffset + rectWidth,
                        y: this.rectYOffset + this.edgeWidth + 1,
                    },
                    {
                        x: this.rectXOffset + rectWidth + thickness,
                        y: this.rectYOffset + this.edgeWidth + 1,
                    },
                    {
                        x: this.rectXOffset + rectWidth + thickness,
                        y: this.rectYOffset + height - this.edgeWidth - 1,
                    },
                    {
                        x: this.rectXOffset + rectWidth,
                        y: this.rectYOffset + height - this.edgeWidth - 1,
                    },
                ],
            });
        });

        horizontalShelves.forEach((position) => {
            const rectHeight =
                height - Math.round(height * position) - thickness / 2;

            this.drawShelfSection({
                positions: [
                    {
                        x: this.rectXOffset + this.edgeWidth + 1,
                        y: this.rectYOffset + rectHeight,
                    },
                    {
                        x:
                            this.rectXOffset +
                            this.rectWidth -
                            this.edgeWidth -
                            1,
                        y: this.rectYOffset + rectHeight,
                    },
                    {
                        x:
                            this.rectXOffset +
                            this.rectWidth -
                            this.edgeWidth -
                            1,
                        y: this.rectYOffset + rectHeight + thickness,
                    },
                    {
                        x: this.rectXOffset + this.edgeWidth + 1,
                        y: this.rectYOffset + rectHeight + thickness,
                    },
                ],
            });
        });
    }

    /**
     *
     *
     * @memberof Shelves
     */
    async draw() {
        this.preview = SHELVES_PREVIEWS.NORMAL_SHELVES;

        if (
            this.options.oven_opening_height ||
            this.options.microwave_opening_height
        ) {
            this.preview = SHELVES_PREVIEWS.WALL_OVEN_MICRO;
        } else if (
            this.options.cabinetName.toLowerCase().indexOf('door') > -1 &&
            this.options.cabinetName.toLowerCase().indexOf('drawer') > -1
        ) {
            this.options.innerText = '';
            this.preview = SHELVES_PREVIEWS.DRAWER_DOOR;
        } else if (
            this.options.cabinetName.toLowerCase().includes('pantry') &&
            this.options.cabinetName.includes('2 Part')
        ) {
            this.preview = SHELVES_PREVIEWS.TWO_PART_PANTRY;
        } else if (
            this.options.cabinetName.toLowerCase().includes('broom 2 part')
        ) {
            this.preview = SHELVES_PREVIEWS.TWO_PART_BROOM;
        } else if (
            this.options.partitionHeight &&
            this.options.upperShelves &&
            this.options.partitionWidth == -1
        ) {
            if (typeof this.options.lowerShelves === 'undefined') {
                this.options.lowerShelves = [];
            }

            this.preview = SHELVES_PREVIEWS.TWO_TIER;
        } else if (
            this.options.lowerPartitionHeight &&
            this.options.upperPartitionHeight
        ) {
            this.preview = SHELVES_PREVIEWS.THREE_TIER;
        } else if (
            this.options.horizontalShelves &&
            this.options.verticalShelves
        ) {
            this.preview = SHELVES_PREVIEWS.WINERACK;
        } else if (
            this.options.partitionHeight &&
            this.options.upperShelves &&
            typeof this.options.lowerShelves === 'undefined'
        ) {
            this.preview = SHELVES_PREVIEWS.NORMAL_UPPER;
        } else if (
            this.options.advancedShelves &&
            this.options.upperShelves &&
            this.options.lowerShelves &&
            this.options.partitionWidth
        ) {
            this.preview = SHELVES_PREVIEWS.BROOM;
        }

        this.dimension();
        this.isHorizontal =
            this.options.hasOwnProperty('horizontal') &&
            this.options.horizontal;

        this.rectXOffset =
            this.canvas.getBoundingClientRect().width / 2 -
            this.rectWidth / 2 +
            0.5;

        const carcaseImage: Image | boolean = await this.image(
            this.options.color
        );
        this.carcasePattern = this.pattern(this.isHorizontal, carcaseImage, 90);

        const edgeImage: Image | boolean = await this.image(
            this.options.edgeColor
        );
        this.edgePattern = this.pattern(this.isHorizontal, edgeImage, 45);

        this.edgeWidth = 5;
        const ratio: number =
            this.rectHeight < this.options.height
                ? this.rectHeight / this.options.height
                : this.options.height / this.rectHeight;

        if (
            this.preview === SHELVES_PREVIEWS.NORMAL_SHELVES ||
            this.preview === SHELVES_PREVIEWS.NORMAL_UPPER
        ) {
            this.rectYOffset =
                this.canvas.getBoundingClientRect().height / 2 -
                this.rectHeight / 2;

            this.drawShelves({
                height: this.rectHeight,
                hasBase: this.options.hasBase,
                hasTop: this.options.hasTop,
                hasLeft: this.options.hasLeft,
                hasRight: this.options.hasRight,
                ratio,
            });
        } else if (this.preview === SHELVES_PREVIEWS.TWO_PART_BROOM) {
            const partitionHeight: number = Math.round(
                this.options.partitionHeight * ratio
            );
            const partitionWidth: number = Math.round(
                this.options.partitionWidth * ratio
            );

            this.rectXOffset =
                this.canvas.getBoundingClientRect().width / 2 -
                this.rectWidth / 2;
            this.rectYOffset =
                this.canvas.getBoundingClientRect().height / 2 -
                this.rectHeight / 2;

            const positionBottom = [
                {
                    x: this.rectXOffset + this.edgeWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y:
                        this.rectYOffset +
                        this.rectHeight -
                        this.edgeWidth +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.edgeWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight -
                        this.edgeWidth +
                        (this.options.hasBase ? 0 : 0),
                },
            ];

            this.drawShelfSection({
                positions: [
                    {
                        x: this.rectXOffset + partitionWidth - 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            this.rectYOffset +
                            (this.options.hasBase ? 5 : 0),
                    },
                    {
                        x: this.rectXOffset + partitionWidth + 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            this.rectYOffset +
                            (this.options.hasBase ? 5 : 0),
                    },
                    {
                        x: this.rectXOffset + partitionWidth + 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            (this.options.hasBase ? 5 : 0) +
                            this.rectYOffset +
                            partitionHeight -
                            (this.options.hasBase ? this.edgeWidth * 2 : 0) +
                            (this.options.hasBase ? 5 : -5),
                    },
                    {
                        x: this.rectXOffset + partitionWidth - 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            (this.options.hasBase ? 5 : 0) +
                            this.rectYOffset +
                            partitionHeight -
                            (this.options.hasBase ? this.edgeWidth * 2 : 0) +
                            (this.options.hasBase ? 5 : -5),
                    },
                ],
                fill: '#000',
                shadow: false,
            });

            [
                [
                    this.rectHeight -
                        partitionHeight +
                        (this.options.hasBase ? 5 : 0) -
                        5,
                    this.rectWidth,
                    this.rectYOffset,
                    this.rectXOffset,
                    SHELVES_TYPE.UPPER,
                    true,
                    this.options.hasTop,
                    true,
                    true,
                    true,
                    0,
                    false,
                ],
                [
                    partitionHeight - 5,
                    partitionWidth,
                    this.rectHeight -
                        partitionHeight +
                        this.rectYOffset +
                        (this.options.hasBase ? 5 : 0),
                    this.rectXOffset,
                    SHELVES_TYPE.LOWER,
                    false,
                    true,
                    false,
                    true,
                    false,
                    0,
                    false,
                ],
                [
                    partitionHeight - 5,
                    this.rectWidth - partitionWidth,
                    this.rectHeight -
                        partitionHeight +
                        this.rectYOffset +
                        (this.options.hasBase ? 5 : 0),
                    this.rectXOffset + partitionWidth,
                    SHELVES_TYPE.SIMPLE,
                    false,
                    true,
                    true,
                    false,
                    false,
                    partitionWidth - 1,
                    true,
                ],
            ].forEach(
                ([
                    height,
                    width,
                    yOffset,
                    xOffset,
                    type,
                    hasBase,
                    hasTop,
                    hasRight,
                    hasLeft,
                    drawPartition,
                    rectXOffsetAddOn,
                    topOpen,
                ]) => {
                    this.rectYOffset = yOffset;
                    this.rectXOffset = xOffset;

                    this.drawShelves({
                        height,
                        width,
                        type,
                        hasBase,
                        hasTop,
                        hasRight,
                        hasLeft,
                        ratio,
                        drawPartition,
                        rectXOffsetAddOn,
                        topOpen,
                    });
                }
            );

            if (this.options.hasBase) {
                this.drawShelfSection({
                    positions: positionBottom,
                    fill: undefined,
                    shadow: true,
                });
            }
        } else if (this.preview === SHELVES_PREVIEWS.TWO_PART_PANTRY) {
            const ratio: number =
                this.rectHeight < this.options.height
                    ? this.rectHeight / this.options.height
                    : this.options.height / this.rectHeight;

            const partitionHeight: number = Math.round(
                this.options.partitionHeight * ratio
            );

            [this.rectHeight - partitionHeight, partitionHeight].forEach(
                (height, index) => {
                    let hasBase = false;
                    if (index == 0) {
                        this.rectYOffset =
                            this.canvas.getBoundingClientRect().height / 2 -
                            this.rectHeight / 2;
                        height -= 5;
                        hasBase = true;
                    } else {
                        hasBase = this.options.hasBase;
                        this.rectYOffset =
                            this.rectYOffset + (this.rectHeight - height);
                    }

                    this.drawShelves({
                        height: height,
                        type:
                            index == 0
                                ? SHELVES_TYPE.UPPER
                                : SHELVES_TYPE.LOWER,
                        hasBase,
                        hasTop: true,
                        drawPartition: index == 0,
                        ratio,
                    });
                }
            );
        } else if (this.preview === SHELVES_PREVIEWS.TWO_TIER) {
            const ratio: number =
                this.rectHeight < this.options.height
                    ? this.rectHeight / this.options.height
                    : this.options.height / this.rectHeight;

            const partitionHeight: number = Math.round(
                this.options.partitionHeight * ratio
            );

            [this.rectHeight - partitionHeight, partitionHeight].forEach(
                (height, index) => {
                    let rectYOffsetAddOn = 0;
                    if (index == 0) {
                        this.rectYOffset =
                            this.canvas.getBoundingClientRect().height / 2 -
                            this.rectHeight / 2;
                        rectYOffsetAddOn = 0;
                    } else {
                        rectYOffsetAddOn =
                            this.rectHeight - partitionHeight - 1;

                        this.rectYOffset =
                            this.rectYOffset + (this.rectHeight - height);
                    }

                    this.drawShelves({
                        height,
                        type:
                            index == 0
                                ? SHELVES_TYPE.UPPER
                                : SHELVES_TYPE.LOWER,
                        drawPartition: index == 0,
                        hasBase: index != 0 && this.options.hasBase,
                        hasTop: index == 0 && this.options.hasTop,
                        ratio,
                        topOpen: index == 1 && true,
                        rectYOffsetAddOn,
                    });
                }
            );
        } else if (this.preview === SHELVES_PREVIEWS.DRAWER_DOOR) {
            const ratio: number =
                this.rectHeight < this.options.height
                    ? this.rectHeight / this.options.height
                    : this.options.height / this.rectHeight;

            const partitionHeight: number = Math.round(
                this.options.partitionHeight * ratio
            );

            [partitionHeight, this.rectHeight - partitionHeight].forEach(
                (height, index) => {
                    if (index == 0) {
                        this.rectYOffset =
                            this.canvas.getBoundingClientRect().height / 2 -
                            this.rectHeight / 2;
                    } else {
                        this.rectYOffset =
                            this.rectYOffset + (this.rectHeight - height);
                    }

                    this.drawShelves({
                        height,
                        type:
                            index != 0
                                ? SHELVES_TYPE.UPPER
                                : SHELVES_TYPE.DRAWER_DOOR,
                        drawPartition: index == 0,
                        hasBase: index != 0 && this.options.hasBase,
                        hasTop: index == 0 && this.options.hasTop,
                        ratio,
                    });

                    if (index == 0) {
                        const labelTextColor = this.color(
                            this.rectXOffset + this.edgeWidth,
                            this.rectYOffset + this.edgeWidth,
                            this.rectWidth - this.edgeWidth,
                            height - this.edgeWidth
                        );
                        this.label(
                            undefined,
                            [
                                this.rectXOffset + this.edgeWidth + 2 + 5,
                                this.rectYOffset +
                                    height / 2 +
                                    5 -
                                    this.edgeWidth / 2,
                            ],
                            'Drawer Section',
                            40,
                            10,
                            12,
                            labelTextColor
                        );
                    }
                }
            );
        } else if (this.preview === SHELVES_PREVIEWS.THREE_TIER) {
            this.edgeWidth = 2.5;
            const upperHeight: number = Math.round(
                this.rectHeight * this.options.upperPartitionHeight
            );
            const lowerHeight: number = Math.round(
                this.rectHeight * this.options.lowerPartitionHeight
            );

            [
                {
                    height: this.rectHeight - upperHeight - 10,
                    hasBase: true,
                    drawPartition: true,
                    hasTop: true,
                },
                {
                    height: upperHeight - lowerHeight - 10,
                    hasBase: true,
                    hasTop: true,
                    drawPartition: true,
                },
                {
                    height: lowerHeight - 5,
                    hasTop: true,
                    hasBase: this.options.hasBase,
                    drawPartition: this.options.hasBase,
                },
            ].forEach((shelf, index) => {
                let type: string;

                if (index == 0) {
                    this.rectYOffset =
                        this.canvas.getBoundingClientRect().height / 2 -
                        this.rectHeight / 2;
                    type = SHELVES_TYPE.UPPER;
                } else if (index == 1) {
                    this.rectYOffset += this.rectHeight - upperHeight;
                    type = SHELVES_TYPE.MIDDLE;
                } else {
                    this.rectYOffset += upperHeight - lowerHeight;
                    type = SHELVES_TYPE.LOWER;
                }

                this.drawShelves({
                    height: shelf.height,
                    type,
                    hasBase: shelf.hasBase,
                    hasTop: shelf.hasTop,
                    ratio,
                    drawPartition: shelf.drawPartition,
                });
            });
        } else if (this.preview === SHELVES_PREVIEWS.WINERACK) {
            this.rectYOffset =
                this.canvas.getBoundingClientRect().height / 2 -
                this.rectHeight / 2 +
                0.5;
            this.edgeWidth = 2;

            const verticalShelves: Array<number> = Array.from(
                {length: this.options.verticalShelves},
                (_, index) =>
                    toFixed((index + 1) / (this.options.verticalShelves + 1), 4)
            );
            const horizontalShelves: Array<number> = Array.from(
                {length: this.options.horizontalShelves},
                (_, index) =>
                    toFixed(
                        (index + 1) / (this.options.horizontalShelves + 1),
                        4
                    )
            );

            this.drawWineRack({
                verticalShelves,
                horizontalShelves,
                thickness: 2,
            });
        } else if (this.preview === SHELVES_PREVIEWS.BROOM) {
            const partitionHeight: number = Math.round(
                this.options.partitionHeight * ratio
            );
            const partitionWidth: number = Math.round(
                this.options.partitionWidth * ratio
            );

            this.rectXOffset =
                this.canvas.getBoundingClientRect().width / 2 -
                this.rectWidth / 2;
            this.rectYOffset =
                this.canvas.getBoundingClientRect().height / 2 -
                this.rectHeight / 2;

            this.drawShelfSection({
                positions: [
                    {
                        x: this.rectXOffset + partitionWidth - 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            this.rectYOffset +
                            (this.options.hasBase ? 5 : 0.5),
                    },
                    {
                        x: this.rectXOffset + partitionWidth + 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            this.rectYOffset +
                            (this.options.hasBase ? 5 : 0.5),
                    },
                    {
                        x: this.rectXOffset + partitionWidth + 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            (this.options.hasBase ? 5 : 0) +
                            this.rectYOffset +
                            partitionHeight -
                            (this.options.hasBase ? this.edgeWidth * 2 : 0) +
                            (this.options.hasBase ? 5 : 0.5),
                    },
                    {
                        x: this.rectXOffset + partitionWidth - 5,
                        y:
                            this.rectHeight -
                            partitionHeight +
                            (this.options.hasBase ? 5 : 0) +
                            this.rectYOffset +
                            partitionHeight -
                            (this.options.hasBase ? this.edgeWidth * 2 : 0) +
                            (this.options.hasBase ? 5 : 0.5),
                    },
                ],
                fill: '#000',
                shadow: false,
            });

            const positionLeft = [
                {x: this.rectXOffset, y: this.rectYOffset},
                {
                    x: this.rectXOffset,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0.5),
                },
                {
                    x: this.rectXOffset + this.edgeWidth,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0.5),
                },
                {x: this.rectXOffset + this.edgeWidth, y: this.rectYOffset},
            ];

            const positionRight = [
                {x: this.rectXOffset + this.rectWidth + 2, y: this.rectYOffset},
                {
                    x: this.rectXOffset + this.rectWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0.5),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0.5),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth + 2,
                    y: this.rectYOffset,
                },
            ];

            const positionBottom = [
                {
                    x: this.rectXOffset + this.edgeWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y:
                        this.rectYOffset +
                        this.rectHeight +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.rectWidth - this.edgeWidth,
                    y:
                        this.rectYOffset +
                        this.rectHeight -
                        this.edgeWidth +
                        (this.options.hasBase ? 0 : 0),
                },
                {
                    x: this.rectXOffset + this.edgeWidth + 2,
                    y:
                        this.rectYOffset +
                        this.rectHeight -
                        this.edgeWidth +
                        (this.options.hasBase ? 0 : 0),
                },
            ];
            //[height, width, yOffset, xOffset, type, hasBase, hasTop, hasRight, hasLeft]
            [
                [
                    this.rectHeight -
                        partitionHeight +
                        (this.options.hasBase ? 5 : 0),
                    this.rectWidth,
                    this.rectYOffset,
                    this.rectXOffset,
                    SHELVES_TYPE.UPPER,
                    false,
                    this.options.hasTop,
                    false,
                    false,
                    true,
                ],
                [
                    partitionHeight - (this.options.hasBase ? 3 : 1),
                    partitionWidth,
                    this.rectHeight -
                        partitionHeight +
                        this.rectYOffset +
                        (this.options.hasBase ? 5 : 0),
                    this.rectXOffset,
                    SHELVES_TYPE.LOWER,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    partitionHeight - (this.options.hasBase ? 3 : 1),
                    this.rectWidth - partitionWidth,
                    this.rectHeight -
                        partitionHeight +
                        this.rectYOffset +
                        (this.options.hasBase ? 5 : 0),
                    this.rectXOffset + partitionWidth,
                    SHELVES_TYPE.SIMPLE,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
            ].forEach(
                ([
                    height,
                    width,
                    yOffset,
                    xOffset,
                    type,
                    hasBase,
                    hasTop,
                    hasRight,
                    hasLeft,
                    drawPartition,
                ]) => {
                    this.rectYOffset = yOffset;
                    this.rectXOffset = xOffset;

                    this.drawShelves({
                        height,
                        width,
                        type,
                        hasBase,
                        hasTop,
                        hasRight,
                        hasLeft,
                        ratio,
                        drawPartition,
                    });
                }
            );

            this.drawShelfSection({
                positions: positionLeft,
                shadow: true,
                fill: this.carcasePattern,
            });

            this.drawShelfSection({
                positions: positionRight,
                shadow: true,
                fill: this.carcasePattern,
            });

            if (this.options.hasBase) {
                this.drawShelfSection({
                    positions: positionBottom,
                    fill: undefined,
                    shadow: true,
                });
            }
        } else if (this.preview === SHELVES_PREVIEWS.WALL_OVEN_MICRO) {
            const ovenHeight = this.options.oven_opening_height
                ? this.options.oven_opening_height
                : 0;
            const ovenHeightPreview = ovenHeight * ratio;

            const microwaveHeight = this.options.microwave_opening_height
                ? this.options.microwave_opening_height
                : 0;
            const microwaveHeightPreview = microwaveHeight * ratio;

            const shelvesHeight = this.options.rem_door_shelf_height
                ? this.options.rem_door_shelf_height
                : 0;
            const shelvesHeightPreview = shelvesHeight * ratio;

            const drawerHeight = this.options.partitionHeight
                ? this.options.partitionHeight
                : 0;
            const drawerHeightPreview = drawerHeight * ratio;

            const extImage: Image | boolean = await this.image(
                this.options.extColor
            );
            const extPattern = this.pattern(this.isHorizontal, extImage, 45);

            const extEdgeImage: Image | boolean = await this.image(
                this.options.extEdgeColor
            );
            const extEdgePattern = this.pattern(
                this.isHorizontal,
                extEdgeImage,
                45
            );

            this.rectYOffset =
                this.canvas.getBoundingClientRect().height / 2 -
                this.rectHeight / 2;

            [
                {
                    height: shelvesHeightPreview,
                    type: SHELVES_TYPE.SIMPLE,
                    offset: 0,
                    carcase: true,
                    hasBase: true,
                    fill: this.carcasePattern,
                    fillEdge: this.edgePattern,
                },
                {
                    height: microwaveHeightPreview,
                    label: 'Microwave',
                    type: SHELVES_TYPE.NONE,
                    offset: shelvesHeightPreview,
                    carcase: true,
                    hasBase: true,
                    fill: extPattern,
                    fillEdge: extEdgePattern,
                },
                {
                    height: ovenHeightPreview,
                    label: 'Oven',
                    type: SHELVES_TYPE.NONE,
                    offset: microwaveHeightPreview
                        ? microwaveHeightPreview
                        : shelvesHeightPreview,
                    carcase: false,
                    hasBase: true,
                    fill: this.carcasePattern,
                    fillEdge: this.edgePattern,
                },
                {
                    height: drawerHeightPreview,
                    label: 'Drawers',
                    type: SHELVES_TYPE.NONE,
                    offset: ovenHeightPreview,
                    carcase: true,
                    hasBase: true,
                    fill: extPattern,
                    fillEdge: this.edgePattern,
                },
            ].forEach((shelf) => {
                if (shelf.height > 0) {
                    this.rectYOffset += shelf.offset;

                    this.drawShelves({
                        height: shelf.height,
                        shelfLabel: shelf.label ? shelf.label : undefined,
                        type: shelf.type,
                        ratio,
                        drawCarcase: shelf.carcase,
                        hasBase: shelf.hasBase,
                        fill: shelf.fill,
                        fillEdge: shelf.fillEdge,
                    });
                }
            });
        }
    }
}
