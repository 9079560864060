import Shape from './Shape';
/**
 *
 *
 * @export
 * @class UShape
 * @extends {Shape}
 */
export default class UShape extends Shape {
    /**
     *
     *
     * @memberof UShape
     */
    dimension() {
        if (this.canvas.width <= 0 || this.canvas.height <= 0) {
            return;
        }

        const length = parseInt(this.options.height);
        const width = parseInt(this.options.width);
        const length1 = parseInt(this.options.length1);
        const length2 = parseInt(this.options.length2);
        const width1 = parseInt(this.options.width1);
        const exteriorRadius = parseInt(this.options.exteriorRadius);
        const interiorRadius = parseInt(this.options.interiorRadius);

        if (length1 <= exteriorRadius) {
            throw new Error('Invalid Length 1');
        }

        if (length2 <= exteriorRadius) {
            throw new Error('Invalid Length 2');
        }

        if (width1 < exteriorRadius + interiorRadius) {
            throw new Error(
                'Invalid Shape, change values for width 1 and radii '
            );
        }

        if (width1 >= width) {
            throw new Error('Invalid width 1');
        }

        const staticWidth = this.canvas.width - this.widthOffset * 2;
        const staticHeight = this.canvas.height - this.heightOffset * 2 - 30;

        let newWidth = width;
        let newHeight = length;

        if (width > staticWidth || length > staticHeight) {
            newWidth = staticWidth < width ? staticWidth : width;
            newHeight = Math.round(
                (staticWidth < width
                    ? staticWidth / width
                    : width / staticWidth) * length
            );

            if (newHeight > staticHeight) {
                newHeight = staticHeight;
                newWidth = Math.round(
                    (staticHeight < length
                        ? staticHeight / length
                        : length / staticHeight) * width
                );
            }
        }

        this.verticalWidth = Math.round(newWidth);

        if (this.verticalWidth + this.heightOffset * 2 > this.height) {
            this.verticalWidth = this.height - this.heightOffset * 2;
        }

        this.length = Math.round(newHeight);

        if (this.length + this.widthOffset * 2 > this.width) {
            this.length = this.width - this.widthOffset * 2;
        }

        this.exteriorRadius = Math.round(
            (this.length / length) * exteriorRadius
        );
        this.interiorRadius = Math.round(
            (this.length / length) * interiorRadius
        );

        this.length1 =
            Math.round((this.length / length) * length1) - this.exteriorRadius;
        this.length2 =
            Math.round((this.length / length) * length2) - this.exteriorRadius;
        this.width1 = Math.round((this.verticalWidth / width) * width1);
    }

    /**
     *
     *
     * @param {*} xArc
     * @param {*} yArc
     * @param {*} radius
     * @param {*} startAngle
     * @param {*} endAngle
     * @memberof UShape
     */
    arc(xArc, yArc, radius, startAngle, endAngle) {
        this.context.beginPath();
        this.context.arc(xArc, yArc, radius, startAngle, endAngle);
        this.context.stroke();
    }

    /**
     *
     *
     * @memberof UShape
     */
    lines() {
        this.line(
            [this.xOffset, this.yOffset - 15],
            [this.xOffset + this.length, this.yOffset - 15]
        );
        this.line(
            [this.xOffset - 15, this.yOffset],
            [this.xOffset - 15, this.yOffset + this.verticalWidth]
        );

        this.line(
            [
                this.xOffset + this.length + 15,
                this.yOffset + this.verticalWidth - this.width1,
            ],
            [this.xOffset + this.length + 15, this.yOffset + this.verticalWidth]
        );

        this.line(
            [this.xOffset, this.yOffset + this.verticalWidth + 15],
            [
                this.xOffset + this.length1 + this.exteriorRadius,
                this.yOffset + this.verticalWidth + 15,
            ]
        );

        this.line(
            [
                this.xOffset + this.length - this.length2 - this.exteriorRadius,
                this.yOffset + this.verticalWidth + 15,
            ],
            [this.xOffset + this.length, this.yOffset + this.verticalWidth + 15]
        );
    }

    /**
     *
     *
     * @memberof UShape
     */
    labels() {
        this.label(
            [this.width / 2 - 25, this.yOffset - 25],
            [this.width / 2 - 25 + 5, this.yOffset - 10],
            'Length',
            50,
            20
        );

        this.label(
            [this.xOffset - 39, this.height / 2 - 10],
            [this.xOffset - 45, this.height / 2 + 5],
            'Width',
            35,
            20
        );

        this.label(
            [
                this.xOffset + this.length1 / 2,
                this.yOffset + this.verticalWidth + 5,
            ],
            [
                this.xOffset + this.length1 / 2 + 5,
                this.yOffset + this.verticalWidth + 18,
            ],
            'L1',
            20,
            20
        );

        this.label(
            [
                this.xOffset + this.length - this.length2 / 2 - 15,
                this.yOffset + this.verticalWidth + 5,
            ],
            [
                this.xOffset + this.length - this.length2 / 2 - 11,
                this.yOffset + this.verticalWidth + 18,
            ],
            'L2',
            20,
            20
        );

        this.label(
            [
                this.xOffset + this.length + 5,
                this.yOffset + this.verticalWidth - this.width1 / 2 - 10,
            ],
            [
                this.xOffset + this.length + 10,
                this.yOffset + this.verticalWidth - this.width1 / 2 + 3,
            ],
            'Width 1',
            40,
            20
        );

        this.label(
            [
                this.xOffset + this.length1 + this.exteriorRadius + 15,
                this.yOffset + this.verticalWidth,
            ],
            [
                this.xOffset + this.length1 + this.exteriorRadius + 15,
                this.yOffset + this.verticalWidth + 10,
            ],
            'Ext. Radius',
            50,
            20,
            10
        );

        this.label(
            [
                this.xOffset + this.length1 + this.exteriorRadius + 15,
                this.yOffset + this.verticalWidth - this.width1 - 25,
            ],
            [
                this.xOffset + this.length1 + this.exteriorRadius + 15,
                this.yOffset + this.verticalWidth - this.width1 - 25 + 15,
            ],
            'Int. Radius',
            50,
            20,
            10
        );
    }

    /**
     *
     *
     * @memberof UShape
     */
    edges() {
        this.context.lineWidth = this.edgeWidth;
        this.context.strokeStyle = this.edgeColor;

        const edgeOffset = this.edgeDistance + this.edgeWidth;

        if (this.options.edging.edgeLength2) {
            this.context.beginPath();
            this.context.moveTo(
                this.xOffset - edgeOffset,
                this.yOffset - edgeOffset
            );
            this.context.lineTo(
                this.xOffset + this.length + edgeOffset,
                this.yOffset - edgeOffset
            );
            this.context.stroke();
        }

        if (this.options.edging.edgeWidth1) {
            this.context.beginPath();
            this.context.moveTo(
                this.xOffset - edgeOffset,
                this.yOffset - edgeOffset
            );
            this.context.lineTo(
                this.xOffset - edgeOffset,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.stroke();
        }

        if (this.options.edging.edgeWidth2) {
            this.context.beginPath();
            this.context.moveTo(
                this.xOffset + this.length + edgeOffset,
                this.yOffset - edgeOffset
            );
            this.context.lineTo(
                this.xOffset + this.length + edgeOffset,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.stroke();
        }

        if (this.options.edging.edgeLength1) {
            this.context.beginPath(); // Length 1
            this.context.moveTo(
                this.xOffset - edgeOffset,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.lineTo(
                this.xOffset + this.length1,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.stroke();

            this.context.beginPath(); // Length 2
            this.context.moveTo(
                this.xOffset + this.length - this.length2,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.lineTo(
                this.xOffset + this.length + edgeOffset,
                this.yOffset + this.verticalWidth + edgeOffset
            );
            this.context.stroke();

            this.context.beginPath(); // Width 1 left
            this.context.moveTo(
                this.xOffset + this.length1 + this.exteriorRadius + edgeOffset,
                this.yOffset + this.verticalWidth - this.exteriorRadius
            );
            this.context.lineTo(
                this.xOffset + this.length1 + this.exteriorRadius + edgeOffset,
                this.yOffset +
                    this.verticalWidth -
                    this.width1 +
                    this.interiorRadius
            );
            this.context.stroke();

            this.context.beginPath(); // Width 1 Right
            this.context.moveTo(
                this.xOffset +
                    this.length -
                    this.length2 -
                    this.exteriorRadius -
                    edgeOffset,
                this.yOffset + this.verticalWidth - this.exteriorRadius
            );
            this.context.lineTo(
                this.xOffset +
                    this.length -
                    this.length2 -
                    this.exteriorRadius -
                    edgeOffset,
                this.yOffset +
                    this.verticalWidth -
                    this.width1 +
                    this.interiorRadius
            );
            this.context.stroke();

            this.context.beginPath(); // small length
            this.context.moveTo(
                this.xOffset +
                    this.length1 +
                    this.exteriorRadius +
                    this.interiorRadius,
                this.yOffset + this.verticalWidth - this.width1 + edgeOffset
            );
            this.context.lineTo(
                this.xOffset +
                    this.length -
                    this.length2 -
                    this.exteriorRadius -
                    this.interiorRadius,
                this.yOffset + this.verticalWidth - this.width1 + edgeOffset
            );
            this.context.stroke();

            this.context.beginPath();
            this.arc(
                this.xOffset + this.length1,
                this.yOffset + this.verticalWidth - this.exteriorRadius,
                this.exteriorRadius + edgeOffset,
                2 * Math.PI,
                0.5 * Math.PI
            );

            this.arc(
                this.xOffset +
                    this.length1 +
                    this.exteriorRadius +
                    this.interiorRadius,
                this.yOffset +
                    this.verticalWidth -
                    this.width1 +
                    this.interiorRadius,
                this.interiorRadius === 0
                    ? 0
                    : this.interiorRadius - edgeOffset,
                Math.PI,
                1.5 * Math.PI
            );

            this.arc(
                this.xOffset +
                    this.length -
                    this.length2 -
                    this.interiorRadius -
                    this.exteriorRadius,
                this.yOffset +
                    this.verticalWidth -
                    this.width1 +
                    this.interiorRadius,
                this.interiorRadius === 0
                    ? 0
                    : this.interiorRadius - edgeOffset,
                1.5 * Math.PI,
                2 * Math.PI
            );

            this.arc(
                this.xOffset + this.length - this.length2,
                this.yOffset + this.verticalWidth - this.exteriorRadius,
                this.exteriorRadius + edgeOffset,
                0.5 * Math.PI,
                Math.PI
            );
        }

        this.context.strokeStyle = '#000';
        this.context.lineWidth = 3;
    }

    /**
     *
     *
     * @memberof UShape
     */
    draw() {
        const edging = this.options.hasOwnProperty('edging');
        this.dimension();

        this.xOffset =
            this.canvas.getBoundingClientRect().width / 2 - this.length / 2;
        this.yOffset =
            this.canvas.getBoundingClientRect().height / 2 -
            this.verticalWidth / 2;

        // solid line
        this.context.beginPath();
        this.context.lineWidth = 3;
        this.context.moveTo(
            this.xOffset + this.length1,
            this.yOffset + this.verticalWidth
        );
        this.context.lineTo(this.xOffset, this.yOffset + this.verticalWidth);
        this.context.lineTo(this.xOffset, this.yOffset);
        this.context.lineTo(this.xOffset + this.length, this.yOffset);
        this.context.lineTo(
            this.xOffset + this.length,
            this.yOffset + this.verticalWidth
        );
        this.context.lineTo(
            this.xOffset + this.length - this.length2,
            this.yOffset + this.verticalWidth
        );
        this.context.stroke();

        if (!edging) {
            // dotted line
            this.context.beginPath();
            this.context.setLineDash([2, 2]);
            this.context.lineWidth = 1;
            this.context.moveTo(
                this.xOffset + this.length1,
                this.yOffset + this.verticalWidth
            );
            this.context.lineTo(
                this.xOffset + this.length1 + this.exteriorRadius,
                this.yOffset + this.verticalWidth
            );
            this.context.lineTo(
                this.xOffset + this.length1 + this.exteriorRadius,
                this.yOffset + this.verticalWidth - this.width1
            );
            this.context.lineTo(
                this.xOffset + this.length - this.length2 - this.exteriorRadius,
                this.yOffset + this.verticalWidth - this.width1
            );
            this.context.lineTo(
                this.xOffset + this.length - this.length2 - this.exteriorRadius,
                this.yOffset + this.verticalWidth
            );
            this.context.lineTo(
                this.xOffset + this.length - this.length2,
                this.yOffset + this.verticalWidth
            );
            this.context.stroke();
        }

        this.context.setLineDash([]);
        this.context.beginPath();
        this.context.lineWidth = 3;
        this.context.moveTo(
            this.xOffset + this.length1 + this.exteriorRadius,
            this.yOffset + this.verticalWidth - this.exteriorRadius
        );
        this.context.lineTo(
            this.xOffset + this.length1 + this.exteriorRadius,
            this.yOffset +
                this.verticalWidth -
                this.width1 +
                this.interiorRadius
        );
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(
            this.xOffset +
                this.length1 +
                this.exteriorRadius +
                this.interiorRadius,
            this.yOffset + this.verticalWidth - this.width1
        );
        this.context.lineTo(
            this.xOffset +
                this.length -
                this.length2 -
                this.exteriorRadius -
                this.interiorRadius,
            this.yOffset + this.verticalWidth - this.width1
        );
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(
            this.xOffset + this.length - this.length2 - this.exteriorRadius,
            this.yOffset +
                this.verticalWidth -
                this.width1 +
                this.interiorRadius
        );
        this.context.lineTo(
            this.xOffset + this.length - this.length2 - this.exteriorRadius,
            this.yOffset + this.verticalWidth - this.exteriorRadius
        );
        this.context.stroke();

        this.arc(
            this.xOffset + this.length1,
            this.yOffset + this.verticalWidth - this.exteriorRadius,
            this.exteriorRadius,
            2 * Math.PI,
            0.5 * Math.PI
        );

        this.arc(
            this.xOffset +
                this.length1 +
                this.exteriorRadius +
                this.interiorRadius,
            this.yOffset +
                this.verticalWidth -
                this.width1 +
                this.interiorRadius,
            this.interiorRadius,
            Math.PI,
            1.5 * Math.PI
        );

        this.arc(
            this.xOffset +
                this.length -
                this.length2 -
                this.interiorRadius -
                this.exteriorRadius,
            this.yOffset +
                this.verticalWidth -
                this.width1 +
                this.interiorRadius,
            this.interiorRadius,
            1.5 * Math.PI,
            2 * Math.PI
        );

        this.arc(
            this.xOffset + this.length - this.length2,
            this.yOffset + this.verticalWidth - this.exteriorRadius,
            this.exteriorRadius,
            0.5 * Math.PI,
            Math.PI
        );

        if (!edging) {
            this.lines();
            this.labels();
        } else {
            this.edges();
        }
    }
}
