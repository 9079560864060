import React, {useEffect, useMemo} from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import {useGetConfigurationQuery} from 'store/customer/appSlice';

export const Configuration = () => {
    const {data: configuration, isLoading: isConfigurationLoading} =
        useGetConfigurationQuery();
    const host = window.location.host;

    const [gaKey, gtmId] = useMemo(() => {
        if (isConfigurationLoading) {
            return [null, null];
        }

        let gaKey = configuration.googleAnalyticsKey;

        if (configuration.googleAnalyticsKeys != null) {
            for (const h in configuration.googleAnalyticsKeys) {
                if (host == h || host.endsWith('.' + h)) {
                    gaKey = configuration.googleAnalyticsKeys[h];
                    break;
                }
            }
        }

        let gtmId = configuration.googleTagManagerId;

        if (configuration.googleTagManagerIds != null) {
            for (const h in configuration.googleTagManagerIds) {
                if (host == h || host.endsWith('.' + h)) {
                    gtmId = configuration.googleTagManagerIds[h];
                    break;
                }
            }
        }

        return [gaKey, gtmId];
    }, [isConfigurationLoading, configuration, host]);

    useEffect(() => {
        if (gaKey != null) {
            ReactGA.initialize(gaKey);
        }
    }, [gaKey]);

    useEffect(() => {
        if (gtmId != null) {
            TagManager.initialize({
                gtmId,
            });
        }
    }, [gtmId]);

    return <></>;
};
