/**
 * Checks if the value supplied is defined, not null and a number
 * Use this function to shorten the if condition for all these conditions.
 * 
 * @param {any} val value to check
 * @return {boolean}
 */
export const isValidNonNegativeNumber = (val: any): boolean => {
    const isDefined = typeof val !== 'undefined';
    const isNotNull = val != null;
    const isNonNegative = val >= 0;

    return isDefined && isNotNull && isNonNegative;
};
