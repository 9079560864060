import {AppApi} from 'store/customer/api';

const UpdateApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        roomAssistant: builder.mutation<void, {room_assistant: number}>({
            query: (body) => ({
                url: '/settings/defaults/save',
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['User'],
        }),
        tutorial: builder.mutation<void, Record<string, boolean>>({
            query: (body) => ({
                url: `/settings/set-tutorial`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['User'],
        }),
    }),
});

export const {useTutorialMutation} = UpdateApi;
