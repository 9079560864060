import {useEffect, useState} from 'react';

export const useZoomHelper = () => {
    const [pixelRatio, setPixelRatio] = useState(1);

    const handleResize = () => {
        setPixelRatio(window.devicePixelRatio);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return pixelRatio;
};
