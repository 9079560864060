import React, {useState} from 'react';
import {useCategoriesAndItems} from 'hooks';
import {Icon} from 'shared/helpers';
import {Categories, Items, RecentItems} from 'shared';
import {Alert} from 'react-bootstrap';
import {useProductContext} from 'contexts';

type CategoriesAndItemsType = {
    isProduct?: boolean;
};

export const CategoriesAndItems = ({
    isProduct = false,
}: CategoriesAndItemsType) => {
    const {loader, categories, items} = useCategoriesAndItems();
    const [errorMessages, setErrorMessages] = useState([]);
    const {search} = useProductContext<{search: string}>();

    return (
        <span>
            {errorMessages.length ? (
                <div className="jobFormErrors" style={{marginTop: '15px'}}>
                    {errorMessages.map((errorMessage, index) => {
                        return (
                            <Alert variant="danger" key={index}>
                                <Icon iconName="Button-Error.svg" />
                                <div>{errorMessage}</div>
                            </Alert>
                        );
                    })}
                </div>
            ) : null}
            {search == '' ? (
                <Categories
                    isProduct={isProduct}
                    categories={categories}
                    loader={loader}
                />
            ) : null}

            {items.length ? (
                <Items
                    items={items}
                    noHr={!categories.length || search != ''}
                    isProduct={isProduct}
                    setError={setErrorMessages}
                    loader={loader}
                />
            ) : (
                <RecentItems
                    isProduct={isProduct}
                    setError={setErrorMessages}
                />
            )}
        </span>
    );
};
