import React from 'react';
import {useSearchParams} from 'react-router-dom';
import TableBody, {DnDTableBody} from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';

import type {OptionsType} from 'shared/types';

import './Table.scss';

interface JobCBCTableProps {
    options: OptionsType<{id: number}>;
    className?: string;
    sortable?: boolean;
}

export const JobCBCTable = ({
    options,
    className,
    sortable = false,
    ...props
}: JobCBCTableProps): JSX.Element => {
    const tableClassName = `table ${className || ''}`;
    const [query] = useSearchParams();

    const pid = query.get('pid');

    const index =
        pid === null
            ? null
            : options.data?.findIndex((d) => d.id.toString() == pid);

    return (
        <table
            {...props}
            className={tableClassName}
            cellSpacing={
                options.hasOwnProperty('cellSpacing') ? options.cellSpacing : 0
            }>
            <TableHead fields={options.fields} options={options.options} />
            {sortable ? (
                <DnDTableBody
                    isTable={true}
                    draggables={options.data}
                    setDraggables={options?.reorder?.order}
                    data={options.data}
                    fields={options.fields}
                    options={options}
                    focusRowIndex={index}
                />
            ) : (
                <TableBody
                    fields={options.fields}
                    data={options.data}
                    options={options}
                    focusRowIndex={index}
                />
            )}
        </table>
    );
};
