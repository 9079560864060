import {useMediaQuery} from 'react-responsive';

const SMALL_WIDTH_THRESHOLD = 695;
const MEDIUM_WIDTH_THRESHOLD = 767;
const LARGE_WIDTH_THRESHOLD = 1250;

export const isDeviceLarge = () =>
    useMediaQuery({
        minWidth: 1650,
    });

export const isDeviceMediumToLarge = () =>
    useMediaQuery({
        minWidth: 1367,
        maxWidth: 1720,
    });

export const isDeviceMid = (hasMinResolution = true) =>
    useMediaQuery({
        minWidth: 1024,
        maxWidth: 1366,
        minResolution: hasMinResolution ? '1.5dppx' : undefined,
    });

export const isDeviceMedium = () =>
    useMediaQuery({
        minWidth: MEDIUM_WIDTH_THRESHOLD + 1,
        maxWidth: LARGE_WIDTH_THRESHOLD,
    });

export const isDeviceSmallMedium = () =>
    useMediaQuery({
        minWidth: SMALL_WIDTH_THRESHOLD + 1,
        maxWidth: MEDIUM_WIDTH_THRESHOLD,
    });

export const isDeviceSmall = () =>
    useMediaQuery({
        maxWidth: SMALL_WIDTH_THRESHOLD,
    });

export const useCustomMediaQuery = ({
    minWidth,
    maxWidth,
}: {
    minWidth?: number;
    maxWidth?: number;
}) =>
    useMediaQuery({
        minWidth,
        maxWidth,
    });

export const useTabletSize = () => {
    const isSmallDevice = isDeviceMedium();
    const isSmallMediumDevice = isDeviceSmallMedium();

    return isSmallDevice || isSmallMediumDevice;
};
