import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';

export const ItemColumn = styled.div<{
    $hardware?: boolean;
    $container?: number;
}>`
    flex-basis: ${({$container = 1772}) => {
        const columns = Math.min(Math.ceil($container / 192), 6);
        return `calc((100% - (15px * ${columns - 1})) / ${columns})`;
    }};

    .details {
        height: ${({$hardware}) => ($hardware ? '170px' : '135px')};
    }
`;

export const useItemColumn = (loader: boolean) => {
    const observer = useRef<ResizeObserver>();
    const observed = useRef<HTMLElement>();

    const [containerLength, setContainerLength] = useState<number>();

    const resizeHandler = useCallback(() => {
        const dimension = observed.current.getBoundingClientRect();
        setContainerLength(dimension.width);
    }, []);

    const resizeRef = useCallback((container: HTMLElement) => {
        if (container) {
            observed.current = container;
            observer.current = new ResizeObserver(resizeHandler);

            observer.current.observe(container);
        }
    }, []);

    const loaders = useMemo(() => {
        if (loader) {
            return (
                <>
                    <ItemColumn $container={containerLength}>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                    <ItemColumn $container={containerLength}>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                    <ItemColumn $container={containerLength}>
                        <div className="hardwareItem">
                            <div className="animated-background" />
                        </div>
                    </ItemColumn>
                </>
            );
        }
    }, [loader]);

    useEffect(() => {
        if (observer.current) {
            return () => observer.current.unobserve(observed.current);
        }
    }, []);

    return {
        loaders,
        containerLength,
        resizeRef,
    };
};
