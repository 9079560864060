import React from 'react';
import {Pagination as BSPagination} from 'react-bootstrap';

import type {PaginationType, SetPageType} from 'shared/types';

type PaginationComponentType = {
    pagination: PaginationType;
    setPage: SetPageType;
};

const Pagination = ({
    pagination,
    setPage,
}: PaginationComponentType): JSX.Element => {
    if (typeof pagination !== 'undefined') {
        return (
            <BSPagination size="sm">
                {pagination.currentPage > 2 && pagination.totalPages > 3 ? (
                    <BSPagination.Item
                        onClick={() => {
                            if (pagination.currentPage != 1) {
                                setPage(1);
                            }
                        }}>
                        {1}
                    </BSPagination.Item>
                ) : (
                    <></>
                )}
                {pagination.currentPage > 3 ? (
                    <BSPagination.Prev
                        onClick={() => setPage(pagination.currentPage - 1)}
                    />
                ) : (
                    <></>
                )}
                {pagination.pages.map((page, index) => {
                    return (
                        <BSPagination.Item
                            onClick={() => setPage(page)}
                            key={index}
                            active={page === pagination.currentPage}>
                            {page}
                        </BSPagination.Item>
                    );
                })}
                {pagination.currentPage < pagination.totalPages - 2 ? (
                    <BSPagination.Next
                        onClick={() => setPage(pagination.currentPage + 1)}
                    />
                ) : (
                    <></>
                )}
                {pagination.totalPages > 3 &&
                pagination.currentPage < pagination.totalPages - 1 ? (
                    <BSPagination.Item
                        onClick={() => setPage(pagination.totalPages)}>
                        {pagination.totalPages}
                    </BSPagination.Item>
                ) : (
                    <></>
                )}
            </BSPagination>
        );
    } else {
        return <></>;
    }
};

export default Pagination;
