import React, {useEffect, useMemo} from 'react';
import {useAppContext} from 'contexts';
import {useConfirmationDialog} from 'shared';
import {useLocation} from 'react-router-dom';

export const ManufacturerCheck = ({children}) => {
    const {userProfile} = useAppContext();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const location = useLocation();

    const inactiveManufacturerText = useMemo(() => {
        return `<p>This online ordering portal is no longer accepting jobs. Contact ${userProfile.manufacturerName} to finalise any outstanding orders.</p> ${userProfile.manufacturerContactInfo}`;
    }, [userProfile]);

    useEffect(() => {
        // show only on dashboard page
        if (
            (location.pathname == '/v2/' || location.pathname == '/v2') &&
            userProfile &&
            userProfile.inActiveManufacturer
        ) {
            showDialog({
                title: 'Inactive portal',
                message: inactiveManufacturerText,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => hideDialog,
                    },
                ],
            });
        }
        // hide dialog on unmount
        return () => hideDialog();
    }, [userProfile]);

    return (
        <>
            {dialog}
            {children}
        </>
    );
};