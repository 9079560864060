import React from 'react';
import {CBCImage} from 'shared/helpers';
import {SidebarStatus} from 'store/customer/UISlice';
import styled, {useTheme} from 'styled-components';

const StyledLabel = styled.label`
    margin: 0;
    cursor: pointer;
`;

interface MenuButtonProps {
    onClick: () => void;
    status: string;
}

const MenuButton = ({onClick, status}: MenuButtonProps) => {
    const theme = useTheme();

    return (
        <a
            role="button"
            tabIndex={0}
            className="dashboard-link"
            onClick={onClick}
            style={{textDecoration: 'none'}}>
            <div>
                <CBCImage
                    src={
                        status === SidebarStatus.OPEN
                            ? 'Header-Menu-Open.svg'
                            : 'Header-Menu.svg'
                    }
                    alt="Menu Icon"
                    color={theme.colors.headerIconColor.main}
                />
            </div>
            {status === SidebarStatus.OPEN ? (
                <StyledLabel>Collapse Menu</StyledLabel>
            ) : null}
        </a>
    );
};

export default MenuButton;
