import styled from 'styled-components';

export const MiniRadio = styled.label<{
    $checked: boolean;
    $disabled?: boolean;
    $rightCheckbox?: boolean;
    $flexLabel?: boolean;
}>`
    position: relative;
    padding-left: 25px;
    cursor: ${({$disabled = false}) => ($disabled ? 'initial' : 'pointer')};
    display: ${({$rightCheckbox = false, $flexLabel = false}) =>
        $rightCheckbox ? 'block' : $flexLabel ? 'flex' : 'initial'};

    :before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        top: 3px;
        border: 1px solid rgb(var(--primary_colour));
        box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 75%) inset;
        opacity: ${({$disabled = false}) => ($disabled ? '0.5' : 1)};

        ${({$rightCheckbox = false}) => {
            if ($rightCheckbox) {
                return 'right: 0;';
            } else {
                return 'left: 0;';
            }
        }}
    }

    :after {
        display: ${({$checked}) => ($checked ? 'block' : 'none')};
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        top: 7px;
        background: rgb(var(--secondary_colour));

        ${({$rightCheckbox = false}) => {
            if ($rightCheckbox) {
                return 'right: 4px;';
            } else {
                return 'left: 4px;';
            }
        }}
    }

    > input {
        display: none;
    }
`;
