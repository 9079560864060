import React, {useCallback, useRef} from 'react';
import {Alert} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {FIELDSET_LAYOUT, GenericFields} from 'components/customer/Product';
import {ApplianceDB} from 'components/customer/Product/entity/Appliance';

interface NewApplianceFormProps {
    newAppliance: React.MutableRefObject<ApplianceDB>;
    messages: string[];
}

export const NewApplianceForm = ({
    newAppliance,
    messages,
}: NewApplianceFormProps) => {
    const newForm = useRef([
        {
            name: 'make',
            displayName: 'Make',
            type: 'text',
            value: '',
            options: {
                maximum: 150,
                mandatory: true,
            },
        },
        {
            name: 'model',
            displayName: 'Model',
            type: 'text',
            value: '',
            options: {
                maximum: 150,
                mandatory: true,
            },
        },
        {
            name: 'height',
            displayName: 'Height',
            type: 'size',
            value: 0,
        },
        {
            name: 'width',
            displayName: 'Width',
            type: 'size',
            value: 0,
        },
        {
            name: 'depth',
            displayName: 'Depth',
            type: 'size',
            value: 0,
        },
    ]).current;

    const selectHandler = useCallback(
        (name: string, value: string | number) => {
            switch (name) {
                case 'depth':
                    newAppliance.current.depth = Number(value);
                    break;

                case 'height':
                    newAppliance.current.height = Number(value);
                    break;

                case 'width':
                    newAppliance.current.width = Number(value);
                    break;

                case 'make':
                    newAppliance.current.make = String(value);
                    break;

                case 'model':
                    newAppliance.current.model = String(value);
                    break;
            }
        },
        []
    );

    return (
        <section className="cbc-form" style={{padding: 0, fontSize: 'initial'}}>
            {messages.length ? (
                <Alert
                    variant="danger"
                    style={{marginTop: 0, marginBottom: '15px'}}>
                    <Icon iconName="Button-Error.svg" />

                    {messages.map((message) => {
                        return <div key={message}>{message}</div>;
                    })}
                </Alert>
            ) : null}

            <GenericFields
                fields={newForm}
                fieldsetName="new_appliance"
                indexedValues={newAppliance.current}
                fieldsetLayout={FIELDSET_LAYOUT.COMPOUND}
                selectHandler={selectHandler}
            />
        </section>
    );
};
