import React, {useState} from "react";
import {Image} from "react-bootstrap";

import './ImageSlider.scss';

export const ImageSlider = ({images, variationImage}) => {
    const [shownImage, setShownImage] = useState(0);

    // check if variation image exists and not present in images array
    if (variationImage && images.indexOf(variationImage) < 0) {
        if (images.length) {
            // Show variation image first.
            images.unshift(variationImage);
            // reset the index to show first image
            setShownImage(0);
        }
    }
    return (
        <div className="sliderWrapper">
            <div className="sliderContainer">
                {
                    images.map((image, index) => {
                        if(image) {
                            return (
                                <div className={ shownImage === index ? "slide" : "slide hide" } key={index}>
                                    <div className="imageContainer">
                                        <Image src={image} />
                                    </div>
                                </div>
                            );
                        } else return <></>;
                    })
                }
            </div>
            <div className="sliderThumbnails">
                {
                    images.map((image, index) => {
                        if (image) {
                            return (
                                <div className="thumbnail" key={index} onClick={() => setShownImage(index)}>
                                    <Image src={image} />
                                </div>
                            );
                        } else return <></>;
                    })
                }
            </div>
        </div>
    );
}