import {Card as BSCard} from 'react-bootstrap';
import styled from 'styled-components';

export const Card = styled(BSCard)`
    border: none;
    border-radius: 15px;
    background: #e5e5e5;
`;

export const Title = styled(BSCard.Title)`
    background: rgb(var(--primary_colour));
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-weight: 900;
    padding: 5px;
    font-size: 0.9em;
    margin: 0;
    display: flex;
    align-items: center;

    > img {
        width: 29px;
        background: white;
        border-radius: 29px;
        padding: 2px;
    }

    > span {
        flex: 1;
        text-align: center;
    }
`;

export const Body = styled(BSCard.Body)`
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.9em;
`;
