import React, {useEffect} from 'react';
import {useAppContext} from 'contexts';
import {useConfirmationDialog} from 'shared';

export const ManufacturerDisabledProperties = () => {
    const {disableProperties} = useAppContext();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    useEffect(() => {
        if (disableProperties.length > 0) {
            // show first error message
            showDialog({
                title: `${disableProperties[0].propertyName} Unavailable`,
                message: disableProperties[0].message,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => hideDialog,
                    },
                ],
            });
        }
        // hide dialog on unmount
        return () => hideDialog();
    }, [disableProperties]);

    return <>{dialog}</>;
};
