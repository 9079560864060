// @flow
import React, {useState} from 'react';
import {Col, Form, Row, Image} from 'react-bootstrap';
import {Dropdown} from 'shared';
import {useTabletSize} from 'shared/helpers/DeviceSize';

type OptionsType = {
    image?: string,
    description?: string,
    hidden?: boolean,
    name?: string,
};

type BooleanType = {
    name: string,
    value: any,
    setFieldValue: (name: string, value: any) => void,
    type?: string,
    alignRight?: boolean,
    disabled?: boolean,
    disabledMessage?: () => void | string,
    yesOptions?: OptionsType,
    noOptions?: OptionsType,
};

export const Boolean = ({
    name,
    value,
    setFieldValue,
    yesOptions = {},
    noOptions = {},
    type = 'radio',
    alignRight = false,
    disabled = false,
    disabledMessage,
}: BooleanType): React$Element<any> => {
    const isTabletSize = useTabletSize();
    value = value ? value : type === 'radio' ? false : 0;

    const handleChange = async (event) => {
        const targetValue = event.target.value == 'true';
        setFieldValue(name, targetValue);
    };

    const handleCheck = (name) => {
        if (disabled && disabledMessage) {
            disabledMessage();
        }
        // click radio button on click of image/description
        const element = document.getElementById(name);

        if (element) {
            element.click();
        }
    };

    const opacity = {
        opacity: '0.5',
    };

    if (type === 'radio') {
        return (
            <Row>
                {!yesOptions.hasOwnProperty('hidden') || !yesOptions.hidden ? (
                    <Col md={6} xs={6}>
                        <div
                            className="cbc-radio"
                            onClick={() => handleCheck(`boolean_${name}_yes`)}
                            style={!value ? opacity : null}>
                            {yesOptions.image || yesOptions.description ? (
                                <section
                                    style={{
                                        display: 'flex',
                                        padding: '10px 0',
                                        flexWrap: 'wrap',
                                    }}>
                                    {yesOptions.image ? (
                                        <section
                                            style={{
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                width: '150px',
                                            }}>
                                            <Image
                                                src={`/${yesOptions.image}`}
                                                thumbnail
                                            />
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                    {yesOptions.description ? (
                                        <section
                                            style={{
                                                fontSize: '.75em',
                                                textAlign: 'justify',
                                                flex: isTabletSize
                                                    ? 'unset'
                                                    : 1,
                                            }}>
                                            {yesOptions.description}
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                </section>
                            ) : (
                                <></>
                            )}
                            <Form.Check
                                type="radio"
                                id={`boolean_${name}_yes`}
                                name={name}
                                disabled={disabled}
                                value={true}
                                checked={value}
                                label={
                                    yesOptions.name ? yesOptions.name : 'Yes'
                                }
                                onChange={handleChange}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
                {!noOptions.hasOwnProperty('hidden') || !noOptions.hidden ? (
                    <Col md={6} xs={6}>
                        <div
                            className="cbc-radio"
                            onClick={() => handleCheck(`boolean_${name}_no`)}
                            style={value ? opacity : null}>
                            {noOptions.image || noOptions.description ? (
                                <section
                                    style={{
                                        display: 'flex',
                                        padding: '10px 0',
                                        flexWrap: 'wrap',
                                    }}>
                                    {noOptions.image ? (
                                        <section
                                            style={{
                                                marginRight: '10px',
                                                cursor: 'pointer',
                                                width: '150px',
                                            }}>
                                            <Image
                                                src={`/${noOptions.image}`}
                                                thumbnail
                                            />
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                    {noOptions.description ? (
                                        <section
                                            style={{
                                                fontSize: '.75em',
                                                textAlign: 'justify',
                                                flex: isTabletSize
                                                    ? 'unset'
                                                    : 1,
                                            }}>
                                            {noOptions.description}
                                        </section>
                                    ) : (
                                        <></>
                                    )}
                                </section>
                            ) : (
                                <></>
                            )}
                            <Form.Check
                                type="radio"
                                id={`boolean_${name}_no`}
                                name={name}
                                value={false}
                                disabled={disabled}
                                checked={!value}
                                label={noOptions.name ? noOptions.name : 'No'}
                                onChange={handleChange}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
        );
    } else if (type === 'checkbox') {
        return (
            <Row>
                <Col>
                    <div style={alignRight ? {textAlign: 'right'} : {}}>
                        <Form.Check
                            type="switch"
                            id={`boolean_${name}_no`}
                            label=""
                            checked={value}
                            onChange={() => {
                                setFieldValue(name, !value);
                            }}
                        />
                    </div>
                </Col>
            </Row>
        );
    } else {
        const [options] = useState([
            {
                id: 1,
                name: yesOptions && yesOptions.name ? yesOptions.name : 'Yes',
            },
            {id: 0, name: noOptions && noOptions.name ? noOptions.name : 'No'},
        ]);

        return (
            <Dropdown
                options={options}
                name={name}
                selectHandler={setFieldValue}
                value={value}
            />
        );
    }
};
