import {useCallback} from 'react';
import {
    NavigateOptions,
    To,
    useNavigate as ogUseNavigate,
} from 'react-router-dom';
import {useAppSelector} from 'store/customer';
import {selectReloadScript} from 'store/customer/appSlice';

export const useNavigate = () => {
    const reloadScript = useAppSelector(selectReloadScript);
    const navigate = ogUseNavigate();

    return useCallback(
        (to: To, options?: NavigateOptions) => {
            if (reloadScript) {
                // force reload the page, which will fetch new scripts
                window.location.href = to as string;
            } else {
                navigate(to, options);
            }
        },
        [reloadScript]
    );
};
