import LShape from "./LShape";

export default class KeyShape extends LShape{
    circleValues (xOffset, yOffset, leftWidth, leftDepth, rightDepth) {
        let vRatio = leftWidth / this.options.leftWidth;
        let radius = Math.round(vRatio * 200);

        let curveCenterX = xOffset + leftDepth;
        let curveCenterY = yOffset + rightDepth;

        return [radius, curveCenterX, curveCenterY];
    }

    curve(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth, edgeOffset = 0) {
        const [ radius, curveCenterX, curveCenterY ] = this.circleValues(xOffset, yOffset, leftWidth, leftDepth, rightDepth);
        let startAngle = .5 * Math.PI;
        let endAngle = 2 * Math.PI;

        if (edgeOffset > 0) {
            startAngle = (.5 - (this.edgeDistance/100) - .02) * Math.PI;
            endAngle = (2 + (this.edgeDistance/100) + .02) * Math.PI;
        }

        this.context.beginPath();
        this.context.lineWidth = 2;
        this.context.arc(curveCenterX, curveCenterY, radius - edgeOffset, startAngle, endAngle);
        this.context.stroke();

        this.context.beginPath();
        this.context.lineWidth = 3;
        this.context.moveTo(xOffset + leftDepth + edgeOffset, yOffset + leftWidth + edgeOffset);
        this.context.lineTo(xOffset + leftDepth + edgeOffset, yOffset + rightDepth + radius - edgeOffset);
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(xOffset + rightWidth + edgeOffset, yOffset + rightDepth + edgeOffset);
        this.context.lineTo(xOffset + leftDepth + radius - edgeOffset, yOffset + rightDepth + edgeOffset);
        this.context.stroke();
    }

    draw() {
        const edging = this.options.hasOwnProperty('edging');
        let options = this.options;

        const [ leftWidth, rightWidth, leftDepth, rightDepth ] = this.dimension(
            options.leftWidth,
            options.rightWidth,
            options.leftDepth,
            options.rightDepth
        );
        let xOffset = (this.canvas.getBoundingClientRect().width / 2) - (rightWidth/2);
        let yOffset = (this.canvas.getBoundingClientRect().height / 2) - (leftWidth/2);

        this.context.beginPath();
        this.context.lineWidth = 3;
        this.context.moveTo(xOffset, yOffset);
        this.context.lineTo(xOffset, yOffset + leftWidth);
        this.context.lineTo(xOffset + leftDepth, yOffset + leftWidth);
        this.context.stroke();

        this.context.beginPath();
        this.context.moveTo(xOffset + rightWidth, yOffset + rightDepth);
        this.context.lineTo(xOffset + rightWidth, yOffset);
        this.context.lineTo(xOffset, yOffset);
        this.context.stroke();

        this.curve(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth);

        if (!edging) {
            this.lines(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );

            this.labels(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );
        } else {
            let edgeOffset = this.edges(
                xOffset,
                yOffset,
                leftWidth,
                rightWidth,
                leftDepth,
                rightDepth
            );

            if (this.options.edging.innerRadius) {
                this.context.lineWidth = this.edgeWidth;
                this.context.strokeStyle = this.edgeColor;

                this.curve(xOffset, yOffset, leftWidth, rightWidth, leftDepth, rightDepth, edgeOffset);

                this.context.strokeStyle = "#fff";
                this.context.lineWidth = 3;
            }
        }
    }
}