import Rectangle from "./Rectangle";

export default class Ladder extends Rectangle{

    drawLines(rectXOffset, rectYOffset, rectWidth, rectHeight) {
        if (this.options.ladderFrames >= this.options.width) {
            throw new Error("Too many latter frames");
        }

        let frame = 1, difference = rectWidth / (this.options.ladderFrames + 1), x = rectXOffset + difference;
        while (frame <= this.options.ladderFrames) {

            this.context.beginPath();
            this.context.lineWidth = 1;

            this.context.moveTo(x, rectYOffset);
            this.context.lineTo(x, rectYOffset + rectHeight);

            this.context.stroke();

            x += difference;
            frame++;
        }
    }
    edges (rectXOffset, rectYOffset, rectWidth, rectHeight) {
        this.context.lineWidth = this.edgeWidth;
        this.context.strokeStyle = this.edgeColor;

        let edgeOffset =  this.edgeDistance + this.edgeWidth;

        this.options.edging.edgeLength1 && this.drawLine(
            [rectXOffset - edgeOffset, rectYOffset - edgeOffset],
            [rectXOffset + rectWidth + edgeOffset, rectYOffset - edgeOffset]
        );
        this.options.edging.edgeLength2 && this.drawLine(
            [rectXOffset - edgeOffset, rectYOffset + rectHeight + edgeOffset],
            [rectXOffset + rectWidth + edgeOffset, rectYOffset + rectHeight + edgeOffset]
        );
        this.options.edging.edgeWidth1 && this.drawLine(
            [rectXOffset - edgeOffset, rectYOffset - edgeOffset],
            [rectXOffset - edgeOffset, rectYOffset + rectHeight + edgeOffset]
        );
        this.options.edging.edgeWidth2 && this.drawLine(
            [rectXOffset + rectWidth + edgeOffset, rectYOffset - edgeOffset],
            [rectXOffset + rectWidth + edgeOffset, rectYOffset + rectHeight + edgeOffset]
        );

        this.context.strokeStyle = "#000";
        this.context.lineWidth = 3;
    }

    draw() {
        const edging = this.options.hasOwnProperty('edging');
        let width = this.options.width;
        let height = this.options.depth;

        const [rectWidth, rectHeight] = this.dimension(width, height);
        let rectXOffset = (this.canvas.getBoundingClientRect().width / 2) - (rectWidth/2);
        let rectYOffset = (this.canvas.getBoundingClientRect().height / 2) - (rectHeight/2);

        this.context.beginPath();
        this.context.strokeStyle = "#000";
        this.context.lineWidth = 3;
        this.context.rect(rectXOffset, rectYOffset, rectWidth, rectHeight);
        this.context.stroke();

        this.drawLines(rectXOffset, rectYOffset, rectWidth, rectHeight);

        if (!edging) {
            this.horizontalLabel(rectWidth, rectXOffset, rectYOffset, "Width");
            this.verticalLabel(rectWidth, rectHeight, rectXOffset, rectYOffset, "Depth");
        } else {
            edging && this.edges(rectXOffset, rectYOffset, rectWidth, rectHeight);
        }
    }
}