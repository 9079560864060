import React, {useEffect} from 'react';
import {useAppContext} from 'contexts';
import {FourZeroFourRaw} from 'shared/FourZeroFourRaw';

export const FourZeroFour = ({}) => {
    const {setIsLeftDashboardButtonVisible} = useAppContext() as {
        setIsLeftDashboardButtonVisible: (value: boolean) => void;
    };

    useEffect(() => {
        setIsLeftDashboardButtonVisible(false);
    }, []);

    return <FourZeroFourRaw />;
};
