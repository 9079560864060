import Rectangle from './Rectangle';
/**
 *
 *
 * @export
 * @class Drilling
 * @extends {Rectangle}
 */
export default class Drilling extends Rectangle {
    /**
     *
     *
     * @memberof Drilling
     */
    draw() {
        const width = this.options.width;
        const height = this.options.length;

        const [rectWidth, rectHeight] = this.dimension(width, height);
        const xOffset =
            this.canvas.getBoundingClientRect().width / 2 - rectWidth / 2;
        const yOffset =
            this.canvas.getBoundingClientRect().height / 2 - rectHeight / 2;

        this.rectWidth = rectWidth;
        this.rectHeight = rectHeight;
        this.xOffset = xOffset;
        this.yOffset = yOffset;

        const background = new Image();
        background.src = this.options.exteriorMaterial;

        background.onload = () => {
            const pattern = this.context.createPattern(background, 'repeat');

            const edge = new Image();
            edge.src = this.options.edgeMaterial;

            edge.onload = () => {
                const edgePattern = this.context.createPattern(edge, 'repeat');

                this.drawHoleSet(pattern, edgePattern);
            };

            edge.onerror = (error) => {
                console.log(error);
                this.drawHoleSet(pattern);
            };
        };

        background.onerror = (error) => {
            console.log(error);
            this.drawHoleSet();
        };
    }

    /**
     *
     *
     * @param {*} pattern
     * @param {*} edgePattern
     * @memberof Drilling
     */
    drawHoleSet(pattern, edgePattern) {
        this.context.fillStyle = pattern ? pattern : '#b5b5b5';
        this.context.beginPath();
        this.context.strokeStyle = edgePattern ? edgePattern : '#777';
        this.context.lineWidth = 1;
        this.context.rect(
            this.xOffset,
            this.yOffset,
            this.rectWidth,
            this.rectHeight
        );
        this.context.fill();
        this.context.stroke();

        if (
            this.options.hasOwnProperty('drillings') &&
            Array.isArray(this.options.drillings)
        ) {
            this.options.drillings.forEach((drilling) =>
                this.drawDrillingHoles(drilling)
            );
        }
    }

    /**
     *
     *
     * @param {*} drillingSet
     * @memberof Drilling
     */
    drawDrillingHoles(drillingSet) {
        const sizeRatio =
            this.rectWidth < this.options.width
                ? this.rectWidth / this.options.width
                : this.options.width / this.rectWidth;

        const xOffset = sizeRatio * drillingSet.drilling_offset_y;
        const yOffset = sizeRatio * drillingSet.drilling_offset_x;
        const pitch = sizeRatio * drillingSet.drilling_pitch;

        let holeDistance = this.yOffset + this.rectHeight - xOffset;

        for (let i = 0; i < parseInt(drillingSet.drilling_num_holes); i++) {
            this.drillingHole(this.xOffset + yOffset, holeDistance);

            holeDistance = holeDistance - pitch;
        }
    }

    /**
     *
     *
     * @param {*} x
     * @param {*} y
     * @memberof Drilling
     */
    drillingHole(x, y) {
        this.context.beginPath();
        this.context.strokeStyle = this.options.hasOwnProperty(
            'holeDisplayColor'
        )
            ? this.options.holeDisplayColor
            : '#000';
        this.context.lineWidth = 1;
        this.context.arc(x, y, 2, 0, 2 * Math.PI);
        this.context.stroke();
    }
}
